import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Circle, Defs, LinearGradient, Stop } from 'react-native-svg';

const UserBeeIcon = React.memo(({size, containerStyle}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 40}
                height={size ? size : 40}
                viewBox="0 0 40 40"
                fill="none"
            >
                <Circle
                    cx={20}
                    cy={20.0664}
                    r={19.5}
                    fill="#fff"
                    stroke="url(#paint0_linear_2019_3363)"
                />
                <Path
                    d="M24.37 32.745c.074-.3.11-.62.116-.779.086-1.171-.46-2.373-1.422-3.054-.503-.319-.988.448-.485.767.497.325.877.864 1.049 1.447.251.693-.061 1.662-.061 2.103.067.528 1.98.54 2.072-.11-.018-.307-.692-.448-1.269-.374z"
                    fill="#332E25"
                />
                <Path
                    d="M25.61 33.12c0 .073-.037.147-.105.214-.098-.337-.822-.367-1.22-.355-.16.006-.264.018-.264.018s.019-.067.05-.19c.079-.356.27-1.128.146-1.613-.288-1.171-1.373-1.968-1.373-1.968l.319-.331c.815.827 1.386 2.11 1.318 3.078a4.206 4.206 0 01-.123.779c.068-.006.142-.019.215-.019.552-.036 1.03.135 1.037.387z"
                    fill="#3F3A31"
                />
                <Path
                    d="M24.487 33.717c-.006 0 0 0 0 0-.466 0-1.012-.122-1.06-.466v-.012c0-.141.03-.331.067-.546.073-.472.171-1.067.006-1.527a2.569 2.569 0 00-1.012-1.398c-.3-.19-.307-.521-.16-.748a.562.562 0 01.313-.245.528.528 0 01.436.067 3.592 3.592 0 011.465 3.14 5.354 5.354 0 01-.086.668c.393-.024.877.031 1.11.227.086.068.13.16.135.252v.018c-.073.392-.656.57-1.214.57zm-.877-.484c.03.172.41.3.87.3h.007c.472 0 .963-.14 1.012-.41a.182.182 0 00-.068-.11c-.177-.154-.65-.228-1.097-.173a.08.08 0 01-.08-.03c-.018-.025-.03-.056-.018-.086.067-.288.104-.595.11-.76.086-1.153-.46-2.319-1.386-2.975a.335.335 0 00-.282-.043.391.391 0 00-.209.166c-.098.153-.104.368.105.497a2.7 2.7 0 011.085 1.496c.178.497.08 1.116 0 1.613-.018.208-.049.386-.049.515z"
                    fill="#2D1D1D"
                />
                <Path
                    opacity={0.5}
                    d="M25.882 24.963c2.802-.32 4.957-1.609 4.812-2.878-.145-1.268-2.535-2.037-5.338-1.716-2.803.32-4.957 1.609-4.812 2.877.145 1.269 2.535 2.037 5.338 1.717z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M21.849 24.663c-.816-.337-1.288-.822-1.35-1.386-.073-.65.4-1.312 1.344-1.877.92-.545 2.177-.932 3.544-1.097 1.368-.154 2.68-.062 3.704.263 1.043.332 1.656.871 1.73 1.521.073.65-.4 1.313-1.344 1.877-.92.546-2.177.932-3.544 1.098-1.367.153-2.68.06-3.704-.264a3.888 3.888 0 01-.38-.135zm3.538-4.274l.012.08c-2.76.318-4.881 1.57-4.746 2.796.141 1.226 2.496 1.968 5.256 1.65 1.349-.154 2.587-.534 3.483-1.068.883-.527 1.337-1.14 1.27-1.717-.142-1.226-2.497-1.968-5.256-1.65l-.019-.091z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M20.545 23.33c-.025-.012-.043-.024-.05-.055-.011-.043.007-.086.056-.092.05-.012 4.9-1.392 8.536-1.11.043 0 .074.037.074.086 0 .043-.037.074-.086.074-3.606-.282-8.438 1.091-8.475 1.104-.024 0-.043 0-.055-.007z"
                    fill="#B9D3F9"
                />
                <Path
                    opacity={0.5}
                    d="M28.558 27.44c.744-1.038-.513-3.214-2.808-4.86-2.296-1.647-4.76-2.14-5.504-1.103-.744 1.037.513 3.213 2.808 4.86 2.296 1.646 4.76 2.14 5.504 1.102z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M25.037 27.577c-.68-.282-1.392-.68-2.073-1.171-1.116-.804-2.024-1.754-2.57-2.68-.545-.944-.637-1.76-.257-2.294.38-.527 1.177-.699 2.25-.49 1.05.208 2.245.766 3.361 1.57 1.116.803 2.024 1.753 2.57 2.68.545.944.637 1.76.257 2.293-.38.527-1.177.7-2.25.49a6.697 6.697 0 01-1.288-.398zm-1.429-6.083c-1.52-.632-2.84-.663-3.336.043-.343.478-.245 1.226.27 2.115.521.908 1.422 1.846 2.52 2.631 1.104.791 2.27 1.337 3.3 1.545 1.005.203 1.753.05 2.09-.423.338-.472.246-1.226-.27-2.115-.52-.908-1.422-1.846-2.52-2.631a10.052 10.052 0 00-2.054-1.165z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M27.367 26.798c-.006-.006-.018-.006-.024-.018-3.526-3.453-7.114-5.225-7.15-5.237-.037-.019-.056-.068-.037-.104.018-.037.067-.056.104-.037.037.018 3.643 1.803 7.193 5.274.03.03.03.086 0 .11-.03.018-.055.025-.086.012z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M28.863 17.555c-.68 2.447-4.06 4.127-6.623 3.875-.632-.098-.448-1.005.129-.907 2.195.27 5.206-1.129 5.85-3.19.147-.41.785-.195.644.222z"
                    fill="#332E25"
                />
                <Path
                    d="M22.74 21.528c-.177 0-.349-.006-.52-.025-.221-.03-.374-.147-.442-.33a.607.607 0 01.098-.583.519.519 0 01.497-.178c1.049.129 2.336-.135 3.446-.712 1.172-.607 2.006-1.49 2.3-2.416.098-.264.35-.325.552-.251.196.067.356.276.27.533-.325 1.172-1.264 2.233-2.65 2.999-1.116.62-2.403.963-3.55.963zm-.471-.938a.318.318 0 00-.252.116.41.41 0 00-.067.399c.03.08.11.184.294.214 2.453.24 5.844-1.367 6.519-3.808v-.006c.055-.166-.05-.264-.153-.3-.117-.043-.264-.013-.32.14-.306.988-1.158 1.883-2.385 2.52-1.14.59-2.471.866-3.563.73-.024 0-.049-.005-.073-.005z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M28.277 17.316c.184-.638.981-1.356 1.404-1.288.706.22-.33 1.233-.95 1.435-.24.073-.49.165-.454-.147z"
                    fill="#332E25"
                />
                <Path
                    opacity={0.5}
                    d="M15.359 25.3c2.778-.49 4.85-1.908 4.627-3.165-.222-1.258-2.654-1.879-5.432-1.388-2.779.491-4.85 1.909-4.628 3.166.222 1.258 2.655 1.879 5.433 1.387z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M14.197 25.53c-.914.06-1.772 0-2.496-.185-1.061-.27-1.711-.773-1.815-1.41-.117-.644.319-1.337 1.22-1.957.883-.607 2.122-1.073 3.471-1.312 1.361-.24 2.674-.227 3.71.043 1.061.27 1.711.773 1.815 1.41.117.644-.319 1.337-1.22 1.957-.883.607-2.122 1.073-3.47 1.312-.412.061-.817.116-1.215.141zm.417-4.71c-1.33.238-2.545.692-3.416 1.281-.846.583-1.263 1.214-1.159 1.79.104.577.711 1.037 1.699 1.289 1.018.257 2.306.276 3.642.036 1.331-.239 2.545-.693 3.416-1.281.846-.583 1.263-1.215 1.16-1.791-.215-1.208-2.62-1.803-5.342-1.325z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M12.454 23.614a.085.085 0 01-.08-.055c-.018-.043 0-.086.043-.104.043-.012 3.95-1.484 7.543-1.576.043 0 .08.037.08.08 0 .043-.037.085-.08.08-3.557.091-7.439 1.557-7.482 1.563-.006.012-.018.012-.024.012z"
                    fill="#B9D3F9"
                />
                <Path
                    opacity={0.5}
                    d="M18.95 23.617c.68-1.081-.706-3.175-3.094-4.676-2.389-1.502-4.876-1.842-5.555-.762-.68 1.081.706 3.175 3.094 4.676 2.389 1.502 4.876 1.843 5.555.762z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M17.65 24.324c-.258.018-.54 0-.853-.037-1.061-.147-2.288-.631-3.453-1.361s-2.128-1.625-2.723-2.52c-.6-.914-.742-1.724-.398-2.276.35-.546 1.134-.772 2.214-.625 1.06.147 2.287.631 3.452 1.361s2.128 1.625 2.723 2.52c.6.908.748 1.718.399 2.276-.233.392-.712.62-1.362.662zm-6.054-6.697c-.588.037-1.018.24-1.238.59-.657 1.042.717 3.09 3.072 4.568 1.147.723 2.355 1.196 3.385 1.337 1.018.14 1.748-.055 2.06-.552.657-1.043-.717-3.091-3.072-4.569-1.147-.724-2.355-1.196-3.385-1.337a4.249 4.249 0 00-.822-.037z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M18.949 23.69a.103.103 0 01-.062-.02c-2.74-2.796-6.396-4.341-6.439-4.36-.043-.012-.06-.06-.043-.104.013-.043.062-.061.105-.043.037.013 3.728 1.582 6.494 4.397.025.031.03.08 0 .117-.024.006-.043.012-.055.012z"
                    fill="#B9D3F9"
                />
                <Path
                    d="M24.542 24.75c.08 3.447-3.134 7.292-6.77 6.427a3.492 3.492 0 01-.392-.116 3.663 3.663 0 01-1.35-.791 3.114 3.114 0 01-.668-.877c-.84-1.6-.546-3.698.399-5.2.306-.479 1-1.62 1.539-2.527.38-.632.687-1.147.73-1.245l5.304-.858c.067.049.24.496.435 1.122.405 1.257.896 3.22.773 4.066z"
                    fill="#F9C710"
                />
                <Path
                    d="M24.497 24.894c0 3.41-3.19 7.156-6.77 6.304 2.311.024 4.685-1.723 5.482-4.354.803-2.65-.258-5.464-.258-5.464l.78-.68c.38 1.207.845 3.053.778 3.955-.006.08-.006.16-.012.239z"
                    fill="#F8A01F"
                />
                <Path
                    d="M16.03 30.284a3.113 3.113 0 01-.668-.877c-.84-1.601-.546-3.698.399-5.2.306-.48 1-1.62 1.539-2.527l.95.19s-1.655 1.938-2.477 4.299c-.804 2.287.202 4.023.257 4.114z"
                    fill="#FFD95F"
                />
                <Path
                    d="M24.267 22.572c-4.605 1.319-7.408-.226-7.408-.226.693-1.147 1.135-1.932 1.135-1.932l4.244-.693 1.067-.178s.257.534.527 1.361c.147.491.307 1.055.435 1.668z"
                    fill="#332E25"
                />
                <Path
                    d="M19.368 31.352a4.582 4.582 0 01-2.049-.197c-.895-.294-1.65-.913-2.066-1.71-.797-1.503-.632-3.625.392-5.287.528-.827 2.153-3.538 2.275-3.771.013-.024.037-.037.062-.043l5.304-.859c.03-.006.08.013.092.05.521 1.349 1.135 3.152 1.214 5.218.062 2.036-1.067 4.275-2.802 5.575-.767.564-1.595.913-2.422 1.024zm-1.325-10.849c-.215.4-1.754 2.956-2.257 3.747-.834 1.343-1.275 3.447-.392 5.127.392.76 1.122 1.35 1.968 1.631 1.448.479 2.98.197 4.318-.803 1.698-1.27 2.802-3.452 2.747-5.44-.074-2.01-.662-3.777-1.178-5.108l-5.206.846z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M20.143 30.042c.031.086-.067.202-.214.258-.153.055-.3.024-.337-.062-.031-.085.067-.202.214-.257.153-.055.313-.025.337.061zM18.99 30.37c.14-.073.294-.073.337.007.042.08-.037.209-.178.282-.141.074-.295.074-.338-.006-.049-.074.031-.202.178-.282zM19.805 30.523c.03.061-.018.153-.129.208-.104.055-.208.055-.245-.012-.03-.061.018-.153.129-.209.11-.055.22-.049.245.013z"
                    fill="#F4B503"
                />
                <Path
                    d="M19.976 31.15a6.25 6.25 0 01-.608.123 4.515 4.515 0 01-1.637-.092.802.802 0 01-.104.049 7.729 7.729 0 01-1.779.638c-.607.14-.98.128-.98.128.686-.325 1.183-1.128 1.33-1.41.03-.061.055-.098.055-.098a3.477 3.477 0 01-1.14-1.57c.024.037 1.152 2 4.863 2.232z"
                    fill="#332E25"
                />
                <Path
                    d="M16.198 30.586c0 .018.073.803-.35 1.282-.607.14-.98.128-.98.128.686-.319 1.183-1.128 1.33-1.41z"
                    fill="#3F3A31"
                />
                <Path
                    d="M15.352 32.05c-.282.038-.454.038-.472.038-.037-.007-.068-.025-.074-.068a.082.082 0 01.05-.092c.692-.325 1.189-1.183 1.318-1.416-.51-.405-.853-.926-1.092-1.558-.012-.043-.018-.092.018-.104.037-.025.086 0 .105.037.012.024 1.14 1.95 4.783 2.189.037.006.067.024.074.067.006.043-.043.012-.08.025-.742.208-1.478.282-2.22.104l-.08.037c-1 .496-1.833.674-2.33.742zm-.129-.152a7.356 7.356 0 002.398-.749c.067-.036.11-.049.147-.036a4.355 4.355 0 001.686.08c-2.189-.246-3.385-1.098-3.955-1.681.215.356.49.675.822.92.03.018.043.055.024.08a.062.062 0 01-.006.018c-.043.104-.46.914-1.116 1.367z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M24.526 24.753c0 .387-.031.773-.086 1.16a6.271 6.271 0 01-.227.92s-4.735-.172-7.807-2.165a7.475 7.475 0 01-.656-.473c.129-.208.258-.41.38-.613 0 0 .24.135.687.325 1.202.509 3.919 1.35 7.709.846z"
                    fill="#332E25"
                />
                <Path
                    d="M16.815 23.923s-.251.429-.417.76a7.454 7.454 0 01-.656-.472c.129-.209.258-.411.38-.613-.006 0 .24.14.693.325z"
                    fill="#3F3A31"
                />
                <Path
                    d="M24.204 26.946h-.018c-.05 0-5.507-.257-8.5-2.68-.03-.03-.043-.067-.012-.104.129-.208.258-.41.38-.613.019-.037.068-.049.105-.024.03.018 3.213 1.827 8.352 1.159.024-.007.043 0 .061.018.019.012.03.037.025.061-.006.411-.025.797-.086 1.171-.043.301-.16.694-.252.957 0 .03-.024.055-.055.055zm-8.358-2.76c2.784 2.22 7.684 2.564 8.29 2.595.08-.264.179-.613.228-.877.055-.337.085-.693.085-1.061-4.734.589-7.806-.895-8.297-1.147-.098.166-.202.331-.306.49zM22.286 23.074c-3.403.454-5.446-.644-5.464-.656a.114.114 0 01-.037-.043c-.006-.024-.006-.043.006-.061.687-1.14 1.129-1.92 1.135-1.92a.098.098 0 01.055-.042l5.305-.859c.043-.006.08.024.092.061.006.043-.025.086-.062.092l-5.267.859c-.086.153-.491.852-1.074 1.821.473.22 3.165 1.362 7.28.184.043-.012.012.025.024.061.012.043.061.074.018.092a15.42 15.42 0 01-2.01.411z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M13.078 26.653c-.178.485-1 1.129-1.417 1.055-.963-.343 1.748-2.453 1.417-1.055z"
                    fill="#332E25"
                />
                <Path
                    d="M15.944 24.754c.055.037.043.135-.031.233-.061.092-.16.129-.215.098-.055-.037-.043-.135.031-.233.061-.092.16-.14.215-.098zM15.413 25.393c.049-.098.135-.154.196-.123.055.03.061.135.012.233s-.135.154-.196.123c-.061-.03-.067-.135-.012-.233zM15.944 25.16c.043.019.049.093.006.166-.03.074-.104.117-.141.086-.043-.018-.05-.092-.006-.166.043-.06.104-.104.14-.085z"
                    fill="#F9C710"
                />
                <Path
                    d="M23.336 28.555a6.88 6.88 0 01-1.367 1.521s-4.023-.546-6.489-2.827a6.039 6.039 0 01-.613-.638l.178-.877s.172.264.62.62c.95.779 3.158 2.011 7.671 2.201z"
                    fill="#332E25"
                />
                <Path
                    d="M15.664 26.362s-.128.38-.184.895a6.03 6.03 0 01-.613-.638l.178-.877s.166.252.62.62z"
                    fill="#3F3A31"
                />
                <Path
                    d="M22.018 30.163c-.006-.043-.019 0-.025.006-.049-.012-4.82-.68-7.15-3.489-.012-.018-.019-.03-.012-.049v-.018l.177-.877c.007-.025.025-.055.062-.055.03-.013.061 0 .08.024 0 .006.012.006.012.019 0 .006.429.631 1.637 1.287 1.128.607 3.183 1.356 6.592 1.503.025.006.056.018.062.043.018.006-.05.043-.074.073a6.607 6.607 0 01-1.325 1.466c-.03-.018-.03.067-.036.067zM14.984 26.6c2.201 2.619 6.555 3.336 6.99 3.404.46-.386.884-.846 1.246-1.368-3.36-.165-5.403-.913-6.525-1.52-.914-.491-1.38-.963-1.576-1.19l-.135.675z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M18.428 32.689c.165-.184.313-.399.43-.564.183-.252.343-.528.453-.822.319-.859.307-1.846-.086-2.686-.368-.601-1.153.043-.736.576.534 1.196-.018 2.46-.87 3.324a.32.32 0 00-.08.325c.171.546 1.343 1.417 1.631 1.037.208-.252-.24-.865-.742-1.19z"
                    fill="#332E25"
                />
                <Path
                    d="M17.965 33.07c.288.362.877.797 1.22.834-.16.19-.644.043-1.085-.325-.424-.35-.65-.773-.528-.975a.263.263 0 01.086-.117c.24-.233.454-.484.626-.76a2.767 2.767 0 00.233-2.508c.018.024.588.901.41 1.674-.153.687-.656 1.312-.962 1.613a1.04 1.04 0 00-.117.104c-.11.086-.037.264.117.46z"
                    fill="#3F3A31"
                />
                <Path
                    d="M17.476 22.517c-1.386.552-2.588 1.527-3.49 2.71-.361.424-.576.982-.907 1.424-.27.177-.663-.123-.503-.43a9.115 9.115 0 011.864-2.68c.779-.766 1.779-1.38 2.79-1.82.583-.258.847.588.246.796z"
                    fill="#332E25"
                />
                <Path
                    d="M13.21 26.609c-.018 0-.037-.007-.043-.013-.037-.018-.08-.018-.061-.055.227-.448.515-.938.822-1.349.95-1.251 2.17-2.195 3.526-2.735a.088.088 0 01.104.043.088.088 0 01-.043.104c-1.33.527-2.52 1.46-3.459 2.68-.3.399-.558.84-.784 1.282-.013.036-.037.036-.062.043z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M16.95 21.863c-.031.019-2.116 1.319-3.018 2.643A26.36 26.36 0 0012.86 26.2a5.654 5.654 0 00-.165.313s-.043.012-.092.049c-.252.128-.932.509-.932 1.165a.226.226 0 01-.142-.074.185.185 0 01-.049-.116c-.03-.221.184-.589.534-.902.19-.165.38-.288.558-.361.006-.013.006-.025.012-.037a9.114 9.114 0 011.865-2.68c.699-.7 1.588-1.27 2.502-1.693z"
                    fill="#3F3A31"
                />
                <Path
                    d="M12.605 26.312c-.018 0-.03.006-.043 0-.037-.019-.061-.068-.037-.105a8.924 8.924 0 011.877-2.698c.92-.907 2.042-1.496 2.815-1.833a.088.088 0 01.104.043.088.088 0 01-.043.104c-.766.331-1.876.914-2.772 1.803a9.148 9.148 0 00-1.846 2.649c-.006.018-.03.03-.055.037z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M11.832 27.782c-.16.018-.276-.012-.356-.092a.321.321 0 01-.074-.16c-.036-.257.19-.643.565-.975.19-.165.386-.294.576-.368a.088.088 0 01.104.043c.025.037-.006.086-.043.105-.171.073-.361.19-.533.337-.368.325-.534.656-.51.828a.14.14 0 00.038.073c.116.13.54.013.956-.355.356-.32.558-.706.583-.73.018-.037.067-.061.104-.037.037.018.061.067.037.104-.018.037-.22.466-.613.797-.295.24-.595.4-.834.43z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M20.355 10.924c.098-.325.215-.638.35-.95.122-.307.264-.614.417-.915.147-.3.319-.594.497-.87.177-.282.38-.558.582-.816.043-.05.11-.055.166-.012.043.03.055.104.024.153-.19.264-.368.534-.521.822-.153.288-.3.582-.43.877-.128.294-.245.6-.349.907-.104.3-.202.62-.276.933v.006a.23.23 0 01-.276.171.23.23 0 01-.172-.276c-.018-.024-.018-.024-.012-.03zM15.333 11.758a12 12 0 00-.797-1.772 10.262 10.262 0 00-.503-.828 7.123 7.123 0 00-.595-.767.122.122 0 01.012-.16c.043-.036.11-.03.16.007.233.24.46.49.662.76.203.264.399.546.57.822.178.288.344.576.503.87.16.295.295.602.43.915a.23.23 0 01-.424.177c-.018-.006-.018-.006-.018-.024z"
                    fill="#332E25"
                />
                <Path
                    d="M20.603 11.329c-.025 0-.05 0-.074-.007a.313.313 0 01-.24-.337c-.005-.018-.011-.037-.005-.055v-.012c.098-.32.214-.632.35-.957.122-.306.263-.62.416-.92a9.363 9.363 0 011.086-1.705.213.213 0 01.135-.073.175.175 0 01.14.049c.08.055.099.178.037.264a6.813 6.813 0 00-.515.81c-.165.306-.306.594-.423.87-.116.27-.233.564-.343.901-.123.35-.209.65-.276.92v.006a.312.312 0 01-.141.197.236.236 0 01-.147.049zm-.148-.399a.17.17 0 01.007.055c-.019.074.03.154.11.178.037.006.074 0 .11-.018.037-.019.056-.056.068-.092v-.006c.067-.283.16-.59.282-.945.116-.337.233-.638.35-.914.122-.276.263-.576.429-.883.147-.27.325-.552.527-.827.006-.013.006-.037-.006-.043-.012-.013-.018-.013-.03-.013-.007 0-.013.006-.019.013-.22.276-.417.545-.577.803a8.4 8.4 0 00-.49.865c-.153.3-.294.6-.417.907-.141.32-.252.62-.343.92zm-.085.006l.08.012-.08-.012zM15.503 12.008a.322.322 0 01-.276-.16c-.018-.012-.03-.037-.037-.061-.098-.27-.214-.558-.355-.89a12.438 12.438 0 00-.43-.864 12.316 12.316 0 00-.496-.822 7.377 7.377 0 00-.59-.76.2.2 0 01.02-.276.212.212 0 01.263 0 8.161 8.161 0 01.675.773 11.466 11.466 0 011.08 1.705c.152.288.293.594.434.92a.313.313 0 01-.288.435zm-.153-.282a.034.034 0 01.018.03c.03.074.117.104.196.074a.144.144 0 00.074-.19 9.608 9.608 0 00-.43-.908 11.147 11.147 0 00-.496-.865 10.018 10.018 0 00-.564-.815 7.748 7.748 0 00-.65-.748c-.019-.013-.037-.013-.05-.006-.012.012-.012.036-.005.042.25.283.441.534.6.78.215.324.375.582.51.833.177.325.318.614.435.877.14.331.264.626.362.896z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M21.416 21.214c-.098.024-.183.055-.282.08-3.422 1.011-6.2 1.073-7.211-2.361-1.006-3.428 1.06-8.42 3.808-9.236.165-.049.35-.08.533-.11.086-.006.166-.025.252-.019 2.79-.153 6.5 2.699 7.414 5.826.981 3.342-1.239 4.808-4.514 5.82z"
                    fill="#F9C710"
                />
                <Path
                    d="M21.446 21.227s1.484-.521 3.066-1.993c1.582-1.465 1.245-4.765-1.049-7.064-2.28-2.288-4.905-2.576-4.924-2.582 2.79-.153 6.5 2.698 7.414 5.826.988 3.336-1.232 4.801-4.507 5.813z"
                    fill="#F8A01F"
                />
                <Path
                    d="M21.416 21.233c-.098.024-.183.055-.282.08-3.422 1.011-6.2 1.072-7.211-2.362-1.006-3.428 1.06-8.42 3.808-9.235.165-.05.35-.08.533-.11 0 0-1.563.147-2.992 3.004-1.43 2.858-1.288 5.777-.454 7.427.834 1.65 3.36 1.9 6.598 1.196z"
                    fill="#FFD95F"
                />
                <Path
                    d="M21.042 21.422c-1.92.552-3.318.693-4.404.441-1.38-.312-2.275-1.27-2.76-2.9-.477-1.625-.293-3.735.498-5.648.79-1.914 2.048-3.287 3.366-3.674.006 0 .019-.006.019-.006 1.343-.386 3.146.092 4.832 1.27 1.674 1.171 2.962 2.851 3.44 4.495.479 1.637.233 2.925-.748 3.93-.797.816-2.09 1.472-4.096 2.061-.055.006-.098.019-.147.03zm-3.25-11.64a.062.062 0 00-.019.007c-1.27.374-2.49 1.71-3.262 3.58-.78 1.877-.957 3.95-.491 5.545.46 1.57 1.33 2.49 2.643 2.784 1.085.251 2.508.092 4.477-.485 1.968-.582 3.25-1.22 4.023-2.017.944-.963 1.17-2.208.705-3.772-.466-1.6-1.736-3.244-3.385-4.409-1.638-1.14-3.385-1.607-4.692-1.233z"
                    fill="#A55A0F"
                />
                <Path
                    d="M17.778 16.833c.051-.886-.346-1.629-.887-1.66-.54-.031-1.02.661-1.072 1.547-.051.886.346 1.63.887 1.66.541.032 1.021-.66 1.072-1.547z"
                    fill="#fff"
                />
                <Path
                    d="M16.948 18.425a1.01 1.01 0 01-.233.025c-.288-.019-.552-.215-.736-.552a2.338 2.338 0 01-.233-1.196c.056-.932.57-1.65 1.16-1.619.588.03 1.017.816.968 1.748-.043.803-.447 1.453-.926 1.594zm-.233-3.158c-.417.117-.76.717-.803 1.447-.03.417.049.81.214 1.104.154.288.375.454.601.466.503.03.945-.631.994-1.472.049-.846-.313-1.545-.816-1.576a.879.879 0 00-.19.031z"
                    fill="#A55A0F"
                />
                <Path
                    d="M16.285 16.975c.03-.552.331-.981.662-.963.338.019.583.479.546 1.03-.03.552-.331.982-.662.963-.338-.018-.583-.478-.546-1.03z"
                    fill="#22213F"
                />
                <Path
                    d="M16.9 16.515c-.006.104-.104.177-.22.177-.117-.012-.21-.098-.203-.19.006-.104.104-.177.22-.177a.19.19 0 01.203.19zM22.51 13.105c.436-.319 1.215-.006 1.742.705.527.712.601 1.552.166 1.87-.436.32-1.215.007-1.742-.704-.527-.712-.601-1.552-.166-1.87z"
                    fill="#fff"
                />
                <Path
                    d="M24.257 15.86c-.478.134-1.165-.197-1.631-.835-.552-.748-.62-1.637-.147-1.987.472-.35 1.306-.018 1.858.718.552.748.62 1.637.147 1.986a.572.572 0 01-.227.117zm-1.508-.927c.496.68 1.232.987 1.63.687.4-.294.32-1.085-.177-1.766-.497-.68-1.233-.981-1.631-.68-.399.293-.32 1.085.178 1.76z"
                    fill="#A55A0F"
                />
                <Path
                    d="M24.043 15.431c.27-.2.223-.72-.105-1.165-.328-.444-.813-.642-1.082-.443-.27.2-.223.72.105 1.165.328.444.812.642 1.082.443z"
                    fill="#22213F"
                />
                <Path
                    d="M23.204 14.324c.006.105-.085.19-.202.197-.116.006-.22-.068-.22-.172 0-.104.085-.19.202-.196.116-.006.214.067.22.171z"
                    fill="#fff"
                />
                <Path
                    d="M17.271 14.358c-.337.27-.711.362-.834.203-.122-.154.055-.503.393-.76.337-.27.711-.363.834-.203.122.153-.055.49-.393.76z"
                    fill="#F8A01F"
                />
                <Path
                    d="M16.813 14.686l-.043.012c-.184.043-.338.019-.417-.086-.154-.196.018-.576.404-.877.387-.3.798-.386.95-.184.154.197-.018.577-.404.877-.16.117-.331.209-.49.258zm.484-1.067a1.244 1.244 0 00-.435.239c-.32.245-.466.546-.38.656.042.05.128.055.257.03.147-.03.313-.122.466-.245.319-.245.466-.545.38-.656-.043-.049-.147-.06-.288-.024z"
                    fill="#A55A0F"
                />
                <Path
                    d="M21.391 12.873c-.435.019-.785-.122-.797-.313-.012-.19.331-.38.76-.398.436-.019.785.122.798.313.012.19-.325.38-.76.398z"
                    fill="#F8A01F"
                />
                <Path
                    d="M21.744 12.9c-.11.03-.239.049-.362.06-.22.013-.429-.018-.594-.079-.184-.067-.289-.184-.295-.313-.012-.128.086-.251.258-.343.153-.08.362-.129.582-.147.491-.03.871.14.883.386.013.19-.177.35-.472.435zm-.736-.601c-.073.018-.135.049-.19.073-.11.061-.178.129-.172.19 0 .062.08.123.197.172.135.055.325.08.521.067.399-.018.687-.19.687-.319 0-.128-.319-.263-.718-.232a.973.973 0 00-.325.049z"
                    fill="#A55A0F"
                />
                <Path
                    d="M16.538 10.65c.122-.104.27-.129.33-.062.062.068.007.209-.116.313-.122.104-.27.129-.331.062-.055-.068-.006-.209.117-.313zM16.203 11.194c.067.056.043.215-.061.331-.105.123-.246.172-.325.11-.08-.06-.043-.214.06-.33.117-.123.258-.172.326-.11zM16.469 11.232c.073-.086.178-.122.233-.073.055.043.037.153-.043.239-.074.086-.178.123-.233.073-.05-.049-.03-.153.043-.239z"
                    fill="#F4B503"
                />
                <Path
                    d="M21.396 19.182c1.65-.601 1.87-2.196 1.545-2.925-.073-.172-.178-.295-.3-.35-.705-.325-.97.546-2.104.969-1.14.417-1.9-.08-2.226.632-.055.116-.049.276.012.453.234.749 1.393 1.84 3.073 1.22z"
                    fill="#22213F"
                />
                <Path
                    d="M18.304 17.817c.607.209 1.576.178 2.557-.183.981-.362 1.735-.976 2.06-1.527-.073-.172-.134-.172-.257-.227-.705-.325-.969.545-2.104.969-1.14.417-1.9-.08-2.226.631-.049.117-.092.16-.03.337z"
                    fill="#fff"
                />
                <Path
                    d="M21.22 19.316c-.809.233-1.428.061-1.814-.129-.644-.319-1.018-.859-1.129-1.227-.067-.202-.067-.38 0-.52.209-.448.577-.46 1.049-.473.337-.012.748-.024 1.226-.196.466-.178.791-.436 1.055-.638.368-.294.662-.527 1.11-.319.141.062.258.196.337.387.197.447.178 1.091-.061 1.63-.19.455-.613 1.062-1.527 1.393a1.77 1.77 0 01-.245.092zm-2.434-2.134c-.16.043-.282.14-.374.33-.043.093-.037.234.012.406.105.33.448.834 1.043 1.128.405.196 1.067.368 1.925.043.871-.319 1.258-.89 1.442-1.306.263-.62.202-1.202.06-1.515-.067-.153-.159-.264-.263-.307-.355-.165-.576.013-.944.295-.27.214-.607.484-1.098.668-.503.184-.926.196-1.27.209-.208.012-.392.012-.533.049z"
                    fill="#A55A0F"
                />
                <Path
                    d="M29.564 15.958c.154-.024.276 0 .362.08a.286.286 0 01.08.153c.043.252-.166.65-.534.994-.184.172-.38.306-.564.392a.094.094 0 01-.11-.043c-.025-.036-.006-.08-.006-.08.171-.08.398-.232.57-.386.356-.337.509-.674.478-.846-.006-.03-.018-.055-.037-.067-.122-.123-.54.006-.944.386-.343.331-.533.724-.552.754-.018.037-.067.061-.104.043a.088.088 0 01-.043-.104c.11-.337.442-.675.589-.816.288-.257.582-.423.815-.46z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M12.502 8.243c.533-.208 1.042-.19 1.128.037.086.227-.276.576-.81.785-.533.208-1.042.19-1.128-.037-.086-.227.276-.576.81-.785z"
                    fill="#332E25"
                />
                <Path
                    d="M12.128 9.3c-.264 0-.454-.079-.515-.232-.11-.276.264-.662.859-.895.594-.234 1.128-.197 1.232.085.11.276-.264.663-.858.896a2.08 2.08 0 01-.718.147zm1.067-1.11c-.184 0-.417.044-.668.142-.51.196-.822.521-.76.68.06.16.508.185 1.017-.012.51-.196.822-.52.76-.68-.024-.086-.153-.13-.349-.13z"
                    fill="#2D1D1D"
                />
                <Path
                    d="M24.138 7.982c.067-.235-.326-.552-.876-.708-.55-.157-1.051-.093-1.118.141-.066.235.326.552.876.708.55.157 1.051.094 1.118-.141z"
                    fill="#332E25"
                />
                <Path
                    d="M23.573 8.302c-.178 0-.38-.03-.601-.092-.614-.177-1.018-.52-.939-.81.043-.146.203-.244.448-.275.22-.03.497 0 .773.08.613.178 1.018.521.938.81-.049.19-.294.287-.62.287zm-.914-1.018c-.055 0-.11 0-.16.007-.171.018-.288.08-.306.159-.05.16.294.454.822.607.527.147.968.08 1.018-.08.049-.16-.295-.453-.822-.607a1.809 1.809 0 00-.552-.086zM18.937 34.064c-.031 0-.068 0-.105-.013-.496-.085-1.25-.73-1.398-1.183a.407.407 0 01.104-.417c.73-.742 1.448-1.969.76-3.588a.095.095 0 01.05-.122c.049-.019.098 0 .123.049.73 1.717-.031 3.005-.798 3.79a.221.221 0 00-.055.233c.123.392.828.987 1.251 1.06.104.019.178 0 .215-.048.03-.037.037-.086.024-.148-.043-.257-.38-.668-.748-.907-.024-.013-.037-.037-.043-.068-.006-.024.006-.055.025-.073.122-.135.239-.3.337-.436.03-.043.061-.08.086-.116.196-.264.343-.534.441-.797a3.397 3.397 0 00-.08-2.619.098.098 0 01.044-.123.098.098 0 01.122.043c.393.847.423 1.852.086 2.76a3.478 3.478 0 01-.466.84c-.024.037-.055.074-.086.117-.086.116-.178.251-.282.374.386.276.7.68.748.975.019.116 0 .214-.061.288a.37.37 0 01-.294.129z"
                    fill="#2D1D1D"
                />
                <Defs>
                    <LinearGradient
                        id="paint0_linear_2019_3363"
                        x1={20}
                        y1={0.0664062}
                        x2={20}
                        y2={40.0664}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FFCF53" />
                        <Stop offset={1} stopColor="#F90" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export default UserBeeIcon;
