import React  from 'react';
import { StyleSheet, Text } from 'react-native';
import CoinIcon from '../../resources/svgComponents/CoinIcon';
import { COLORS } from '../../constants/colors';
import LinearGradient from 'react-native-linear-gradient';

const YourPoints = ({points, text}: {
    points: number | undefined
    text?: string
}) => {
    return (
        <LinearGradient useAngle={false}
                    // angle={90}
                    colors={['#FFCF53', '#F90']}
                        style={styles.pointsContainer}>
            <CoinIcon size={57} containerStyle={{
                position: 'absolute',
                left: -2,
                top: -2,
            }}/>
            <Text style={styles.pointsText}>{`${text ?? 'Your points'}:`}</Text>

            <Text style={styles.pointsNumber}>{points ?? 0}</Text>
        </LinearGradient>
    );
};

export {YourPoints};

const styles = StyleSheet.create({
    pointsContainer: {
        minWidth: 167,
        height: 50,
        borderRadius: 25,
        paddingRight: 16,
        paddingLeft: 65,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'relative',
    },
    pointsText: {
        fontFamily: 'Secular One',
        fontSize: 16,
        fontWeight:'600',
        color: COLORS.black,
    },
    pointsNumber: {
        fontFamily: 'Secular One',
        fontSize: 18,
        fontWeight:'600',
        color: COLORS.black,
        marginLeft: 10,
    },
});
