import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

const SCREEN_SIZES = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

const LARGE_WIDTH = 1600;
const LARGE_HEIGHT = 900;

const MEDIUM_WIDTH = 1200;
const MEDIUM_HEIGHT = 850;

const SMALL_WIDTH = 600;
const SMALL_HEIGHT = 700;

const findSize = (width: number, height: number) => {
    if (width >= LARGE_WIDTH && height >= LARGE_HEIGHT) {
        return SCREEN_SIZES.LARGE;
    }
    else if (width <= SMALL_WIDTH && height <= SMALL_HEIGHT) {
        return SCREEN_SIZES.SMALL;
    }
    else if (width >= MEDIUM_WIDTH || height >= MEDIUM_HEIGHT) {
        return SCREEN_SIZES.MEDIUM;
    }
    return undefined;
};

const useScreenSize = () => {
    const dims = useWindowDimensions();
    const [size, setSize] = useState(findSize(dims.width, dims.height));

    useEffect(() => {
        setSize(findSize(dims.width, dims.height));
    }, [dims]);

    return size;
};

export { SCREEN_SIZES, useScreenSize };
