import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Divider } from './Divider';
import LinearGradient from 'react-native-linear-gradient';
import { HiveType } from '../screens/MyHives/useGetHivesQuery.gql';
import { applyTransparency } from '../../constants/colors';
import { HiveSize, PaymentStatus } from '../../constants/constants';
import { ChangeNameIcon } from '../../resources/svgComponents/ChangeNameIcon';

interface IMyHiveCardProps {
  hive: HiveType,
  isTeacher: boolean,
  onPress: (hive: HiveType) => void,
  onChangeNamePress: (hive: HiveType) => void;
}
const MyHiveCard = ({ hive, isTeacher, onPress, onChangeNamePress }: IMyHiveCardProps) => {
  return (
    <>
      <View style={styles.hivesContainer}>
        <TouchableOpacity onPress={() => onPress(hive)}>
          <LinearGradient style={styles.hiveCard} colors={['#1A71AFE5', '#4F3AD4E5']}>
            {
              hive.paymentStatus !== PaymentStatus.Active &&
              <>
                <Divider empty height={4} />
                <View style={styles.chipContainer}>
                  <Text style={styles.chipText}>Inactive</Text>
                </View>
                <Divider empty height={4} />
              </>
            }
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={styles.hiveCardText} numberOfLines={2}>{hive.name}</Text>
              {
                isTeacher &&
                <TouchableOpacity style={{ marginLeft: 8 }} onPress={() => onChangeNamePress(hive)}>
                  <ChangeNameIcon color="white" />
                </TouchableOpacity>
              }
            </View>
            <Divider empty height={4} />
            <Text style={styles.hiveType}>Size: {hive.size === HiveSize.ClassHive ? 'Class' : hive.size === HiveSize.FamilyHive ? 'Group' : 'Single'}</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    </>
  );
};

export { MyHiveCard };

const styles = StyleSheet.create({
  hivesContainer: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
    marginHorizontal: 6,
  },
  hiveType: {
    fontFamily: 'Inter',
    color: 'white',
    textAlign: 'center',
    fontSize: 10,
  },
  hiveCard: {
    width: 280,
    height: 94,
    borderRadius: 10,
    borderColor: '#FFFFFF66',
    borderWidth: 4,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  hiveCardText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: 'white',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    maxWidth: 220,
  },
  chipContainer: {
    backgroundColor: '#ED4A4A',
    height: 18,
    width: 56,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  chipText: {
    fontFamily: 'Inter',
    color: 'white',
    fontSize: 8,
  },
});
