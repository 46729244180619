import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation joinAIGame($difficultyLevel: AIDifficultyLevel!, $userName: String!, $aiName: String!, $gameType: GameType!) {
    joinAIGame(difficultyLevel: $difficultyLevel, userName: $userName, aiName: $aiName, gameType: $gameType)
  }
`;

export interface RequestType {
  difficultyLevel: AIDifficultyLevel;
  userName: string;
  aiName: string;
  gameType: GAME_TYPES_TYPE;
}

export enum AIDifficultyLevel {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  HARD = 'HARD',
  EXTREME = 'EXTREME',
}

export interface MutationType {
  joinAIGame: boolean;
}

export function useJoinAIGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
