export const applyTransparency = (color: COLORS | string, transparencyFloat: number) => {
  if (transparencyFloat < 0 || transparencyFloat > 1) {
    return color;
  }

  let transparencyHex = Math.floor(transparencyFloat * 255).toString(16);
  if (transparencyHex.length === 1) {
    transparencyHex = '0' + transparencyHex;
  }

  return color + transparencyHex;
};

export enum COLORS {
  offRed = '#FF3B3B',
  black = '#000000',
  offBlack = '#333333',
  white = '#ffffff',
  offWhite = '#CECECE',
  AllportsBlue = '#03769e',
  englishViolet = '#4D2D52',
  plum = '#9A4C95',
  darkPurple = '#1D1A31',
  lightGray = '#E3E3E3',
  darkPurpleLight = '#2B203B',
  primaryBlack = '#121925',
  primaryPink = '#FF239A',
  textBlack = '#41423F',
  gray = '#898989',
  placeholder = '#C1C1C1',
  error = '#EB5038',
  pumpkinYellow = '#E89823',
  inputBorderLightGray = '#C4C4C4',
  inactiveInput = '#F9F9F9',
  inactiveInputText = '#878787',
  buttonDanger= '#D72F2F',
  backgroundOrange = '#FFF4E3',
}
