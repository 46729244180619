import { nanoid } from 'nanoid';
import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

const PasswordIcon = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    const gradientId = nanoid();
    return (
        <View style={props.containerStyle}>
            <Svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <Path
                    d="M14.25 6.318V5.25a5.25 5.25 0 00-10.5 0v1.068A3.75 3.75 0 001.5 9.75v4.5A3.755 3.755 0 005.25 18h7.5a3.755 3.755 0 003.75-3.75v-4.5a3.75 3.75 0 00-2.25-3.432zm-4.5 6.432a.75.75 0 01-1.5 0v-1.5a.75.75 0 011.5 0v1.5zm3-6.75h-7.5v-.75a3.75 3.75 0 017.5 0V6z"
                    fill={`url(#${gradientId})`}
                />
                <Defs>
                    <LinearGradient
                        id={gradientId}
                        x1={1.49693}
                        y1={9.00547}
                        x2={17.3465}
                        y2={9.00547}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#949494" />
                        <Stop offset={1} stopColor="#595959" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export { PasswordIcon };
