import { gql, useQuery, QueryHookOptions } from '@apollo/client';

export const query = gql`
  query HiveUsers($hiveId: ID!, $pageSize: Int!, $page: Int!) {
    hiveUsers(hiveId: $hiveId, pageSize: $pageSize, page: $page) {
      id
      firstName
      lastName
      userName
      password
      points
    }
  }
`;

export interface RequestType {
  hiveId: string;
  pageSize?: number;
  page?: number;
}

export interface HiveUserType {
  id: string;
  firstName: string;
  lastName: string;
  userName: string;
  password?: string;
  points?: number;
}

export interface QueryType {
  hiveUsers: HiveUserType[];
}

export function useHiveUsersQuery(request: RequestType, options?: QueryHookOptions<QueryType, RequestType>) {
  return useQuery<QueryType, RequestType>(query, {
    variables: request,
    ...options,
  });
}
