import React from 'react';
import { StyleSheet, TouchableOpacity, Text, Image } from 'react-native';
import { applyTransparency } from '../../constants/colors';
import { RefreshIcon } from '../../resources/svgComponents/RefreshIcon';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import LinearGradient from 'react-native-linear-gradient';

const refreshIcon = require('../../resources/images/refresh.png');

interface IRefreshButtonProps {
  onPress: () => void;
}
const RefreshButton = (props: IRefreshButtonProps) => {
  if (isMobile) {
    return (
      <TouchableOpacity onPress={props.onPress} >
        <Image source={refreshIcon} style={{width: 40, height: 40}}/>
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity onPress={props.onPress}>
        <LinearGradient style={styles.container} colors={['#FFCF53', '#FF9900']}>
          <RefreshIcon width={28} height={20} />
          <Text maxFontSizeMultiplier={1} style={styles.text}>
            Refresh
          </Text>
        </LinearGradient>
      </TouchableOpacity>
    );
  }
};

export { RefreshButton };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 8,
    borderRadius: 10,
    borderWidth: 4,
    borderColor: applyTransparency('#FFFFFF', 0.7),
  },
  text: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 18,
    marginTop: 4,
    color: 'white',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
});
