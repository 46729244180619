import React from 'react';
import { MenuButton } from '../../helperComponents/MenuButton';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { lobbyButtonSharedProps, ButtonRowDivider} from './utils';


const bee2Icon = require('../../../resources/images/bee2.png');
const bee3Icon = require('../../../resources/images/bee4.png');

const PlayAFriendLobby = ({navigation, playingName}: {
    navigation: UniversalProps['navigation'],
    playingName: string | undefined
})=> {

    return (
        <>

            <MenuButton
                onPress={() => navigation.navigate('SelectGameType', { next: 'HostGame', userName: playingName })}
                colors={['#FFCF53', '#FF9900']}
                text="Host a game"
                image={bee2Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
            <MenuButton
                onPress={() => navigation.navigate('JoinGame', { userName: playingName })}
                colors={['#90DF75', '#62B655']}
                text="Join A Game"
                image={bee2Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
            <MenuButton
                onPress={() => navigation.navigate('SelectGameType', { next: 'PassAndPlay' })}
                colors={['#FF9252', '#FF3F15']}
                text="Play and Pass"
                image={bee3Icon}
                {...lobbyButtonSharedProps}
            />
            <ButtonRowDivider/>
        </>
    );
};


export {PlayAFriendLobby};
