import { ApolloError } from '@apollo/client';

export enum ErrorsText {
    GAME_FINISHED = 'Game has finished.',
    EMPTY_PASSWORD = 'Password cannot be empty.',
    USER_NOT_ACTIVE = 'The user is not active at the moment.',
    INVITEE_ALREADY_IN_GAME = 'The invitee user is already in a game.',
    HIVE_NOT_FOUND = 'Hive not found or not active.',
    USER_NOT_RELATED_TO_TEACHER = 'User not found or not related to teacher.',
    HIVE_NAME_ALREADY_USED = 'Hive name already used.',
    EMAIL_USERNAME_ALREADY_TAKEN = 'This email/username address is already taken.',
    USER_ALREADY_JOINED = 'User already joined.',
    USER_NOT_IN_HIVE = 'User not in hive.',
    INVALID_CREDENTIALS = 'Email/Username or password does not match.',
    INVALID_VERIFICATION_CODE = 'Verification code was invalid.',
    INVALID_RESET_PASSWORD_CODE = 'Reset password code was invalid.',
    NO_SEAT_LEFT = 'No seat left.',
    INVALID_HIVE_KEY = 'Hive key is not valid.',
    NAME_CONTAINS_BAD_WORDS = 'has bad words!',
    ARGUMENT_VALIDATION_ERROR = 'Argument Validation Error'
}

export function getErrorText(err: ApolloError) {
    let errorMessage: string | undefined = err.message;
    if (ErrorsText.ARGUMENT_VALIDATION_ERROR.includes(errorMessage)) {
        const [constraint] = Object.values<string | undefined>(err.graphQLErrors[0]?.extensions?.exception?.validationErrors[0]?.constraints || {});
        errorMessage = constraint;
    }
    if (errorMessage && Object.values(ErrorsText).some(errorText => errorText.includes(errorMessage as string) || errorMessage?.includes(errorText))) {
        if (ErrorsText.GAME_FINISHED.includes(errorMessage) || errorMessage.includes(ErrorsText.GAME_FINISHED)) {
            return 'The invitation is no longer valid';
        } else if (ErrorsText.NAME_CONTAINS_BAD_WORDS.includes(errorMessage) || errorMessage.includes(ErrorsText.NAME_CONTAINS_BAD_WORDS)) {
            return 'Please choose a more appropriate name.';
        }
        return errorMessage;
    }
    return 'Something went wrong.';
}
