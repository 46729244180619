import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Linking } from 'react-native';
import {useNavigation} from '@react-navigation/native';
import { applyTransparency, COLORS } from '../../../constants/colors';
import { Divider } from '../Divider';
import LinearGradient from 'react-native-linear-gradient';
import { ArrowIcon } from '../../../resources/svgComponents/ArrowIcon';
import { useSignedInContext } from '../../../context/SignedInContext';
import { useOpenClose } from '../../../utils/hooks';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { LogoutIcon } from '../../../resources/svgComponents/LogoutIcon';
import { useSignOutMutation } from './useSignoutMutation.gql';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import { SettingIcon } from '../../../resources/svgComponents/SettingIcon';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { envs } from '../../../../env';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../utils/utils';
import TrashBinIcon from '../../../resources/svgComponents/TrashBinIcon';
import PrivacyIcon from '../../../resources/svgComponents/PrivacyIcon';
import ContactUsIcon from '../../../resources/svgComponents/ContactUsIcon';
import TermsIcon from '../../../resources/svgComponents/TermsIcon';
import { Popup } from '../Popup';
import { MenuButton } from '../MenuButton';
import { ServerRedirectNavigationProp } from '../../../typesInterfacesEnums/componentProps';
import { useDeleteAccountQuery } from '../../../graphql/deleteUser';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../ErrorPopup';
import { getErrorText } from '../../../utils/errors';


interface IDropdownMenu {
  backButton?: ReactNode;
  items?: {
    text: string;
    icon: ReactNode;
    action: () => void;
  }[]
}

const DropdownMenu = ({ backButton, items }: IDropdownMenu) => {
  const { user, signOut, isSignedIn } = useSignedInContext();
  const [signOutRequest] = useSignOutMutation();
  const [isDropDownVisible, setDropDownOpen, setDropDownClose] = useOpenClose(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [hasError, setHasError] =  useState({
    visible: false,
    popup: false,
    text: '',
  });

  useEffect(() => {
    if (!showConfirmDelete && hasError.popup) {
      setHasError({visible: false, popup: false, text: ''});
    }
  }, [showConfirmDelete, hasError]);

  const navigation = useNavigation<ServerRedirectNavigationProp>();
  const [deleteRecord] = useDeleteAccountQuery();

  const handleSignOut = useCallback(() => {
    signOutRequest().then(() => {
      signOut();
    }).catch((err) => {
      console.log(err);
    });
  },[signOutRequest, signOut]);

  const handleDeleteAccount = useCallback(() => {
    setShowConfirmDelete(true);
  },[]);

  const userActions = useMemo(()=> {
    const actions = {
      deleteAccount: {
        text: 'Delete account',
        textColor: COLORS.error,
        icon: <TrashBinIcon color={COLORS.error}/>,
        action: handleDeleteAccount,
      },
      privacyPolicy: {
        text: 'Privacy policy',
        textColor: COLORS.black,
        icon: <PrivacyIcon color={COLORS.black}/>,
        action: () => Linking.openURL(envs.PRIVACY_POLICY_LINK),
      },
      termsOfService: {
        text: 'Terms of Use',
        textColor: COLORS.black,
        icon: <TermsIcon color={COLORS.black}/>,
        action: () => Linking.openURL(envs.TERMS_OF_SERVICE_LINK),
      },
      contactUs: {
        text: 'Contact us',
        textColor: COLORS.black,
        icon: <ContactUsIcon color={COLORS.black}/>,
        action: () => {
          console.log('contact us');
          navigation.navigate('ContactUs');},
      },
      logout: {
        text: 'Log out',
        textColor: COLORS.black,
        icon: <LogoutIcon />,
        action: handleSignOut,
      },
    };

    if (user && user.userType === USER_TYPES.student) {
      return [actions.contactUs, actions.deleteAccount, actions.logout];
    }
    return [actions.privacyPolicy, actions.termsOfService, actions.contactUs, actions.deleteAccount, actions.logout];

  },[user, handleSignOut, handleDeleteAccount, navigation]);

  if (!isSignedIn) {
    if (backButton) {
      return (
        <View style={styles.topContainer}>
          {backButton}
        </View>
      );
    }
    return null;
  }

  const getUserName = () => {
    if (user) {
      if (user.userType === USER_TYPES.teacher) {
        return user.firstName;
      } else {
        if (user.displayName) {
          return user.displayName;
        }
        return user.email;
      }
    } else {
      return 'Loading...';
    }
  };

  const handleManageBillingClick = async () => {
    if (!isMobile) {
      let token = await MB_accessTokenUtils.getAccessToken();
      token = token?.replace('Bearer ', '');
      utils.openExternalWindow(envs.API_URL + `/api/subscription/stripe/manageBilling?accessToken=${token}`, 'Manage billing');
    }
  };

  const handleDeleteError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const deleteAccountAction = async () => {
    deleteRecord().then(() => {
      setTimeout(() => signOut({ skipSignOutRequest: true }), 500);
    }).catch((err) => {
        handleDeleteError(err);
    });
  };
  return (
    <View style={styles.topContainer}>
      {hasError.visible && <ErrorPopup
          isVisible={hasError.visible}
          setVisible={() => setHasError(v => ({ ...v, popup: false }))}
          text={hasError.text}
      />}
      <Popup title={'Delete Account'}
             headerTextStyle={{fontSize: 22}}
             visible={showConfirmDelete}
             setVisible={setShowConfirmDelete}
             height={250}
      >
        <View style={{
            flex: 1,
            paddingHorizontal: 20,
            paddingTop: 45,
          alignItems: 'center',
        }}>
          <Text style={styles.deletePopupText}>
            Are you sure you want to delete your account? This action cannot be undone.
          </Text>
          <Divider height={24} empty/>
          <MenuButton text={'Delete'}
                      onPress={deleteAccountAction}
                      colors={['#DF3A3A', '#C91717']}
                      width={320}
                      height={58}
                      textStyle={{
                        fontSize: 18,
                        fontWeight: '400',
                      }}
          />
        </View>
      </Popup>
      <View style={{position: 'relative'}}>
        {
          backButton !== undefined ?
            <>
              {backButton}
              <Divider empty height={20} />
            </>
            :
            null
        }
        <TouchableOpacity style={styles.loginContainer} onPress={() => isDropDownVisible ? setDropDownClose() : setDropDownOpen()}>
          <LinearGradient style={styles.nameContainer} colors={['#FFCF53', '#FF9900']}>
            <Text numberOfLines={1} maxFontSizeMultiplier={1} style={styles.name}>
              {getUserName()}
            </Text>
          </LinearGradient>
          <ArrowIcon active={isDropDownVisible} containerStyle={styles.dropdownArrow} />
        </TouchableOpacity>
        <View style={[styles.dropdown, { display: isDropDownVisible ? 'flex' : 'none', top: backButton ? 100 : 50 }]}>
          {
            user && user.userType === USER_TYPES.student ?
              <View style={styles.passwordContainer}>
                <Text style={styles.dropdownText}>Password: {user.userPassword}</Text>
              </View>
              :
              null
          }
          {
            items?.map((element, index) => {
              return (
                <TouchableOpacity onPress={element.action} style={styles.dropdownItem} key={`${element.text}-${index}`}>
                  {element.icon}
                  <Text style={styles.dropdownText}>{element.text}</Text>
                </TouchableOpacity>
              );
            })
          }
          {
            user && user.userType === USER_TYPES.teacher && !isMobile && user.customerId ?
              <TouchableOpacity onPress={() => handleManageBillingClick()} style={styles.dropdownItem}>
                <SettingIcon />
                <Text style={styles.dropdownText}>Manage subscription</Text>
              </TouchableOpacity>
              :
              null
          }
          {userActions.map((action, index) => {
            return (
              <TouchableOpacity onPress={action.action}
                                style={styles.dropdownItem}
                                key={`${action.text}-${index}`}
              >
                {action.icon}
                <Text style={[styles.dropdownText, { color: action.textColor }]}>{action.text}</Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export { DropdownMenu };

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...mbPlatformStyle({
      mobile: {
        left: 20,
      },
      web: {
        left: 60,
      },
    }),
    position: 'absolute',
    top: 60,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 170,
    maxWidth: 170,
    paddingLeft: 20,
    paddingRight: 24,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  dropdown: {
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: '#FFF3E2',
    width: 200,
    ...mbPlatformStyle({
      mobile: {
        width: 170,
      },
    }),
    height: 'auto',
    // height: 76,
    left: 0,
    justifyContent: 'center',
    zIndex: 200,
  },
  passwordContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  dropdownItem: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  dropdownText: {
    fontFamily: 'Secular One',
    fontWeight: '600',
    fontSize: 12,
    marginLeft: 12,
    color: applyTransparency('#000000', 0.85),
  },
  dropdownArrow: {
    position: 'absolute',
    right: 10,
  },
  deletePopupContainer: {
    padding: 20,
  },
  deletePopupText: {
    fontFamily: 'Secular One',
    fontWeight: '400',
    fontSize: 19,
    textAlign: 'center',
  },
});
