import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const PencilIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 21}
          height={props.height ?? 22}
          viewBox="0 0 21 22"
          fill="none"
          {...props}
        >
          <G clipPath="url(#clip0_82_74)">
            <Path
              d="M20.522 3.596L17.906.98a1.645 1.645 0 00-2.324 0L2.432 14.13a.411.411 0 00-.098.156l-2.31 6.67a.41.41 0 00.522.521l6.67-2.31a.41.41 0 00.155-.097l13.15-13.15c.636-.608.636-1.715 0-2.324zm-3.48 4.644l-2.835-2.834-.945-.945.87-.87 3.78 3.78-.87.87zm-9.684 9.683l-.182-1.128 8.63-8.63.656.655-9.104 9.103zm5.324-12.882l.655.654-8.63 8.63-1.128-.181 9.103-9.103zm1.235 1.234l1.31 1.31-8.654 8.653-1.273-.036-.036-1.273 8.653-8.654zM2.997 14.88l1.451.235.044 1.497a.41.41 0 00.398.398l1.497.044.235 1.452-3.245 1.123a2.862 2.862 0 00-.606-.898 2.862 2.862 0 00-.898-.606l1.123-3.245zm-1.394 4.023c.438.2.797.558.996.995l-1.523.528.527-1.523zM19.942 5.34l-1.45 1.45-3.78-3.78 1.45-1.45a.824.824 0 011.164 0l2.616 2.616a.831.831 0 010 1.164z"
              fill="#684403"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_82_74">
              <Path fill="#fff" transform="translate(0 .5)" d="M0 0H21V21H0z" />
            </ClipPath>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { PencilIcon };
