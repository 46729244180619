import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { ApolloError } from '@apollo/client';
import { useVerifyForgetPasswordCodeMutation } from './useVerifyForgetPasswordCodeMutation.gql';
import { useRequestResetPasswordCodeMutation } from './useRequestForgetPasswordCodeMutation.gql';
import { getErrorText } from '../../../utils/errors';

const VerifyForgetPasswordCode = ({ navigation, route }: ScreenProps<'VerifyForgetPasswordCode'>) => {
  const { setSignedInStatus } = useSignedInContext();
  const [code, setCode] = useState('');
  const [verifyCode, { data: verifyCodeData, error: verifyCodeError, loading }] = useVerifyForgetPasswordCodeMutation({
    variables: { email: route.params.email, resetPasswordCode: code },
  });
  const [requestCode, { loading: requestCodeLoading }] = useRequestResetPasswordCodeMutation({
    variables: { email: route.params.email },
  });
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });


  const handleVerifyCodeError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (verifyCodeData) {
      navigation.navigate('ResetPassword', { code: code, email: route.params.email });
    }
  }, [verifyCodeData, navigation, setSignedInStatus, code, route.params.email]);

  useEffect(() => {
    if (verifyCodeError) {
      handleVerifyCodeError(verifyCodeError);
    }
  }, [verifyCodeError]);

  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.goBack()} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Verify Code
        </Text>
        <Divider empty height={12} />
        <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
          Please enter the verification code that was sent to your email address.
        </Text>
        <Divider empty height={40} />
        <Input
          value={code}
          setValue={(value) => setCode(value)}
          style={{ width: '100%', maxWidth: 320 }}
          textStyle={{ fontSize: 18 }}
          placeholder="Please enter the code"
          onSubmitEditing={() => verifyCode()}
        />
        <Divider empty height={60} />
        <MenuButton
          onPress={() => verifyCode()}
          colors={['#90DF75', '#62B655']}
          text="Verify code"
          height={64}
          width={320}
          loading={loading || requestCodeLoading}
          disabled={loading || requestCodeLoading || code.length !== 6}
        />
        <Divider empty height={20} />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text maxFontSizeMultiplier={1} style={styles.resendText}>
            Didn’t receive code?
          </Text>
          <TouchableOpacity onPress={() => requestCode().catch((error) => handleVerifyCodeError(error))}>
            <Text style={styles.resendText2}>
              Resend
            </Text>
          </TouchableOpacity>
        </View>
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { VerifyForgetPasswordCode };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subtitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    width: 320,
    color: '#E89823',
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  resendText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  resendText2: {
    color: '#E89823',
    fontFamily: 'Secular One',
    fontSize: 20,
    marginLeft: 6,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#E89823',
  },
});
