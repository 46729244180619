import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SeatIcon({ size = 22 }: { size?: number }) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
    >
      <Path
        d="M19.129 9.21h-1.015a.578.578 0 00-.578.579v4.76h-.333a1.614 1.614 0 00-1.295-.653H6.096c-.53 0-1 .257-1.295.652l-.333.001V9.79a.578.578 0 00-.578-.578H2.875a.578.578 0 000 1.156h.437v4.764a.578.578 0 00.578.578h.003l.6-.004c.096.8.778 1.421 1.603 1.421h4.328v1.514H7.462a.578.578 0 00-.578.578v1.938a.578.578 0 001.156 0v-1.36h2.384v1.359a.578.578 0 001.156 0v-1.36h2.384v1.36a.578.578 0 001.156 0v-1.937a.578.578 0 00-.578-.578H11.58v-1.514h4.328c.824 0 1.506-.621 1.603-1.42l.6.003h.003a.578.578 0 00.578-.578v-4.764h.437a.578.578 0 100-1.156z"
        fill="#E89823"
      />
      <Path
        d="M6.008 6.999a4.999 4.999 0 019.997 0v5.739H6.008v-5.74z"
        fill="#E89823"
      />
      <Path
        d="M11.006 2a5.004 5.004 0 00-4.998 4.999v5.739h9.997v-5.74A5.004 5.004 0 0011.006 2zm0 9.239a.58.58 0 01-.533-.8.568.568 0 01.533-.354c.218 0 .436.118.534.354a.58.58 0 01-.534.8zm1.31-4.147a1.813 1.813 0 01-.732.468v.802a.578.578 0 01-1.156 0V7.077c0-.319.26-.578.578-.578.18 0 .348-.072.472-.203a.652.652 0 00-.458-1.098h-.014a.65.65 0 00-.65.65.578.578 0 01-1.156 0 1.805 1.805 0 011.807-1.806h.036c.943.02 1.719.77 1.768 1.711.026.5-.15.977-.495 1.34z"
        fill="#E89823"
      />
    </Svg>
  );
}

export { SeatIcon };

