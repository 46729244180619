import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, useWindowDimensions } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { MenuButton } from '../../helperComponents/MenuButton';
import { applyTransparency } from '../../../constants/colors';
import { useSignedInContext } from '../../../context/SignedInContext';
import { BackButton } from '../../helperComponents/BackButton';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import { HiveType, useGetHivesQuery } from './useGetHivesQuery.gql';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { PaymentStatus } from '../../../constants/constants';
import { MyHiveCard } from '../../helperComponents/MyHiveCard';
import { DropdownMenu } from '../../helperComponents/DropdownMenu/DropdownMenu';
import { useChangeHiveNameMutation } from '../MyHiveData/useChangeHiveNameMutation';
import { useOpenClose } from '../../../utils/hooks';
import { ChangeHiveNamePopup } from '../../helperComponents/ChangeHiveNamePopup';
import { getErrorText } from '../../../utils/errors';

const MyHives = ({ navigation, route }: ScreenProps<'MyHives'>) => {
  const { user } = useSignedInContext();
  const { height } = useWindowDimensions();
  const { error: getHivesError, data: getHivesData, refetch } = useGetHivesQuery();
  const [changeHiveName, { loading: changeHiveNameLoading }] = useChangeHiveNameMutation();

  const [isChangeNamePopupVisible, setChangeNamePopupOpen, setChangeNamePopupClose] = useOpenClose();

  const [hives, setHives] = useState<HiveType[]>([]);
  const [selectedHive, setSelectedHive] = useState<undefined | HiveType>(undefined);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleSelectHiveForChange = (hiveData: HiveType) => {
    setSelectedHive(hiveData);
    setChangeNamePopupOpen();
  };

  const handleChangeName = (name: string) => {
    if (selectedHive) {
      changeHiveName({ variables: { hiveId: selectedHive.id, name: name } }).then(() => {
        refetch();
        setChangeNamePopupClose();
        setSelectedHive(undefined);
      }).catch((err) => {
        handleError(err);
        setChangeNamePopupClose();
        setSelectedHive(undefined);
      });
    }
  };

  const handleError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (getHivesData) {
      setHives(getHivesData.hives);
    }
  }, [getHivesData]);

  useEffect(() => {
    if (getHivesError) { handleError(getHivesError); }
  }, [getHivesError]);

  useEffect(() => {
    if (route.params) {
      if (route.params.shouldRefetch) {
        refetch();
      }
    }
  }, [refetch, route.params]);

  const onHivePress = (hive: HiveType) => {
    if (user?.userType === USER_TYPES.student) {
      if (hive.paymentStatus === PaymentStatus.Active) {
        navigation.navigate('MyHiveDataStudent', hive);
      } else {
        setHasError({ popup: true, visible: true, text: 'The hive is not activated yet.' });
      }
    } else {
      navigation.navigate('MyHiveData', { id: hive.id });
    }
  };

  return (
    <>
      <ComponentWrapper hasInset hasScroll={true} scrollEnabled style={{ paddingHorizontal: 0 }}>
        <Divider empty height={isMobile ? 140 : height / 4} />
        <Divider empty height={24} />
        <Text style={styles.title}>My Hives</Text>
        <Divider empty height={20} />
        {user?.userType === USER_TYPES.teacher && !isMobileApp &&
          <>
            <Text style={styles.subTitle}>Create your own group, class or school hives to access all additional game boards (for all users) and user analytics</Text>
            <Text style={[styles.subTitle, styles.note]}>Click on a hive to see analytics and leaderboard</Text>
            <Divider empty height={40} />
          </>
        }
        {
          user?.userType === USER_TYPES.teacher ?
            isMobileApp ? null :
              <>
                <MenuButton
                  onPress={() => navigation.navigate('SelectHiveSize')}
                  colors={['#90DF75', '#62B655']}
                  text="+ Create New Hive"
                  height={74}
                  width={280}
                />
                <Divider empty height={36} />
              </>
            :
            <>
              <MenuButton
                onPress={() => navigation.navigate('JoinHive')}
                colors={['#90DF75', '#62B655']}
                text="Join Hive"
                height={74}
                width={280}
              />
              <Divider empty height={36} />
            </>
        }
        {
          hives.length > 0 ?
            <View style={hives.length > 3 ? styles.hivesContainer : styles.hivesContainerCol}>
              {
                hives.map((hive) => {
                  return (
                    <MyHiveCard
                      hive={hive}
                      key={hive.id}
                      isTeacher={user?.userType === USER_TYPES.teacher}
                      onChangeNamePress={handleSelectHiveForChange}
                      onPress={(value) => onHivePress(value)}
                    />
                  );
                })
              }
              <View style={{ width: 280, height: 0, marginHorizontal: 6 }} />
            </View>
            :
            user?.userType === USER_TYPES.teacher && isMobileApp ?
              <Text>Currently you have no Hives</Text>
              :
              null
        }
        <Divider empty height={isMobile ? 140 : height / 4} />
        <DropdownMenu
          backButton={
            <BackButton
              text="Back to lobby"
              onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })}
            />
          }
        />
      </ComponentWrapper>
      <ChangeHiveNamePopup
        currentName={selectedHive ? selectedHive.name : ''}
        visible={isChangeNamePopupVisible}
        setVisible={setChangeNamePopupClose}
        loading={changeHiveNameLoading}
        onChangePress={handleChangeName}
      />
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { MyHives };

const styles = StyleSheet.create({
  nameContainer: {
    borderRadius: 12,
    width: 190,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -20,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subTitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    fontWeight: '400',
    color: '#000000',
    marginHorizontal: 40,
    textAlign: 'center',
  },
  note: {
    fontSize: 15,
    color: '#E89823',
    marginTop: 10,
  },
  setting: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#6D6D6D',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    marginRight: 10,
  },
  hivesContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: 600,
    flexWrap: 'wrap',
  },
  hivesContainerCol: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 600,
  },
});
