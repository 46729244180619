import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {ComponentWrapper} from '../helperComponents/ComponentWrapper';
import { Divider } from '../helperComponents/Divider';
import { MenuButton } from '../helperComponents/MenuButton';
import { applyTransparency } from '../../constants/colors';
import { useSignOutMutation } from '../helperComponents/DropdownMenu/useSignoutMutation.gql';
import { useSignedInContext } from '../../context/SignedInContext';

const WebOnly = () => {
    const [signOutRequest] = useSignOutMutation();
    const { signOut } = useSignedInContext();

    const handleSignOut = () => {
        signOutRequest().then(() => {
            signOut();
        }).catch((err) => {
            console.log(err);
        });
    };
    return (
        <ComponentWrapper hideBee hasInset hasScroll={false} style={{ paddingHorizontal: 0 }}>
            <View style={styles.container}>
                <View style={styles.wrapper}>
                <>
                    <Text maxFontSizeMultiplier={1} style={styles.title}>
                        Admin panel is accessible from web only.
                    </Text>
                    <Divider empty height={12} />
                    <View style={styles.buttonContainer}>
                        <MenuButton
                            height={62}
                            width={160}
                            onPress={handleSignOut}
                            text="Log out"
                            colors={['#90DF75', '#62B655']}
                            textStyle={styles.buttonTextStyle}
                            style={styles.button}
                        />
                    </View>
                </>
            </View>
            </View>
        </ComponentWrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 24,
    },
    wrapper: {
        maxWidth: 400,
        backgroundColor: '#FDFDFD',
        borderRadius: 24,
        borderColor: '#FF9900',
        borderWidth: 3,
        padding: 16,
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Secular One',
        color: '#000000',
        fontSize: 18,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 4 },
        textShadowRadius: 2,
        textAlign: 'center',
        paddingHorizontal: 12,
    },
    button: {
        marginHorizontal: 12,
    },
    buttonTextStyle: {
        fontFamily: 'Secular One',
        color: '#fff',
        fontSize: 20,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 4 },
        textShadowRadius: 2,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export { WebOnly };
