import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, LinearGradient, Defs, Stop } from 'react-native-svg';

const LeaderBoardHeaderIcon = React.memo(({ size, containerStyle, noColor }: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    noColor?: boolean
}) => {
    const colorGray = '#D9D9D9';
    const borderColor = noColor ? '#AAA' : '#fff';
    const fillColor = noColor ? colorGray : 'url(#paint0_linear_2019_2905)';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 266}
                height={size ? size * (113 / 266) : 113}
                viewBox="0 0 266 113"
                fill="none"
            >
                <Path
                    d="M130.249 2.716c.539-1.297 2.376-1.297 2.916 0l12.336 29.66c.227.546.741.92 1.331.967l32.02 2.567c1.4.112 1.968 1.86.901 2.773l-24.396 20.898c-.449.385-.646.99-.508 1.565l7.453 31.246c.326 1.366-1.16 2.446-2.359 1.714L132.53 77.362a1.579 1.579 0 00-1.646 0L103.47 94.106c-1.198.732-2.685-.348-2.359-1.714l7.454-31.246a1.58 1.58 0 00-.509-1.565L83.66 38.683c-1.066-.914-.499-2.66.901-2.773l32.02-2.567a1.58 1.58 0 001.332-.967l12.336-29.66z"
                    fill={fillColor}
                    stroke={borderColor}
                    strokeWidth={2.89462}
                />
                <Path
                    d="M33.05 39.456c.186-1.392 1.96-1.867 2.817-.754l11.689 15.186c.36.47.954.697 1.536.59l18.848-3.465c1.381-.254 2.382 1.287 1.588 2.446L58.697 69.268a1.579 1.579 0 00-.086 1.644l9.12 16.854c.668 1.235-.489 2.663-1.836 2.266l-18.382-5.416a1.579 1.579 0 00-1.59.426L32.71 98.924c-.968 1.017-2.683.359-2.722-1.045l-.53-19.157a1.579 1.579 0 00-.896-1.38l-17.285-8.275c-1.267-.607-1.17-2.441.153-2.912l18.055-6.423a1.579 1.579 0 001.036-1.28l2.529-18.996z"
                    fill={fillColor}
                    stroke={borderColor}
                    strokeWidth={2.89462}
                />
                <Path
                    d="M232.949 39.456c-.185-1.392-1.96-1.867-2.816-.754l-11.689 15.186c-.361.47-.954.697-1.536.59l-18.848-3.465c-1.381-.254-2.382 1.287-1.588 2.446l10.831 15.809c.335.488.368 1.123.086 1.644l-9.119 16.854c-.668 1.235.488 2.663 1.835 2.266l18.382-5.416a1.579 1.579 0 011.59.426l13.212 13.882c.968 1.017 2.683.359 2.722-1.045l.529-19.157a1.58 1.58 0 01.897-1.38l17.285-8.275c1.267-.607 1.17-2.441-.153-2.912l-18.055-6.423a1.578 1.578 0 01-1.036-1.28l-2.529-18.996z"
                    fill={fillColor}
                    stroke={borderColor}
                    strokeWidth={2.89462}
                />
                <Defs>
                    <LinearGradient
                        id="paint0_linear_2019_2905"
                        x1={131.707}
                        y1={-0.789063}
                        x2={131.707}
                        y2={106.312}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor={noColor ? colorGray : '#FFCF53'} />
                        <Stop offset={1} stopColor="#F90" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint1_linear_2019_2905"
                        x1={33.5519}
                        y1={35.6934}
                        x2={51.5323}
                        y2={102.797}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor={noColor ? colorGray : '#FFCF53'} />
                        <Stop offset={1} stopColor="#F90" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint2_linear_2019_2905"
                        x1={232.448}
                        y1={35.6934}
                        x2={214.468}
                        y2={102.797}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor={noColor ? colorGray : '#FFCF53'} />
                        <Stop offset={1} stopColor="#F90" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export default LeaderBoardHeaderIcon;
