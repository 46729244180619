import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation updatePushToken($token: String!) {
    updatePushToken(token: $token) {
      id
      token
    }
  }
`;

export interface RequestType {
  token: string;
}

export interface MutationType {
  id: string,
  token: string
}

export function useAddFirebaseDeviceToken(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
