import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { EditIcon } from '../../../../resources/svgComponents/EditIcon';
import { RemoveIcon } from '../../../../resources/svgComponents/RemoveIcon';
import { UserItemToDisplayType } from './utils';
import CoinIcon from '../../../../resources/svgComponents/CoinIcon';

const UserItem = ({
    user,
    index,
    editAction,
    removeAction,
}: UserItemToDisplayType) => {
    return (
        <View style={[styles.row, index % 2 !== 0 ? { backgroundColor: 'white' } : null]}>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{user.lastName}</Text>
            </View>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{user.firstName}</Text>
            </View>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{user.userName}</Text>
            </View>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{user.password}</Text>
            </View>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{user.numberOfGames}</Text>
            </View>
            <View style={styles.rowView}>
                <Text style={styles.rowText}>{Math.round((user.totalTime ?? 0) / 60000)}</Text>
            </View>
            <View style={styles.rowView}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <CoinIcon />
                    <Text style={[styles.rowText, { marginLeft: 5 }]}>{user.points}</Text>
                </View>
            </View>
            <View style={styles.rowViewLast}>
                <TouchableOpacity onPress={removeAction} style={{ marginHorizontal: 10 }}>
                    <RemoveIcon />
                </TouchableOpacity>
                <TouchableOpacity onPress={editAction} style={{ marginHorizontal: 10 }}>
                    <EditIcon />
                </TouchableOpacity>
            </View>
            <Text />
        </View>
    );
};

export { UserItem };

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#F9F9F9',
        padding: 20,
    },
    rowViewLast: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingHorizontal: 54,
      },

    rowText: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: 14,
        alignSelf: 'center',
    },
    rowView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
