import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const ShareIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? '44px'}
          height={props.height ?? '51px'}
          viewBox="0 0 44 51"
          {...props}
        >
          <Defs>
            <LinearGradient
              x1="49.9999634%"
              y1="1.32806102%"
              x2="49.9999634%"
              y2="98.6719804%"
              id="a"
            >
              <Stop stopColor="#FFCF53" offset="0%" />
              <Stop stopColor="#F90" offset="100%" />
            </LinearGradient>
          </Defs>
          <G fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
            <Path
              fill="url(#a)"
              stroke="url(#a)"
              strokeWidth={1.10457}
              transform="translate(1.53 1.359)"
              d="M20.75147 0.16553L20.47087 6.66133815e-16 20.19027 0.16553 0.27168 11.91552 1.55431223e-15 12.07582 1.55431223e-15 12.39122 1.55431223e-15 35.89122 1.55431223e-15 36.20662 0.27168 36.36692 20.19027 48.11692 20.47087 48.28242 20.75147 48.11692 40.67007 36.36692 40.94177 36.20662 40.94177 35.89122 40.94177 12.39122 40.94177 12.07582 40.67007 11.91552z"
            />
            <Path
              d="M15.469 7.734a3.854 3.854 0 01-2.677-1.078L8.656 9.218c.236.547.367 1.15.367 1.782 0 .633-.13 1.235-.367 1.782l4.136 2.562a3.854 3.854 0 012.677-1.078 3.872 3.872 0 013.867 3.867A3.872 3.872 0 0115.469 22a3.872 3.872 0 01-3.867-3.867c0-.63.15-1.225.419-1.75L7.98 13.88a4.504 4.504 0 01-3.47 1.632A4.517 4.517 0 010 11a4.517 4.517 0 014.512-4.512c1.393 0 2.641.636 3.47 1.632l4.039-2.503a3.844 3.844 0 01-.42-1.75A3.872 3.872 0 0115.47 0a3.872 3.872 0 013.867 3.867 3.872 3.872 0 01-3.867 3.867z"
              transform="translate(1.53 1.359) translate(10.47 12.641)"
              fill="#111"
            />
          </G>
        </Svg>
      </View>
    );
  },
);

export { ShareIcon };
