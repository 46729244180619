import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { MenuButton } from '../MenuButton';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import LeaderBoardIcon from '../../../resources/svgComponents/LeaderBoardIcon';
import { applyTransparency } from '../../../constants/colors';

const LeaderBoardButton = ({action, loading, round, size, noMargin, containerStyle = {}}:{
    action: () => void,
    loading: boolean,
    round?: boolean,
    size?: number,
    noMargin?: boolean,
    containerStyle?: StyleProp<ViewStyle>;
}) => {
    return (
        <MenuButton
            text={isMobile ? '' : 'Leaderboard'}
            style={[containerStyle, {marginLeft: noMargin ? 0 : isMobile ? 20 : 40,
                borderRadius: round ? 50 : undefined,
            }]}
            height={size ?? 50}
            width={size ? size : isMobile ? 50 : 199}
            onPress={action}
            colors={['#FFCF53', '#FF9900']}
            textStyle={styles.leaderboardButtonText}
            loading={loading}
            disabled={loading}
            leftElement={(isMobile && loading) ? undefined : <LeaderBoardIcon containerStyle={isMobile ? {} : {marginRight:10}}/>}

        />
    );
};

export { LeaderBoardButton };

const styles = StyleSheet.create({
    leaderboardButtonText: {
        fontFamily: 'Secular One',
        color: '#fff',
        fontSize: 20,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 4 },
        textShadowRadius: 2,
    },
});
