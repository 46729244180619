import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { MenuButton } from '../../helperComponents/MenuButton';
import { applyTransparency } from '../../../constants/colors';
import { BackButton } from '../../helperComponents/BackButton';
import { GAME_TYPES_TYPE, PaymentStatus } from '../../../constants/constants';
import { GameTypeCard } from '../../helperComponents/GameTypeCard';
import { useSignedInContext } from '../../../context/SignedInContext';
import { useGetHivesQuery } from '../MyHives/useGetHivesQuery.gql';
import { DropdownMenu } from '../../helperComponents/DropdownMenu/DropdownMenu';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { PlusIcon } from '../../../resources/svgComponents/PlusIcon';
import { XIcon } from '../../../resources/svgComponents/XIcon';
import { GoPremiumPopup } from '../../helperComponents/GoPremiumPopup';
import { PremiumPlansPopup } from '../../helperComponents/PremiumPlansPopup';

const SelectGameType = ({ navigation, route }: ScreenProps<'SelectGameType'>) => {
  const { isSignedIn } = useSignedInContext();
  const { data: getHivesData, loading } = useGetHivesQuery({ skip: !isSignedIn });
  const [isGoPremiumPopupVisible, setIsGoPremiumPopupVisible] = useState(false);
  const [isPremiumPlansPopupVisible, setPremiumPlansPopupVisible] = useState(false);

  const nextRoute: 'PlayOnline' | 'HostGame' | 'PlayWithAI' | 'WaitingForHiveMate' | 'PassAndPlay' = route.params.next;
  const [selectedType, setSelectedType] = useState<GAME_TYPES_TYPE>(GAME_TYPES_TYPE.MULTI_1_12);
  const handleNextPress = () => {
    if (nextRoute === 'WaitingForHiveMate') {
      if (route.params.inviteeId && route.params.inviteeName && route.params.userName && route.params.hiveDetail) {
        navigation.navigate(nextRoute,
          {
            gameType: selectedType,
            userName: route.params.userName,
            inviteeUserId: route.params.inviteeId,
            inviteeUsername: route.params.inviteeName,
            hiveDetail: { ...route.params.hiveDetail },
          });
      }
    } else if (nextRoute === 'PassAndPlay') {
      navigation.navigate('PassAndPlay', { gameType: selectedType });
    } else {
      navigation.navigate(nextRoute, { gameType: selectedType, userName: route.params.userName });
    }
  };

  const hasAccessToPremiumGames = useMemo(() => {
    const paidHives = getHivesData?.hives.filter(hive => hive.paymentStatus === PaymentStatus.Active);
    return (paidHives ?? []).length > 0;
  }, [getHivesData?.hives]);

  const onSelectType = useCallback((newSelectedType: GAME_TYPES_TYPE, options?: { isLocked?: boolean }) => {
    if (options?.isLocked && !hasAccessToPremiumGames) {
      setIsGoPremiumPopupVisible(true);
    } else {
      setSelectedType(newSelectedType);
    }
  }, [hasAccessToPremiumGames]);

  const onGoPremium = useCallback((type: 'Learn More' | 'Create Hive') => {
    if (type === 'Create Hive') {
      setIsGoPremiumPopupVisible(false);
      navigation.navigate('SelectHiveSize');
    } else {
      setPremiumPlansPopupVisible(true);
      setTimeout(() => {
        setIsGoPremiumPopupVisible(false);
      }, 500);
    }
  }, [navigation]);

  return (
    <ComponentWrapper hasInset hasScroll={true} style={{ paddingHorizontal: 0 }}>
      <Divider empty height={isMobile ? 170 : 240} />
      <Text style={styles.title}>Select Game</Text>
      <Divider empty height={40} />
      <View style={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'flex-start' }}>
        <View style={{ alignItems: 'center', justifyContent: 'center', marginHorizontal: 60 }}>
          <Text style={styles.typeText}>Addition</Text>
          <Divider empty height={12} />
          <GameTypeCard
            title={'1-12'}
            disabled={false}
            isActive={selectedType === GAME_TYPES_TYPE.ADD_1_12}
            onPress={() => onSelectType(GAME_TYPES_TYPE.ADD_1_12)}
            operandIcon={
              <PlusIcon color={hasAccessToPremiumGames ? 'black' : '#979797'} size={20} />
            }
          />
          <GameTypeCard
            title={'-6 to +6'}
            disabled={loading || (isMobile && !hasAccessToPremiumGames)}
            isLocked={!hasAccessToPremiumGames}
            isActive={selectedType === GAME_TYPES_TYPE.ADD_MINUS_6_6}
            onPress={() => onSelectType(GAME_TYPES_TYPE.ADD_MINUS_6_6, { isLocked: true })}
            operandIcon={
              <PlusIcon color={hasAccessToPremiumGames ? 'black' : '#979797'} size={20} />
            }
          />
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', marginHorizontal: 60 }}>
          <Text style={styles.typeText}>Multiplication</Text>
          <Divider empty height={12} />
          <GameTypeCard
            title={'1-10'}
            disabled={loading || (isMobile && !hasAccessToPremiumGames)}
            isLocked={!hasAccessToPremiumGames}
            isActive={selectedType === GAME_TYPES_TYPE.MULTI_1_10}
            onPress={() => onSelectType(GAME_TYPES_TYPE.MULTI_1_10, { isLocked: true })}
            operandIcon={
              <XIcon color={hasAccessToPremiumGames ? 'black' : '#979797'} width={20} height={20} />
            }
          />
          <GameTypeCard
            title={'1-12'}
            disabled={false}
            isActive={selectedType === GAME_TYPES_TYPE.MULTI_1_12}
            onPress={() => onSelectType(GAME_TYPES_TYPE.MULTI_1_12)}
            operandIcon={
              <XIcon color={'black'} width={20} height={20} />
            }
          />
          <GameTypeCard
            title={'-6 to +6'}
            disabled={loading || (isMobile && !hasAccessToPremiumGames)}
            isLocked={!hasAccessToPremiumGames}
            isActive={selectedType === GAME_TYPES_TYPE.MULTI_MINUS_6_6}
            onPress={() => onSelectType(GAME_TYPES_TYPE.MULTI_MINUS_6_6, { isLocked: true })}
            operandIcon={
              <XIcon color={hasAccessToPremiumGames ? 'black' : '#979797'} width={20} height={20} />
            }
          />
          <GameTypeCard
            title={'Algebra'}
            disabled={loading || (isMobile && !hasAccessToPremiumGames)}
            isLocked={!hasAccessToPremiumGames}
            isActive={selectedType === GAME_TYPES_TYPE.MULTI_ALGEBRA}
            onPress={() => onSelectType(GAME_TYPES_TYPE.MULTI_ALGEBRA, { isLocked: true })}
            operandIcon={
              <XIcon color={hasAccessToPremiumGames ? 'black' : '#979797'} width={20} height={20} />
            }
          />
        </View>
      </View>
      <Divider empty height={48} />
      <MenuButton
        onPress={handleNextPress}
        colors={['#90DF75', '#62B655']}
        text="Next"
        height={64}
        width={290}
      />
      <DropdownMenu
        backButton={
          <BackButton
            text="Back"
            onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })}
          />
        }
      />
      <Divider empty height={isMobile ? 170 : 240} />
      <GoPremiumPopup
        visible={isGoPremiumPopupVisible}
        setVisible={setIsGoPremiumPopupVisible}
        onButtonClicked={onGoPremium}
      />
      <PremiumPlansPopup
        visible={isPremiumPlansPopupVisible}
        setVisible={setPremiumPlansPopupVisible}
      />
    </ComponentWrapper>
  );
};

export { SelectGameType };

const styles = StyleSheet.create({
  hivesContainer: {
    minHeight: 300,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 180,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -20,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  typeText: {
    fontFamily: 'Secular One',
    fontSize: 26,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    color: '#121212',
  },
});
