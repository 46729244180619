import React from 'react';
import {
  ActivityIndicator,
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency, COLORS } from '../../constants/colors';

interface IMenuButtonProps {
  text: string;
  onPress: () => void;
  colors: [start: string, end: string];
  width: number;
  height: number;
  image?: ImageSourcePropType;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  icon?: any;
  disabled?: boolean;
  loading?: boolean;
  leftElement?: any;
  isHorizontalGradient?: boolean;
}
const MenuButton = (props: IMenuButtonProps) => {
  return (
    <TouchableOpacity disabled={props.disabled} onPress={props.onPress}>
      <LinearGradient
        useAngle={props.isHorizontalGradient ? true : false}
        angle={90}
        colors={props.disabled ? [COLORS.gray, COLORS.gray] : props.colors}
        style={[
          {
            height: props.height,
            width: props.width,
          },
          styles.container,
          props.image ? null : styles.containerWIthoutImage,
          props.style,
          { opacity: props.disabled ? 0.5 : 1 },
        ]}
      >
        {props.loading ? (
          <View style={[styles.spinnerContainer, { paddingRight: (!props.icon && !props.leftElement && !props.image && !props.text) ? 0 : 8 }]}>
            <ActivityIndicator size="small" color="#FFFFFF" />
          </View>
        ) : undefined}
        {props.icon}
        {props.leftElement ? props.leftElement : null}
        {props.image ? <Image source={props.image} style={{ width: 54, height: 54 }} /> : null}
        <Text
          maxFontSizeMultiplier={1}
          style={[styles.text, props.textStyle, props.image ? null : styles.textWIthoutImage]}
        >
          {props.text}
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};

export { MenuButton };

const styles = StyleSheet.create({
  container: {
    borderWidth: 4,
    borderColor: applyTransparency('#FFFFFF', 0.4),
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 12,
    alignItems: 'center',
    borderRadius: 10,
  },
  containerWIthoutImage: {
    justifyContent: 'center',
    paddingLeft: 0,
  },
  text: {
    fontFamily: 'Secular One',
    marginLeft: 16,
    fontSize: 24,
    color: 'white',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  textWIthoutImage: {
    textAlign: 'center',
    marginLeft: 0,
  },
  spinnerContainer: {
    paddingRight: 8,
  },
});
