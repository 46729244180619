import { GAME_TYPES_TYPE, OPERAND_TYPE } from '../../../constants/constants';

export function performOperation({
  gameType,
  leftOperand,
  rightOperand,
}: {
  gameType: GAME_TYPES_TYPE;
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
}): OPERAND_TYPE {
  switch (gameType) {
    case GAME_TYPES_TYPE.MULTI_1_12:
    case GAME_TYPES_TYPE.MULTI_MINUS_6_6:
    case GAME_TYPES_TYPE.MULTI_1_10:
      return { a: leftOperand.a * rightOperand.a, x: 0, b: 0 };
    case GAME_TYPES_TYPE.MULTI_ALGEBRA:
      return { a: leftOperand.a * rightOperand.a, x: leftOperand.x + rightOperand.x, b: 0 };
    case GAME_TYPES_TYPE.ADD_1_12:
    case GAME_TYPES_TYPE.ADD_MINUS_6_6:
      return { a: leftOperand.a + rightOperand.a, x: 0, b: 0 };
  }
}

export const renderValue = (value: OPERAND_TYPE) => {
  if (value.b === 0 && value.x === 0) {
    return value.a;
  } else {
    if (value.x === 1) {
      if (value.b === 0) {
        if (value.a === 1) {
          return '𝑥';
        }
        return `${value.a}𝑥`;
      } else {
        return `${value.a}𝑥 + ${value.b}`;
      }
    }
    if (value.a === 1) {
      return '𝑥²';
    }
    return `${value.a}𝑥²`;
  }
};
