import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { BackButton } from '../../helperComponents/BackButton';
import { HiveSize, HiveSubscriptionType } from '../../../constants/constants';
import { MB_ToggleSwitch } from '@mightybyte/rnw.components.toggle-switch';
import LinearGradient from 'react-native-linear-gradient';
import { PremiumPlans } from '../../helperComponents/PremiumPlans';

const SelectHiveSize = ({ navigation }: ScreenProps<'SelectHiveSize'>) => {
  const [subscriptionType, setSubscriptionType] = useState<HiveSubscriptionType>(HiveSubscriptionType.month);

  const onSelectHive = (selectedType: HiveSize) => {
    navigation.navigate('CreateHive', { size: selectedType, subscriptionType });
  };

  const onSwitchSubscription = useCallback(() => {
    setSubscriptionType(currentSubscriptionType => currentSubscriptionType === HiveSubscriptionType.month ? HiveSubscriptionType.year : HiveSubscriptionType.month);
  }, []);

  return (
    <ComponentWrapper contentContainerStyle={styles.contentContainer} containerStyle={styles.container} hasInset hasScroll={true}>
      <View style={styles.topContainer}>
        <BackButton onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }, { name: 'MyHives' }] })} text="Back" />
      </View>
      <Divider empty height={24} />
      <Text style={styles.title}>Select Hive Size</Text>
      <Text style={styles.subTitle}>Each user in your hive will gain access to all the game boards.</Text>
      <Text style={[styles.subTitle, styles.note]}>You will gain access to a hive dashboard with analytics, progress and a leaderboard. </Text>
      <Divider empty height={14} />
      <View style={styles.subscriptionType}>
        <Text style={styles.subscriptionTypeText}>Monthly</Text>
        <LinearGradient colors={['#FFCF53', '#FF9900']} style={styles.subscriptionTypeSwitch}>
          <MB_ToggleSwitch
            isToggled={subscriptionType === HiveSubscriptionType.year}
            onToggle={onSwitchSubscription}
            trackBar={{
              activeBackgroundColor: 'transparent',
              inActiveBackgroundColor: 'transparent',
              width: 50,
              radius: 30,
              height: 30,
              borderWidth: 2,
              borderActiveColor: 'transparent',
              borderInActiveColor: 'transparent',
            }}
            thumbButton={{
              activeBackgroundColor: '#FFF',
              inActiveBackgroundColor: '#FFF',
              height: 30,
              width: 30,
            }}
          // trackBarStyle={styles.subscriptionTypeSwitch}
          />
        </LinearGradient>
        <Text style={styles.subscriptionTypeText}>Yearly</Text>
      </View>
      <Divider empty height={30} />
      <PremiumPlans
        subscriptionType={subscriptionType}
        onSelectHive={onSelectHive}
      />
    </ComponentWrapper>
  );
};

export { SelectHiveSize };

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    minWidth: 1000,
  },
  hivesContainer: {
    minHeight: 300,
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 180,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -20,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  subTitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#121212',
    fontWeight: '400',
  },
  note: {
    fontSize: 15,
    color: '#E89823',
    marginTop: 10,
  },
  subscriptionType: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subscriptionTypeSwitch: {
    marginHorizontal: 20,
    borderRadius: 30,
  },
  subscriptionTypeText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
});
