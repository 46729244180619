import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, ClipPath, Defs, LinearGradient, Stop } from 'react-native-svg';

const ThirdPlaceIcon = React.memo(({size, containerStyle}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 28}
                height={size ? size * 32 / 28 : 32}
                viewBox="0 0 28 32"
                fill="none"
            >
                <G clipPath="url(#clip0_2243_5833)">
                    <Path
                        d="M28 19.93l-1.914-1.262v1.44l1.914.534v-.712zM0 19.93l1.914-1.262v1.44L0 20.643v-.712z"
                        fill="#A50E0E"
                    />
                    <Path
                        d="M1.914 7.751v15.503L14 31.006l12.086-7.752V7.751L14 0 1.914 7.751z"
                        fill="#ECB20C"
                    />
                    <Path
                        d="M9.612 12.827L14 3.343V0L1.914 7.751l7.698 5.076z"
                        fill="url(#paint0_linear_2243_5833)"
                    />
                    <Path
                        d="M19.718 18.668L14 27.661v3.343l12.086-7.751-2.606-1.672-3.762-2.913z"
                        fill="url(#paint1_linear_2243_5833)"
                    />
                    <Path
                        d="M13.791 23.692l-9.27-2.11-2.607 1.672L14 31.005v-3.343l-.209-3.97z"
                        fill="url(#paint2_linear_2243_5833)"
                    />
                    <Path
                        d="M14.207 7.455l9.273 1.968 2.606-1.672L14 0v3.343l.207 4.112z"
                        fill="url(#paint3_linear_2243_5833)"
                    />
                    <Path
                        d="M10.03 18.258L4.912 9.695 1.914 7.75v15.503L4.52 21.58l5.51-3.323z"
                        fill="url(#paint4_linear_2243_5833)"
                    />
                    <Path
                        d="M17.969 18.258l5.118-8.563 2.998-1.945v15.503l-2.607-1.672-5.51-3.323z"
                        fill="url(#paint5_linear_2243_5833)"
                    />
                    <Path
                        d="M4.523 9.424v12.16l9.48 6.08 9.48-6.08V9.423l-9.48-6.08-9.48 6.08z"
                        fill="url(#paint6_linear_2243_5833)"
                    />
                    <Path
                        d="M9.067 24.11H1.204C.539 24.11 0 23.571 0 22.907v-3.063h7.765c.719 0 1.302.582 1.302 1.301v2.965z"
                        fill="url(#paint7_linear_2243_5833)"
                    />
                    <Path
                        d="M26.793 24.376H18.93v-2.965c0-.719.582-1.302 1.301-1.302h7.765v3.063c0 .665-.539 1.204-1.203 1.204z"
                        fill="url(#paint8_linear_2243_5833)"
                    />
                    <Path
                        d="M21.346 26.511H6.654A.654.654 0 016 25.857v-4.558c0-.362.293-.654.654-.654h14.692c.361 0 .654.293.654.654v4.558a.654.654 0 01-.654.654z"
                        fill="url(#paint9_linear_2243_5833)"
                    />
                    <Path
                        d="M13.993 27.842a4.267 4.267 0 100-8.533 4.267 4.267 0 000 8.533z"
                        fill="#F6F6F8"
                    />
                    <Path
                        d="M14.006 27.038a3.459 3.459 0 100-6.917 3.459 3.459 0 000 6.918z"
                        fill="url(#paint10_linear_2243_5833)"
                    />
                    <Path
                        d="M13.998 20.996l.839 1.7 1.877.274-1.358 1.324.32 1.869-1.678-.883-1.68.883.322-1.87-1.359-1.323 1.877-.273.84-1.7z"
                        fill="#FFB05A"
                    />
                    <Path
                        d="M13.998 20.996l.109 2.529.73-.828.939.136.938.137-2.607.555 1.249.769.16.934.16.935-1.57-2.638-.108 1.755-.84.442-.84.44 1.789-2.637-1.467.769-.68-.662-.679-.662 2.826.555-.949-.828.42-.85.42-.85z"
                        fill="#D17A21"
                    />
                    <Path
                        d="M7.133 9.461l.228-.915a.668.668 0 01.486-.485l.915-.23c.025-.005.025-.04 0-.046l-.915-.23a.668.668 0 01-.486-.485l-.228-.915c-.007-.024-.042-.024-.048 0l-.228.915a.668.668 0 01-.486.486l-.915.229c-.025.006-.025.04 0 .047l.915.229a.668.668 0 01.486.485l.228.915c.007.025.042.025.048 0zM23.42 19.494l.171-.686a.5.5 0 01.365-.365l.686-.171c.018-.005.018-.031 0-.036l-.686-.172a.501.501 0 01-.365-.364l-.171-.686c-.005-.019-.031-.019-.036 0l-.171.686a.5.5 0 01-.365.364l-.686.172c-.018.005-.018.03 0 .036l.686.171a.5.5 0 01.365.365l.171.686c.005.018.031.018.036 0z"
                        fill="#fff"
                    />
                    <Path
                        d="M11.094 16.39l.9-1.23c.494.472 1.065.79 1.702.79.758 0 1.252-.318 1.252-.932 0-.703-.373-1.12-2.087-1.12v-1.373c1.395 0 1.834-.428 1.834-1.077 0-.56-.329-.867-.933-.867-.549 0-.967.252-1.46.68l-.989-1.196c.758-.637 1.571-1.01 2.537-1.01 1.691 0 2.8.79 2.8 2.25 0 .813-.439 1.45-1.318 1.802v.055c.912.274 1.582.933 1.582 1.987 0 1.527-1.395 2.383-3.02 2.383-1.296 0-2.207-.45-2.8-1.142z"
                        fill="#D56E1F"
                    />
                </G>
                <Defs>
                    <LinearGradient
                        id="paint0_linear_2243_5833"
                        x1={7.9443}
                        y1={3.86001}
                        x2={9.67861}
                        y2={6.00171}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#EFEDB3" />
                        <Stop offset={1} stopColor="#F8AB30" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint1_linear_2243_5833"
                        x1={19.8527}
                        y1={26.8667}
                        x2={18.5514}
                        y2={24.8684}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#F8AB30" />
                        <Stop offset={1} stopColor="#EFEDB3" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint2_linear_2243_5833"
                        x1={7.72095}
                        y1={27.4347}
                        x2={10.6475}
                        y2={23.6516}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#E1783A" />
                        <Stop offset={1} stopColor="#EB9130" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint3_linear_2243_5833"
                        x1={20.1612}
                        y1={3.75388}
                        x2={17.9932}
                        y2={6.06834}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#E1783A" />
                        <Stop offset={1} stopColor="#EB9130" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint4_linear_2243_5833"
                        x1={1.914}
                        y1={15.5015}
                        x2={4.9456}
                        y2={15.5015}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#E46F26" />
                        <Stop offset={1} stopColor="#FFA835" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint5_linear_2243_5833"
                        x1={26.0847}
                        y1={15.5015}
                        x2={23.099}
                        y2={15.5015}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#E46F26" />
                        <Stop offset={1} stopColor="#FFA835" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint6_linear_2243_5833"
                        x1={14.0031}
                        y1={27.6631}
                        x2={14.0031}
                        y2={3.3438}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#ED9448" />
                        <Stop offset={1} stopColor="#F5CC75" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint7_linear_2243_5833"
                        x1={0}
                        y1={21.9772}
                        x2={9.06667}
                        y2={21.9772}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#BE4244" />
                        <Stop offset={1} stopColor="#D33730" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint8_linear_2243_5833"
                        x1={18.9298}
                        y1={22.2428}
                        x2={27.9965}
                        y2={22.2428}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#BE4244" />
                        <Stop offset={1} stopColor="#D33730" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint9_linear_2243_5833"
                        x1={13.9999}
                        y1={26.5112}
                        x2={13.9999}
                        y2={20.6445}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FF544A" />
                        <Stop offset={1} stopColor="#F7726E" />
                    </LinearGradient>
                    <LinearGradient
                        id="paint10_linear_2243_5833"
                        x1={14.0055}
                        y1={27.0385}
                        x2={14.0055}
                        y2={20.1211}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#ED9448" />
                        <Stop offset={1} stopColor="#F5CC75" />
                    </LinearGradient>
                    <ClipPath id="clip0_2243_5833">
                        <Path
                            fill="#fff"
                            transform="translate(0 -.004)"
                            d="M0 0H28V31.0059H0z"
                        />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export default ThirdPlaceIcon;
