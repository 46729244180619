import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { useCreateHiveMutation } from './useCreateHiveMutation.gql';
import { ApolloError } from '@apollo/client';
import { useSubscribeToStripe } from '../../../hooks/payment';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../utils/utils';
import { envs } from '../../../../env';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, MB_EventObject } from '@mightybyte/rnw.utils.event-emmiter';
import { WEB_POST_MESSAGE_OBJECT_TYPES } from '../ServerRedirect/serverRedirectUtils';
import { getErrorText } from '../../../utils/errors';
import { HIVES } from '../../helperComponents/PremiumPlans';
import { HiveSubscriptionType } from '../../../constants/constants';
import { HivePurchaseCard } from './HivePurchaseCard';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';

const CreateHive = ({ navigation, route }: ScreenProps<'CreateHive'>) => {
  const { size, subscriptionType } = route.params;
  const [name, setName] = useState('');
  const [inputError, setInputError] = useState<boolean>(false);
  const subscribe = useSubscribeToStripe();

  const [createHive, { error, loading }] = useCreateHiveMutation({
    variables: { name, size, subscriptionType },
  });

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handlePay = async (id: string) => {
    if (!isMobileApp) {
      let token = await MB_accessTokenUtils.getAccessToken();
      token = token?.replace('Bearer ', '');
      const url = encodeURI(envs.API_URL + `/api/subscription/stripe/checkout?hiveId=${id}&accessToken=${token}`);
      utils.openExternalWindow(url, 'Checkout');
    } else {
      setHasError({ popup: true, visible: true, text: 'You have to try web version for purchases' });
    }
  };

  const stripeRedirectCallback = useCallback((event: MB_EventObject) => {
    try {
      if (event.origin !== envs.WEBSITE_BASE_URL || (event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.STRIPE_PAYMENT && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR)) {
        return;
      }
      if (event.data.success && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR) {
        navigation.navigate('MyHives', { shouldRefetch: true });
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
      setHasError({ popup: true, visible: true, text: 'Something went wrong' });
    }
  }, [navigation]);


  const handleCreateHiveError = async (err: ApolloError) => {
    const errorMessage = getErrorText(err);
    if (utils.isIosWeb()) {
      const window: any = MB_customWindowForWeb.open('', 'Checkout');
      window?.document.write(`<html><head><title>Checkout</title></head><body>${errorMessage}, redirecting back ...</body></html>`);
      window?.document.close();
      await new Promise((resolve) => setTimeout(resolve, 2000));
      window?.close();
    }
    setInputError(true);
    setHasError({ popup: true, visible: true, text: errorMessage });
  };

  const handleCreatePress = async () => {
    if (name.length > 0) {
      setInputError(false);
      if (!isMobileApp && utils.isIosWeb()) {
        const window: any = MB_customWindowForWeb.open('', 'Checkout');
        window?.document.write('<html><head><title>Checkout</title></head><body>Please wait while we redirect you...</body></html>');
        window?.document.close();
      }
      createHive().then((hiveData) => {
        if (hiveData && hiveData.data) {
          handlePay(hiveData.data?.createHive.id);
        }
      }).catch((err) => handleCreateHiveError(err));
    } else {
      setInputError(true);
      setHasError({ popup: true, visible: true, text: 'Please enter the name' });
    }
  };

  useEffect(() => {
    if (error) {
      handleCreateHiveError(error);
    }
  }, [error]);

  useEffect(() => {
    const eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, stripeRedirectCallback);

    return () => {
      eventSub.remove();
    };
  }, [stripeRedirectCallback]);

  const handleGoBackPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.reset({ index: 0, routes: [{ name: 'Lobby' }, { name: 'MyHives' }, { name: 'SelectHiveSize', params: { size: size } }] });
    }
  };
  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        hideBee
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={handleGoBackPress} text="Back" />
        </View>
        <View style={styles.wholeContainer}>
          <HivePurchaseCard
            style={styles.purchaseCard}
            headerStyle={styles.purchaseCardHeader}
            titleTextStyle={styles.purchaseCardTitleText}
            descriptionTextStyle={styles.purchaseCardDescriptionText}
            priceTextStyle={styles.purchaseCardPriceText}
            priceSuffixTextStyle={styles.purchaseCardPriceSuffixText}
            featureText={styles.purchaseCardFeatureText}
            checkMarkSize={16}
            colors={HIVES[size].colors}
            name={HIVES[size].name}
            description={HIVES[size].description}
            prices={HIVES[size].prices}
            subscriptionType={subscriptionType}
            features={HIVES[size].features}
            hideBuyButton
          />
          <View style={styles.hiveNameContainer}>
            <Text maxFontSizeMultiplier={1} style={[styles.title, inputError ? { color: '#EB5038' } : null]}>
              Enter Your Hive’s Name
            </Text>
            <Divider empty height={12} />
            <Input
              value={name}
              setValue={(value) => setName(value)}
              style={{ width: 288 }}
              textStyle={{ fontSize: 18 }}
              placeholder="Enter name"
              hasError={inputError}
              onSubmitEditing={handleCreatePress}
            />
          </View>
          <View style={styles.orderSummaryContainer}>
            <Text maxFontSizeMultiplier={1} style={styles.orderSummary}>
              Order Summary
            </Text>
            <Divider empty height={32} />
            <View style={{ borderTopColor: '#C4C4C4', borderTopWidth: 1, width: '100%' }} />
            <Divider empty height={20} />
            <Text style={styles.subscrriptionText}>{subscriptionType === HiveSubscriptionType.month ? 'Monthly' : 'Yearly'} Subscription</Text>
            <Divider empty height={20} />
            <View style={{ borderTopColor: '#C4C4C4', borderTopWidth: 1, width: '100%' }} />
            <Divider empty height={20} />
            <View style={styles.totalContainer}>
              <Text style={styles.total}>Total</Text>
              <Text style={styles.total}>${HIVES[size].prices[subscriptionType]}</Text>
            </View>
            <Divider empty height={20} />
            <View style={{ borderTopColor: '#C4C4C4', borderTopWidth: 1, width: '100%' }} />
            <Divider empty height={32} />
            <MenuButton
              textStyle={{ fontSize: 18 }}
              onPress={handleCreatePress}
              colors={['#90DF75', '#62B655']}
              text="Proceed to payment"
              height={64}
              width={320}
              loading={loading || subscribe.isLoading}
              disabled={loading || subscribe.isLoading}
            />
          </View>
        </View>
        <Divider empty height={40} />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { CreateHive };

const styles = StyleSheet.create({
  wholeContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' },
  purchaseCard: {
    width: 325,
    height: 472,
    marginRight: 80,
  },
  purchaseCardHeader: {
    height: 184,
  },
  purchaseCardTitleText: {
    fontSize: 22,
  },
  purchaseCardDescriptionText: {
    fontSize: 13,
  },
  purchaseCardPriceText: {
    fontSize: 20,
  },
  purchaseCardPriceSuffixText: {
    fontSize: 12,
  },
  purchaseCardFeatureText: {
    fontSize: 12,
  },
  hiveNameContainer: {
    position: 'absolute',
    bottom: 22,
    left: 17,
  },
  orderSummaryContainer: {
    width: 420,
    backgroundColor: '#F7F7F7',
    paddingHorizontal: 24,
    paddingVertical: 48,
    marginLeft: 80,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
  },
  totalContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 15,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    width: '100%',
    maxWidth: 420,
  },
  orderSummary: {
    fontFamily: 'Secular One',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    width: '100%',
    maxWidth: 420,
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'Secular One',
    fontSize: 15,
    color: '#453B3B',
    textAlign: 'center',
  },
  subscrriptionText: {
    fontFamily: 'Secular One',
    fontSize: 24,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    color: '#121212',
  },
  total: {
    fontFamily: 'Secular One',
    fontSize: 17,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    color: '#121212',
  },
});
