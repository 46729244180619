import React, { useMemo } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { applyTransparency, COLORS } from '../../constants/colors';
import { Divider } from './Divider';
import { MenuButton } from './MenuButton';
import { Popup } from './Popup';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import RibbonMobile from '../../resources/svgComponents/RibbonMobile';
import ConfettiCannon from '../../resources/svgComponents/ConfettiCannon';
import { YourPoints } from './YourPoints';
import LeaderBoardHeaderIcon from '../../resources/svgComponents/LearBoardHeaderIcon';
import { utils } from '../../utils/utils';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onPressAction: () => void;
  isWinner: boolean;
  isDraw: boolean;
  anotherPlayerLeft?: boolean;
  handlePlayAgainPress?: () => void;
  isError?: boolean;
  isPassAndPlay: boolean;
  winnerName?: string;
  points?: number;
}
const ResultPopup = ({
  visible,
  setVisible,
  onPressAction,
  isWinner,
  isDraw,
  anotherPlayerLeft,
  handlePlayAgainPress,
  isError,
  isPassAndPlay,
  winnerName,
  points,
}: IPopupProps) => {
  const screenSize = useScreenSize();
  const isSmall = screenSize === SCREEN_SIZES.SMALL;

  const calcHeight = () => {
    if (isSmall) {
      let height = 236;
      if (points !== undefined) {
        height += 20;
      }
      if (anotherPlayerLeft) {
        height += 20;
      }
      return height;
    } else {
      let height = 256;
      if (isWinner) {
        height += 30;
      }
      if (points !== undefined) {
        height += 20;
      }
      if (anotherPlayerLeft) {
        height += 20;
      }
      return height;
    }
  };
  const calcMargin = () => {
    if (isSmall) {
      if (Platform.OS === 'android') {
        return 320;
      }
      return 360;
    } else {
      if (Platform.OS === 'android') {
        return 400;
      }
      return undefined;
    }
  };

  const ribbonText = useMemo(() => {
    if (isWinner) {
      return `${isPassAndPlay ? winnerName : 'You'} Won!`;
    }
    if (isDraw) {
      return 'It’s a Draw!';
    }
    return isError ? 'Game Already Finished' : 'Game Over';
  }, [isWinner, isDraw, isError, isPassAndPlay, winnerName]);

  const isLost = useMemo(() => {
    return !isWinner && !isDraw;
  }, [isWinner, isDraw]);

  return (
    <Popup
      height={calcHeight()}
      title=""
      hideCloseButton={true}
      headerComponent={<LeaderBoardHeaderIcon containerStyle={styles.titleIconContainer}
        noColor={isLost} />}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={false}
      containerStyle={{
        marginTop: calcMargin(),
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View style={styles.container}>
        <View style={{
          position: 'relative',
          // width: '100%', //should match popup max width
          zIndex: 120,
          height: 86,
          ...utils.getShadow({
            color: '#161d24',
            radius: 20,
            opacity: 0.08,
            offsetWidth: 0,
            offsetHeight: 24,
            elevation: 0,
          }),
          alignSelf: 'center',
          ...mbPlatformStyle({
            mobile: {
              width: '106.5%',
            },
            web: {
              width: '119%',
            },
          }),
        }}>
          {isLost ? null : <ConfettiCannon containerStyle={{ ...styles.confetti, left: 0 }} />}
          {isLost ? null : <ConfettiCannon containerStyle={{ ...styles.confetti, right: 0 }} />}
          <RibbonMobile
            width="100.5%"
            height={(isMobile || isMobileApp) ? '104%' : '90%'}
            containerStyle={{ top: 20, left: 0 }}
            fillColor={isLost ? '2287_7711' : undefined}
            stopColor1={isLost ? '#DF3A3A' : undefined}
            stopColor2={isLost ? '#C91717' : undefined}
          />
          <Text maxFontSizeMultiplier={1} style={[styles.ribbonText, isLost ? { color: COLORS.white } : {}]}>
            {ribbonText}
          </Text>
        </View>


        <View>
          {anotherPlayerLeft ? (
            <>
              <Divider empty height={12} />
              <Text maxFontSizeMultiplier={1} style={styles.message}>
                Your opponent left the game
              </Text>
              {isSmall ? <Divider empty height={12} /> : <Divider empty height={12} />}
            </>

          ) : isSmall ? <Divider empty height={18} /> : <Divider empty height={30} />}
          {(points || points === 0) ? <YourPoints points={points} text={'Points earned'} /> : null}
          {isSmall ? <Divider empty height={18} /> : <Divider empty height={30} />}
        </View>
        <View style={{
          flexDirection: 'row',
          justifyContent: handlePlayAgainPress ? 'space-between' : 'center',
          width: handlePlayAgainPress ? '100%' : undefined,
          paddingHorizontal: 26,
        }}>
          <MenuButton
            height={isSmall ? 42 : 62}
            width={150}
            onPress={onPressAction}
            text="Go to Lobby"
            colors={['#90DF75', '#62B655']}
            textStyle={styles.buttonTextStyle}
          />
          {handlePlayAgainPress !== undefined ? (
            <>
              <Divider empty height={12} />
              <MenuButton
                height={isSmall ? 42 : 62}
                width={150}
                onPress={handlePlayAgainPress}
                text="Play Again"
                colors={['#747474', '#1F1F1F']}
                textStyle={styles.buttonTextStyle}
              />
            </>
          ) : null}
        </View>
      </View>

    </Popup>
  );
};

export { ResultPopup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  confetti: {
    position: 'absolute',
    top: -55,
  },

  ribbonText: {
    position: 'absolute',
    ...mbPlatformStyle({
      mobile: {
        top: 40,
      },
      web: {
        top: 45,
      },
    }),
    width: '100%',
    textAlign: 'center',
    zIndex: 120,
    color: COLORS.black,
    fontSize: 26,
    fontWeight: '400',
    fontFamily: 'Secular One',
    textTransform: 'uppercase',
  },

  titleIconContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transform: [{ scale: 0.8 }],
    top: -70,
  },

  message: {
    fontFamily: 'Secular One',
    color: '#111',
    fontSize: 16,
    fontWeight: '400',
    paddingBottom: 10,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
