import React, { useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { useSubscribeToStripe } from '../../../hooks/payment';
import { isMobile } from '@mightybyte/rnw.utils.device-info';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { utils } from '../../../utils/utils';
import { envs } from '../../../../env';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, MB_EventObject } from '@mightybyte/rnw.utils.event-emmiter';
import { WEB_POST_MESSAGE_OBJECT_TYPES } from '../ServerRedirect/serverRedirectUtils';
import { HIVES } from '../../helperComponents/PremiumPlans';
import { HiveSubscriptionType } from '../../../constants/constants';

const summaryIcon = require('../../../resources/images/summary.png');

const OrderSummary = ({ navigation, route }: ScreenProps<'OrderSummary'>) => {
  const { id, size } = route.params;
  const subscribe = useSubscribeToStripe();

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handlePay = async () => {
    if (!isMobile) {
      let token = await MB_accessTokenUtils.getAccessToken();
      token = token?.replace('Bearer ', '');
      utils.openExternalWindow(envs.API_URL + `/api/subscription/stripe/checkout?hiveId=${id}&accessToken=${token}`, 'Checkout');
    } else {
      setHasError({ popup: true, visible: true, text: 'You have to try web version for purchases' });
    }
  };

  const stripeRedirectCallback = useCallback((event: MB_EventObject) => {
    try {
      if (event.origin !== envs.WEBSITE_BASE_URL || (event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.STRIPE_PAYMENT && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR)) {
        return;
      }
      if (event.data.success && event.data.type !== WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR) {
        navigation.navigate('MyHives', { shouldRefetch: true });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      setHasError({ popup: true, visible: true, text: 'Something went wrong' });
    }
  }, [navigation]);

  useEffect(() => {
    const eventSub = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, stripeRedirectCallback);

    return () => {
      eventSub.remove();
    };
  }, [stripeRedirectCallback]);

  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.goBack()} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Order Summary
        </Text>
        <Divider empty height={40} />
        <View style={styles.nameContainer}>
          <Image source={summaryIcon} style={{ width: 55, height: 55 }} />
          <View style={{ flex: 1 }}>
            <Text style={styles.name}>{HIVES[size].name}</Text>
            <Text style={styles.size}>
              {
                HIVES[size].capacity === 1 ? null : `(up to ${HIVES[size].capacity} users)`
              }
            </Text>
          </View>
          <Text style={styles.price}>${HIVES[size].prices[HiveSubscriptionType.month]}/month</Text>
        </View>
        <Divider empty height={12} />
        <View style={styles.totalContainer} >
          <Text style={styles.total}>Total</Text>
          <Text style={styles.total}>${HIVES[size].prices[HiveSubscriptionType.month]}</Text>
        </View>
        <Divider empty height={60} />
        <MenuButton
          onPress={handlePay}
          colors={['#90DF75', '#62B655']}
          text="Proceed to payment"
          height={64}
          width={320}
          loading={subscribe.isLoading}
        />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { OrderSummary };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  nameContainer: {
    width: '100%',
    maxWidth: 400,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 17,
  },
  size: {
    fontFamily: 'Secular One',
    fontSize: 13,
    color: '#444444',
  },
  price: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
  },
  totalContainer: {
    width: '100%',
    maxWidth: 400,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: '#C4C4C4',
    borderBottomWidth: 1,
    borderBottomColor: '#C4C4C4',
    padding: 12,
    paddingTop: 16,
    paddingBottom: 20,
  },
  total: {
    fontFamily: 'Secular One',
    fontSize: 17,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    color: '#121212',
  },
});
