import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

const PlusIcon = React.memo(
  (props: {
    size: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 27}
          height={props.size ?? 27}
          viewBox="0 0 27 27"
          fill="none"
          {...props}
        >
          <G
            clipPath="url(#clip0_682_49674)"
            stroke={props.color ?? '#333'}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <Path d="M.834 13.99h24.375M13.021 1.803v24.375" />
          </G>
          <Defs>
            <ClipPath id="clip0_682_49674">
              <Path fill="#fff" transform="translate(.021 .99)" d="M0 0H26V26H0z" />
            </ClipPath>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { PlusIcon };
