import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const EditIcon = React.memo(
  (props: {
    size?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.size ?? 24}
          height={props.size ?? 24}
          viewBox="0 0 24 24"
          fill="none"
          {...props}
        >
          <Path
            d="M11.492 2.79H7.753c-3.075 0-5.003 2.176-5.003 5.258v8.314c0 3.082 1.919 5.26 5.003 5.26h8.824c3.085 0 5.004-2.178 5.004-5.26v-4.028"
            stroke="#130F26"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            clipRule="evenodd"
            d="M8.827 10.92L16.3 3.448c.93-.93 2.44-.93 3.37 0l1.218 1.218a2.383 2.383 0 010 3.37l-7.51 7.51a2.17 2.17 0 01-1.534.635H8.098l.094-3.78a2.17 2.17 0 01.635-1.48z"
            stroke="#130F26"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Path
            d="M15.164 4.602l4.566 4.566"
            stroke="#130F26"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>

      </View>
    );
  },
);

export { EditIcon };
