
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ChevronIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    colors?: [string, string];
    transparent?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 14}
          height={props.height ?? 14}
          viewBox="0 0 14 14"
          fill="none"
          {...props}
        >
          <Path
            d="M11.494 7L6.54 1.91a.438.438 0 11.627-.61l5.25 5.395a.437.437 0 010 .61l-5.25 5.396a.438.438 0 11-.627-.61L11.494 7z"
            fill="#000"
          />
        </Svg>
      </View>
    );
  },
);

export { ChevronIcon };
