import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Popup } from './Popup';
import { MenuButton } from './MenuButton';
import { Divider } from './Divider';
import { applyTransparency } from '../../constants/colors';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onPayClick: () => void;
}
const PaymentPopup = ({ visible, setVisible, onPayClick }: IPopupProps) => {
  return (
    <Popup
      height={200}
      title="Pay to Access Hive"
      headerTextStyle={{ fontSize: 19 }}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={20} />
        <Text style={styles.title}>In order to access this hive, please click the button below to make your payment</Text>
        <Divider empty height={24} />
        <MenuButton
          height={62}
          width={320}
          onPress={() => onPayClick()}
          text="Pay"
          colors={['#90DF75', '#62B655']}
          textStyle={styles.buttonTextStyle}
        />
        <Divider empty height={12} />
      </View>
    </Popup>
  );
};

export { PaymentPopup };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
    textAlign: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  label: {
    fontFamily: 'Secular One',
    marginBottom: 8,
    fontSize: 15,
  },
});
