import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation leaveWaitingOnlineGame {
    leaveWaitingOnlineGame
  }
`;

export interface MutationType {
  leaveWaitingOnlineGame: boolean;
}

export function useLeaveWaitingOnlineGameMutation(
  options?: MutationHookOptions<MutationType, void>,
) {
  return useMutation<MutationType, void>(mutation, options);
}
