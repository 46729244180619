import React, { useCallback, useEffect } from 'react';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { PremiumPlans } from '../../helperComponents/PremiumPlans';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import { useSignedInContext } from '../../../context/SignedInContext';
import { BackButton } from '../../helperComponents/BackButton';
import { StyleSheet, Text, View } from 'react-native';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';


const Subscriptions = ({ navigation }: ScreenProps<'Subscriptions'>) => {

    const { isSignedIn } = useSignedInContext();

    useEffect(() => {
        if (isSignedIn) {
            navigation.replace('SelectHiveSize');
        }
    }, [isSignedIn, navigation]);

    const onSelectHive = useCallback(() => {
        navigation.navigate('ChooseUserType', { userType: USER_TYPES.teacher });
    }, [navigation]);

    const handleGoBackPress = () => {
        if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] });
        }
    };

    return (
        <ComponentWrapper
            hasInset
            hasScroll={true}
            contentContainerStyle={styles.contentContainer}
            containerStyle={styles.container}
        >
            <Divider empty height={20} />
            <Text style={styles.title}>Go Premium!</Text>
            <Divider empty height={30} />
            <PremiumPlans onSelectHive={onSelectHive} />
            <View style={styles.topContainer}>
                <BackButton
                    text="Back"
                    onPress={handleGoBackPress}
                />
            </View>
        </ComponentWrapper>
    );
};

export { Subscriptions };

const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        minWidth: 1000,
    },
    topContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...mbPlatformStyle({
            mobile: {
                left: 20,
            },
            web: {
                left: 60,
            },
        }),
        position: 'absolute',
        top: 60,
    },
    title: {
        fontFamily: 'Secular One',
        fontSize: 32,
        textShadowColor: applyTransparency('#000000', 0.15),
        textShadowOffset: { width: 0, height: 4 },
        textShadowRadius: 2,
        color: '#121212',
    },
});
