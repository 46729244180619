import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const XIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 20}
          height={props.height ?? 20}
          viewBox="0 0 20 20"
          fill="none"
          {...props}
        >
          <Path
            d="M17.95 1.5L1.5 17.95M1.5 1.5l16.45 16.45"
            stroke={props.color ?? '#FF4419'}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Svg>
      </View>
    );
  },
);

export { XIcon };
