import React, { useMemo } from 'react';
import { View, Text, StyleSheet, ActivityIndicator} from 'react-native';
import { COLORS } from '../../../constants/colors';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { useGetTeacher } from '../../../graphql/admin/getTeachers';
import { utils } from '../../../utils/utils';
import {MB_TextInput} from '@mightybyte/rnw.components.text-input';
import { CustomScrollView } from '../../helperComponents/CustomScrollView';

const ViewTeacherDetails = ({id, closeAction}: {
    id: string,
    closeAction: () => void
}) => {
    const {data: teacher, loading} = useGetTeacher({variables: {userId: id}});

    const dataToDisplay = useMemo(() => {
        if (!teacher || loading) {
            return null;
        }
        const {getAdminTeacher} = teacher;
        const userData = [
            [{
                label: 'First Name',
                value: getAdminTeacher.firstName,
            }, {
                label: 'Last Name',
                value: getAdminTeacher.lastName,
            }],
            [{
                label: 'Email address',
                value: getAdminTeacher.email,
            }, {
                label: 'Date joined',
                value: utils.dateToDisplay(getAdminTeacher.joinedDate),
            }],
            ];
        return {
            userData,
            hives: getAdminTeacher.hives,
        };

    }, [teacher, loading]);

  return (
      <View style={styles.container}>
          <View style={[styles.headerContainer, {marginBottom: 16}]}>
              <Text style={styles.headerText}>View Teacher Details</Text>
              <AntDesign name={'close'} size={18} onPress={closeAction}/>
          </View>

          {loading ?  <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1}}>
              <ActivityIndicator size={'large'} color={COLORS.pumpkinYellow} />
          </View> : <CustomScrollView>
              <View>
                  {dataToDisplay?.userData.map((row, index)=> {
                      return (
                          <View key={index.toString()}
                                style={styles.inputRow}>
                              {row.map((item) => {
                                  return (
                                      <View key={`${item.label}-${item.value}`}
                                            style={styles.inputWrapper}>
                                          <MB_TextInput title={item.label}
                                                        value={item.value}
                                                        disable={true}
                                                        style={styles.inputStyle}
                                                        textInputStyle={styles.inputTextStyle}
                                                        titleStyle={styles.labelStyle}
                                          />
                                      </View>
                                  );
                              })
                              }
                          </View>
                      );
                  })}
                  <View style={[styles.headerContainer, {marginTop: 18, paddingLeft: 14}]}>
                      <Text style={[styles.headerText, {fontSize: 14}]}>{`Hives (${dataToDisplay?.hives?.length || 0})`}</Text>
                  </View>
                  {dataToDisplay?.hives && dataToDisplay?.hives.map((row, index)=> {
                      return (
                          <View key={index.toString()} style={styles.inputRow}>
                              <View style={styles.inputWrapper}>
                                  <MB_TextInput title="Hive Name"
                                                value={row.name}
                                                disable={true}
                                                style={styles.inputStyle}
                                                textInputStyle={styles.inputTextStyle}
                                                titleStyle={styles.labelStyle}
                                  />
                              </View>
                              <View style={styles.inputWrapper}>
                                  <MB_TextInput title="Hive Size"
                                                value={row.size}
                                                disable={true}
                                                style={styles.inputStyle}
                                                textInputStyle={styles.inputTextStyle}
                                                titleStyle={styles.labelStyle}
                                  />
                              </View>
                          </View>
                      );
                  })}

              </View>
          </CustomScrollView>}

    </View>
  );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
        width: 720,
        height: 610,
        maxHeight: 610,
        padding: 16,
        borderRadius: 10,
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 8,
        borderBottomColor: COLORS.offWhite,
        borderBottomWidth: 1,
    },
    headerText: {
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: '600',
    },
    labelStyle: {
        fontFamily: 'Inter',
        fontSize: 13,
        fontWeight: '400',
        color: COLORS.offBlack,
        marginBottom: 4,
    },
    inputStyle: {
        borderColor: COLORS.inputBorderLightGray,
        backgroundColor: COLORS.inactiveInput,
        borderRadius: 5,
        height: 46,
        width: '100%',
    },
    inputTextStyle: {
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: '400',
        color: COLORS.inactiveInputText,
    },
    inputRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 12,
        marginTop: 16,
    },
    inputWrapper: {
        width: '48%',
    },

});

export {ViewTeacherDetails};
