import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getStudents = gql`
    query getAdminStudents($pageSize: Int! = 1, $page: Int!=1) {
        getAdminStudents(pageSize: $pageSize, page: $page) {
            items {
                id
                name
                userName
                numberOfHives
                joinedDate
            }
            page
            total   
        }
   }
`;

export type getStudentsRequest = {
    pageSize: number,
    page: number,
};

type getStudentsResponseData = {
    id: string,
    name: string,
    userName: string,
    numberOfHives: number,
    joinedDate: string,
};

export type getStudentsResponse = {
    getAdminStudents: {
        items: getStudentsResponseData[],
        page: number,
        total: number,
    },
};

export const useGetStudents = (options: QueryHookOptions<getStudentsResponse, getStudentsRequest> = {}) => {
    return useQuery<getStudentsResponse, getStudentsRequest>(getStudents, options);
};
