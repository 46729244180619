import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../constants/colors';
import { Divider } from './Divider';
import { MenuButton } from './MenuButton';
import { Popup } from './Popup';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onYesPress: () => void;
  onNoPress: () => void;
}
const ExitPopup = ({ visible, setVisible, onYesPress, onNoPress }: IPopupProps) => {
  return (
    <Popup
      height={216}
      title=""
      hasHeader={false}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={false}
    >
      <View style={styles.container}>
        <Divider empty height={36} />
        <Text maxFontSizeMultiplier={1} style={styles.title}>QUIT GAME?</Text>
        <Divider empty height={12} />
        <View style={styles.buttonContainer}>
          <MenuButton
            height={62}
            width={116}
            onPress={onYesPress}
            text="Yes"
            colors={['#90DF75', '#62B655']}
            textStyle={styles.buttonTextStyle}
            style={styles.button}
          />
          <MenuButton
            height={62}
            width={116}
            onPress={onNoPress}
            text="No"
            colors={['#FF9252', '#FF3F15']}
            textStyle={styles.buttonTextStyle}
            style={styles.button}
          />
        </View>
      </View>
    </Popup>
  );
};

export { ExitPopup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  confetti: {
    position: 'absolute',
    width: 180,
    left: '50%',
    top: -50,
    transform: [{ translateX: -90 }],
  },
  title: {
    fontFamily: 'Secular One',
    color: '#ff6643',
    fontSize: 40,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  subtitle: {
    fontFamily: 'Secular One',
    color: '#86cf7b',
    fontSize: 24,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  button: {
    marginHorizontal: 12,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
