import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';

const ResetPasswordSuccessful = ({ navigation }: UniversalProps) => {
  return (
    <ComponentWrapper
      hasInset
      hasScroll={false}
      scrollEnabled={false}
      style={{ paddingHorizontal: 0 }}
    >
      <View style={styles.topContainer}>
        <BackButton onPress={() => navigation.goBack()} text="Back" />
      </View>
      <Text maxFontSizeMultiplier={1} style={styles.title}>
        Password Reset Successful!
      </Text>
      <Divider empty height={12} />
      <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
        Your password was reset successfully. You can now log in to your account.
      </Text>
      <Divider empty height={60} />
      <MenuButton
        onPress={() => navigation.navigate('SignIn')}
        colors={['#90DF75', '#62B655']}
        text="Back to login"
        height={64}
        width={320}
      />
    </ComponentWrapper>
  );
};

export { ResetPasswordSuccessful };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 28,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    width: 320,
    color: '#E89823',
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
