const sharedEnv = {
    GENERATED_MESSAGING_KEY: 'BCj52ysMx5zCYLADIXimnF5em2rNKgGPL-_5pdwArIxUMwOAgXe9TBMLo38njPoF8I-rJjakGBafTsViv-8sNiw',
    FIREBASE_CONFIG: {
        apiKey: 'AIzaSyCNQDlnCwHSI949KczxqxZN5vDnTX9-uxw',
        authDomain: 'number-hive.firebaseapp.com',
        projectId: 'number-hive',
        storageBucket: 'number-hive.appspot.com',
        messagingSenderId: '285194478935',
        appId: '1:285194478935:web:b0866c3747d2c0013bca12',
        measurementId: 'G-B4T8JQR8SF',
    },

    PLAY_STORE_DEEP_LINK: 'market://details?id=com.numberhive',
    APP_STORE_DEEP_LINK: 'itms-apps://apps.apple.com/be/app/number-hive/id1636921061',
    MOBILE_DEEP_LINK: 'number-hive://',
    MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT: 'number-hive-server-redirect://',
    PRIVACY_POLICY_LINK: 'https://app.termly.io/document/privacy-policy/a87aac02-84df-4d22-9b29-538ec7206ec0',
    TERMS_OF_SERVICE_LINK: 'https://app.termly.io/document/terms-of-service/670c413c-d367-486a-bf2c-7bf14b4b79b1',

    CONTACT_US_EMAIL: 'admin@numberhive.org',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const devEnv = {
    ENV: 'development',
    SERVER_URL: 'http://localhost:3000/graphql',
    API_URL: 'http://localhost:3000',
    SERVER_WEB_SOCKET_URL: 'ws://localhost:3000/graphql',
    WEBSITE_BASE_URL: 'http://localhost:8080',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stagingEnv = {
    ENV: 'staging',
    SERVER_URL: 'https://api.staging.hive.mightybyte.us/graphql',
    API_URL: 'https://api.staging.hive.mightybyte.us',
    SERVER_WEB_SOCKET_URL: 'wss://api.staging.hive.mightybyte.us/graphql',
    WEBSITE_BASE_URL: 'https://number-hive-staging.web.app',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const prodEnv = {
    ENV: 'production',
    SERVER_URL: 'https://api.numberhive.org/graphql',
    API_URL: 'https://api.numberhive.org',
    SERVER_WEB_SOCKET_URL: 'wss://api.numberhive.org/graphql',
    WEBSITE_BASE_URL: 'https://play.numberhive.org',
};


const envs = {
    ...sharedEnv,
    ...(__DEV__ ? stagingEnv : stagingEnv),
} as const;

export { envs };
