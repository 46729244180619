import React from 'react';
import { StyleSheet, View, Text, Pressable, StyleProp, ViewStyle, TextStyle } from 'react-native';

interface IDividerProps {
  text?: string | null;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  empty?: boolean;
  height: number;
}
const Divider = (props: IDividerProps) => {
  if (props.empty) {
    return (
      <View
        pointerEvents="none"
        style={[
          styles.empty,
          props.style,
          { maxHeight: props.height, height: props.height, minHeight: props.height },
        ]}
      />
    );
  }
  return props.text ? (
    <Pressable
      onPress={props.onPress}
      style={[
        styles.container,
        props.style,
        { maxHeight: props.height, height: props.height, minHeight: props.height },
      ]}
    >
      <View style={styles.line} />
      {props.text && <Text maxFontSizeMultiplier={1} style={[styles.text, props.textStyle]}>{props.text}</Text>}
      <View style={styles.line} />
    </Pressable>
  ) : (
    <View style={styles.line} />
  );
};

export { Divider };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  line: {
    flex: 1,
    borderTopWidth: 1,
    borderTopColor: '#898989',
    height: 1,
  },
  text: {
    fontSize: 14,
    color: '#898989',
    marginHorizontal: 0,
    marginVertical: 8,
    flex: 1,
    textAlign: 'center',
  },
  empty: {
    flex: 1,
    width: '100%',
    height: 20,
  },
});
