import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { Divider } from './Divider';
import { Popup } from './Popup';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}
const ReconnectPopup = ({ visible, setVisible }: IPopupProps) => {
  return (
    <Popup
      height={220}
      title="CONNECTION LOST"
      hasHeader={true}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={false}
      headerTextStyle={{ fontSize: 18 }}
      hideCloseButton
    >
      <View style={styles.container}>
        <Divider empty height={48} />
        <Text maxFontSizeMultiplier={1} style={styles.title}>Looks like you’re not connected to the internet.</Text>
        <Divider empty height={24} />
        <ActivityIndicator size={'large'} color={'#FF9252'}/>
        <Divider empty height={24} />
        <View>
          <Text maxFontSizeMultiplier={1} style={styles.subtitle}>Reconnecting...</Text>
        </View>
      </View>
    </Popup>
  );
};

export { ReconnectPopup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '400',
    color: '#000000',
    fontSize: 13,
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: '400',
    color: '#FC9500',
    fontSize: 13,
  },
});
