import React, { ReactNode, useMemo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, Text, ScrollView } from 'react-native';
import {MB_Table} from '@mightybyte/rnw.components.table';
import { COLORS } from '../../../../constants/colors';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { AdminNavigatorNavigationProp } from '../../../../typesInterfacesEnums/componentProps';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';


const AdminViewTable = (props: {
    header: {
        content: ReactNode,
        conatinerStyle: StyleProp<ViewStyle>
    }[],
    data: ReactNode[][] | undefined,
    loading?: boolean,
    currentPage: number,
    setCurrentPage: (page: number) => void,
    navigation: AdminNavigatorNavigationProp
    totalPages: number,
    totalItems: number,
    currentPageSize: number,
}) => {

    const pageIndicatorControlProps = useMemo(() => {
        return {
            title: '',
            buttonStyle: styles.indicatorButtonBase,
            activeColor: COLORS.pumpkinYellow,
            activeTextColor: COLORS.white,
            selectColor: COLORS.pumpkinYellow,
            hoverColor: COLORS.pumpkinYellow,
            inactiveTextColor: COLORS.offBlack,
            textStyle: mbTextStyles({
                fontFamily: 'Inter',
                fontSize: 13,
                fontWeight: '400',
            }),
        };
    }, []);

    const totalShowNote = useMemo(() => {
        const {totalItems, currentPageSize, currentPage} = props;
        const from = (currentPage - 1) * currentPageSize + 1;
        const to = Math.min(currentPage * currentPageSize, totalItems);
        return `Showing ${from} to ${to} of ${totalItems} items`;
    }, [props]);

    return (
        <View style={styles.tableWrapper}>
            <ScrollView style={{width: '100%'}}>
            <MB_Table style={styles.tableStyle}
                      {...props}
                      headerStyle={styles.tableHeader}
                      rowTextStyle={styles.textStyle}

            />
            </ScrollView>

            <View style={styles.indicatorWrapper}>
                <Text style={styles.footerText}>
                    {totalShowNote}
                </Text>
                <MB_PageIndicator
                    current={props.currentPage}
                    pages={props.totalPages}
                    onChange={(p)=> {
                        props.navigation.setParams({page: p});
                        props.setCurrentPage(p);
                    }}
                    indicatorButtonProp={{
                        buttonStyle: {
                            ...styles.indicatorButtonBase,
                            borderWidth: 0,
                        },
                        activeColor: 'transparent',
                        activeTextColor: COLORS.white,
                        selectColor: COLORS.pumpkinYellow,
                        hoverColor: COLORS.pumpkinYellow,
                        inactiveTextColor: COLORS.offBlack,
                        textStyle: {
                            fontSize: 13,
                            fontWeight: '400',
                        },

                    }}
                    leftButtonProps={pageIndicatorControlProps}
                    rightButtonProps={pageIndicatorControlProps}
                    indicatorIconSize={14}
                />
            </View>
        </View>
    );
};

export { AdminViewTable };

const styles = StyleSheet.create({
    tableWrapper: {
        borderRadius: 10,
        borderWidth: 0.1,
        borderColor: '#c4c4c480',
        shadowColor: '#00000008',
        shadowOffset: {width: 3, height: 3},
        shadowOpacity: 1,
        shadowRadius: 10,
        width: '100%',
        paddingVertical: 16,
        paddingHorizontal: 8,
        marginTop: 24,
    },
    tableStyle: {
        borderWidth: 0,
    },
    tableHeader: {
        height: 37,
        backgroundColor: COLORS.white,
        borderBottomWidth: 0.1,
        borderBottomColor: '#c4c4c480',
    },

    textStyle: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: 12,
        color: COLORS.offBlack,
    },
    footerText: {
        fontFamily: 'Inter',
        fontWeight: '600',
        fontSize: 12,
        color: COLORS.black,
    },

    indicatorWrapper: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 14,
    },
    indicatorButtonBase: {
        width: 36,
        height: 36,
        borderRadius: 6,
    },


});

