import React, { useCallback, useEffect } from 'react';
import { Alert, Linking, Platform } from 'react-native';
import { envs } from '../../../env';
import { utils } from '../../utils/utils';
import { version } from '../../version';
import { useGetMinimumMobileJSVersion } from '../../hooks/serverUtilHooks';

interface IProps {
  setVersionChecked: (value: boolean) => void;
}
const ForceUpdate = ({ setVersionChecked }: IProps) => {
  const { data: minimumMobileJSVersion } = useGetMinimumMobileJSVersion({ enabled: Platform.OS !== 'web', refetchOnWindowFocus: false });

  const openAppPageOnStore = async () => {
    const baseOS = Platform.OS;
    if (baseOS === 'android') {
      Linking.openURL(envs.PLAY_STORE_DEEP_LINK);
    } else if (baseOS === 'ios') {
      Linking.openURL(envs.APP_STORE_DEEP_LINK);
    }
  };

  const compareVersions = useCallback(async () => {
    if (minimumMobileJSVersion) {
      if (utils.compareVersion(version, minimumMobileJSVersion) < 0) {
        Alert.alert('Update is available!', 'Good news! A new version of Number Hive is available', [
          { text: 'Update', onPress: () => openAppPageOnStore() },
        ], { cancelable: false });
      } else {
        setVersionChecked(true);
      }
    }
  }, [setVersionChecked, minimumMobileJSVersion]);

  useEffect(() => {
    compareVersions();
  }, [compareVersions]);

  return (
    <></>
  );
};

export default ForceUpdate;
