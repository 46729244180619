import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const GraphIconFilled = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
}) => {
    const iconColor = color ?? '#fff';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 20}
                height={size ?? 20}
                viewBox="0 0 19 19"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.748 2.708a2.21 2.21 0 014.418 0 2.21 2.21 0 01-4.418 0zm-3.53 9.487l2.65-3.418-.037.018a.687.687 0 00.073-.687.676.676 0 00-.558-.403.704.704 0 00-.643.284l-2.217 2.869-2.54-1.998a.724.724 0 00-.523-.147.71.71 0 00-.458.274l-2.712 3.53-.056.083a.685.685 0 00.192.87.77.77 0 00.422.138.65.65 0 00.54-.275l2.302-2.962 2.612 1.963.083.054c.293.156.66.083.87-.193zm1.944-10.064c-.037.23-.055.459-.055.688a3.725 3.725 0 003.722 3.73c.229 0 .449-.027.678-.064v7.397c0 3.108-1.833 4.95-4.95 4.95H5.784c-3.118 0-4.951-1.842-4.951-4.95V7.1c0-3.117 1.833-4.969 4.95-4.969h7.38z"
                    fill={iconColor}
                />
            </Svg>
        </View>
    );
});

export default GraphIconFilled;
