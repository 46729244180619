import { nanoid } from 'nanoid';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const BackIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    const gradientId = nanoid();

    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 24}
          height={props.height ?? 18}
          viewBox="0 0 24 18"
          fill="none"
          {...props}
        >
          <Path
            d="M22.797 17.058a.479.479 0 01-.428-.264c-.028-.053-2.222-4.122-11.378-4.26v4.046a.478.478 0 01-.77.38L.188 9.27a.479.479 0 010-.76L10.221.823a.48.48 0 01.77.379v4.264c.242-.016.558-.029.93-.029 3.414 0 11.355 1.086 11.355 11.142a.479.479 0 01-.48.48z"
            fill={`url(#${gradientId})`}
          />
          <Defs>
            <LinearGradient
              id={gradientId}
              x1={11.638}
              y1={0.724136}
              x2={11.638}
              y2={17.0584}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#747474" />
              <Stop offset={1} stopColor="#1F1F1F" />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { BackIcon };
