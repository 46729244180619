import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GameType } from './useGameEventSubscription.gql';

const mutation = gql`
  mutation claimGameTileV2($gameId: String!, $rightOperand: String!, $leftOperand: String!, $tileId: Int) {
    claimGameTileV2(gameId: $gameId, rightOperand: $rightOperand, leftOperand: $leftOperand, tileId: $tileId) {
      id
      status
      users {
        id
        name
      }
      gameState {
        gameType
        turn
        userNameTurn
        winningPlayerId
        winningPlayerUserName
        lastPlayedTimeAt
        winningTiles
        winningDirection
        leftOperand{
          a
          x
          b
        }
        rightOperand{
          a
          x
          b
        }
        tiles {
          id
          value {
            a
            x
            b
          }
          edges
          claimedBy
          claimedByUserName
        }
      }
    }
  }
`;

export interface RequestType {
  gameId: string;
  rightOperand: string;
  leftOperand: string;
  tileId?: number
}

export interface MutationType {
  claimGameTileV2: GameType;
}

export function useClaimGameTileMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
