import React, { memo, useMemo } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { GameTilePolygon } from '../../../resources/svgComponents/GameTilePolygon';
import { SCREEN_SIZES, useScreenSize } from '../../../utils/dimsHooks';
import { GameHiveTileType } from './useGameEventSubscription.gql';
import { renderValue } from './utils';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface Props {
  rowIndex: number;
  tiles: GameHiveTileType[];
  getClaimColors: (playerId: string, playerUserName?: string) => [string, string];
  isActiveRow: boolean;
  waitingTiles: number[];
  onPress: (idx: number) => void;
}

export const TileRow = memo(({ rowIndex, tiles, getClaimColors, waitingTiles, onPress, isActiveRow }: Props) => {
  const screenSize = useScreenSize();

  const tileWidth = (Dimensions.get('screen').width - 40) / 8;
  const isLarge = screenSize === SCREEN_SIZES.LARGE;
  const isMedium = screenSize === SCREEN_SIZES.MEDIUM;

  const calculateCellSpace = useMemo(() => {
    if (isMobile) {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return -19;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return -14;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return -9.5;
      }
      return -13;
    } else {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return -19;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return -11;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return -9.5;
      }
      return -11;
    }
  }, [screenSize]);


  const tileStyles = useMemo(
    () => [
      styles.row,
      { marginTop: calculateCellSpace },
    ],
    [calculateCellSpace],
  );

  return (
    <View pointerEvents={isActiveRow ? 'auto' : 'none'} key={`row_${rowIndex}`} style={rowIndex === 0 ? styles.row : tileStyles}>
      {tiles.map((item: GameHiveTileType, tileIndex: number) => (
        <GameTilePolygon
          text={renderValue(item.value)}
          key={`tile_${tileIndex}`}
          isActive={item.claimedBy !== '-1'}
          width={tileWidth}
          colors={getClaimColors(item.claimedBy, item.claimedByUserName)}
          isLargeDisplay={isLarge}
          isMediumDisplay={isMedium}
          isWaitingToSelect={waitingTiles.indexOf(item.id) !== -1}
          onPress={() => onPress(item.id)}
        />
      ))}
    </View>
  );
});

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
