import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, ClipPath, Defs } from 'react-native-svg';

const CoinIcon = React.memo(({size, containerStyle}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    filled?: boolean,
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 22}
                height={size ?? 22}
                viewBox="0 0 22 22"
                fill="none"
            >
                <G clipPath="url(#clip0_1819_10855)">
                    <Path
                        d="M11 21.5c5.799 0 10.5-4.701 10.5-10.5S16.799.5 11 .5.5 5.201.5 11 5.201 21.5 11 21.5z"
                        fill="#FABE2C"
                    />
                    <Path
                        d="M21.5 11C21.5 5.224 16.776.5 11 .5v21c5.776 0 10.5-4.724 10.5-10.5z"
                        fill="#FF9100"
                    />
                    <Path
                        d="M10.998 18.996a7.998 7.998 0 100-15.996 7.998 7.998 0 000 15.996z"
                        fill="#FED843"
                    />
                    <Path
                        d="M18.998 10.998C18.998 6.588 15.41 3 11 3v15.996c4.41 0 7.998-3.588 7.998-7.998z"
                        fill="#FABE2C"
                    />
                    <Path
                        d="M16.265 9.29a.615.615 0 00-.497-.418l-2.913-.424-1.304-2.64c-.104-.21-.328-.316-.552-.316-.224 0-.448.105-.551.316l-1.304 2.64-2.914.424a.616.616 0 00-.341 1.049l2.109 2.056-.498 2.902a.615.615 0 00.892.649L11 14.158l2.607 1.37a.615.615 0 00.892-.648L14 11.976l2.11-2.056a.616.616 0 00.155-.63z"
                        fill="#FABE2C"
                    />
                    <Path
                        d="M14.255 15.48a.615.615 0 00.244-.6l-.498-2.903 2.11-2.056a.615.615 0 00-.342-1.05l-2.914-.423-1.303-2.64c-.104-.21-.328-.316-.552-.316v8.666l2.607 1.37c.207.109.459.091.648-.047z"
                        fill="#FF9100"
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_1819_10855">
                        <Path fill="#fff" transform="translate(.5 .5)" d="M0 0H21V21H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export default CoinIcon;
