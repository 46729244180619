import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ChangeNameIcon = (props: { size?: number, color?: string, containerStyle?: StyleProp<ViewStyle> }) => {
	return (
		<View style={props.containerStyle}>
			<Svg
				width={props.size ?? 24}
				height={props.size ?? 24}
				viewBox="0 0 24 24"
				fill="none"
			>
				<Path
					d="M13.75 20.444h7.253"
					stroke={props.color ?? '#000'}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<Path
					clipRule="evenodd"
					d="M12.78 3.795a2.245 2.245 0 013.116-.303l1.733 1.347a2.142 2.142 0 01.72 2.984c-.034.054-9.537 11.942-9.537 11.942a1.699 1.699 0 01-1.31.632l-3.638.046-.82-3.47c-.115-.489 0-1.001.316-1.396l9.42-11.782z"
					stroke={props.color ?? '#000'}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<Path
					d="M11.023 6l5.453 4.187"
					stroke={props.color ?? '#000'}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</Svg>
		</View>
	);
};

export { ChangeNameIcon };
