import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Popup } from './Popup';
import { MenuButton } from './MenuButton';
import { Divider } from './Divider';
import { applyTransparency } from '../../constants/colors';
import { useSignedInContext } from '../../context/SignedInContext';
import { USER_TYPES } from '../../typesInterfacesEnums/enums';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onButtonClicked?: (type: 'Learn More' | 'Create Hive') => void
}
const GoPremiumPopup = ({ visible, setVisible, onButtonClicked }: IPopupProps) => {
  const { user, isSignedIn } = useSignedInContext();

  const message = useMemo(() => {
    if (user?.userType === USER_TYPES.user) {
      return 'Speak to a parent or teacher to unlock premium game boards';
    }
    return 'Create a Hive to unlock game boards and access premium features';
  }, [user?.userType]);

  const buttonText = useMemo(() => {
    if (!isSignedIn) {
      return 'Learn More';
    } else if (user?.userType === USER_TYPES.teacher) {
      return 'Create Hive';
    }
    return '';
  }, [isSignedIn, user?.userType]);

  return (
    <Popup
      title="Go Premium!"
      height="auto"
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={20} />
        <Text style={styles.title}>{message}</Text>
        <Divider empty height={24} />
        {(!isSignedIn || user?.userType === USER_TYPES.teacher) &&
          <MenuButton
            height={62}
            width={320}
            onPress={() => buttonText !== '' && onButtonClicked?.(buttonText)}
            text={buttonText}
            colors={['#90DF75', '#62B655']}
            textStyle={styles.buttonTextStyle}
          />
        }
      </View>
    </Popup>
  );
};

export { GoPremiumPopup };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
    textAlign: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
