import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { UniversalProps } from '../../../typesInterfacesEnums/componentProps';
import { UsernameIcon } from '../../../resources/svgComponents/UsernameIcon';
import { PasswordIcon } from '../../../resources/svgComponents/PasswordIcon';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { useLoginMutation } from './useLoginMutation.gql';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { getErrorText } from '../../../utils/errors';

enum INPUT_TYPES {
  userName = 'userName',
  password = 'password',
}

const SignIn = ({ navigation }: UniversalProps) => {
  const { setSignedInStatus } = useSignedInContext();

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isShowingPassword, setShowingPassword] = useState(false);
  const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const [login, { data, error, loading }] = useLoginMutation({
    variables: { email: userName, password },
  });

  const areInputsValid = () => {
    if (userName.length === 0 && password.length === 0) {
      setInputError([INPUT_TYPES.userName, INPUT_TYPES.password]);
      setHasError({ visible: true, popup: true, text: 'Please enter email/username and password' });
      return false;
    }
    if (userName.length === 0) {
      setInputError([INPUT_TYPES.userName]);
      setHasError({ visible: true, popup: true, text: 'Please enter email/username' });
      return false;
    }
    if (password.length === 0) {
      setInputError([INPUT_TYPES.password]);
      setHasError({ visible: true, popup: true, text: 'Please enter the password' });
      return false;
    }
    return true;
  };

  const handleLogin = () => {
    if (areInputsValid()) {
      login().catch((err) => handleLoginError(err));
    }
  };

  const handleLoginError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (data) {
      const setAccessToken = async () => {
        let accessToken = { ...data.login.accessToken };
        delete accessToken.__typename;
        await MB_accessTokenUtils.setAccessToken({ accessToken: accessToken, refreshToken: data.login.refreshToken });
        setSignedInStatus(SIGNED_IN_STATUS.signedIn);

        //todo Y navigate admin to admin panel and other users to lobby
        navigation.navigate('Lobby');
      };
      setAccessToken();
    }
  }, [data, navigation, setSignedInStatus]);

  useEffect(() => {
    if (error) {
      handleLoginError(error);
    }
  }, [error]);
  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Sign In
        </Text>
        <Divider empty height={16} />
        <Text style={styles.label}>Email/Username</Text>
        <Divider empty height={8} />
        <Input
          value={userName}
          setValue={(value) => setUserName(value)}
          leftIcon={<UsernameIcon />}
          style={{ width: 320 }}
          textStyle={{ fontSize: 18 }}
          placeholder="Email/Username"
          hasError={inputError.includes(INPUT_TYPES.userName)}
          onSubmitEditing={() => handleLogin()}
        />
        <Divider empty height={16} />
        <Text style={styles.label}>Password</Text>
        <Divider empty height={8} />
        <Input
          showPassword={isShowingPassword}
          handleShowPassClick={() => setShowingPassword((v) => !v)}
          value={password}
          setValue={(value) => setPassword(value)}
          leftIcon={<PasswordIcon />}
          isPassword
          style={{ width: 320 }}
          textStyle={{ fontSize: 18 }}
          placeholder="Password"
          hasError={inputError.includes(INPUT_TYPES.password)}
          onSubmitEditing={() => handleLogin()}
        />
        <Divider empty height={16} />
        <TouchableOpacity style={styles.forgetContainer} onPress={() => navigation.push('RequestForgetPasswordCode')}>
          <Text style={styles.forgetText}>Forgot Password?</Text>
        </TouchableOpacity>
        <Divider empty height={32} />
        <MenuButton
          onPress={() => handleLogin()}
          colors={['#90DF75', '#62B655']}
          text="Sign In"
          height={64}
          width={320}
          loading={loading}
          disabled={loading}
        />
        <Divider empty height={16} />
        <View style={styles.loginContainer}>
          <Text style={styles.loginText}>Don’t have an account? </Text>
          <TouchableOpacity onPress={() => navigation.push('ChooseUserType')}>
            <Text style={styles.loginText2}>Sign up</Text>
          </TouchableOpacity>
        </View>
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { SignIn };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    marginBottom: 12,
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  loginContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  loginText2: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#E89823',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
  },
  label: {
    fontFamily: 'Secular One',
    fontSize: 15,
    color: '#453B3B',
    textAlign: 'center',
  },
});
