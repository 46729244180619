import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { Input } from '../../helperComponents/Input';
import { applyTransparency } from '../../../constants/colors';
import { MenuButton } from '../../helperComponents/MenuButton';
import { BackButton } from '../../helperComponents/BackButton';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { ApolloError } from '@apollo/client';
import { useResetPasswordMutation } from './useResetPasswordMutation.gql';
import { PasswordIcon } from '../../../resources/svgComponents/PasswordIcon';
import { getErrorText } from '../../../utils/errors';

const ResetPassword = ({ navigation, route }: ScreenProps<'ResetPassword'>) => {
  const { setSignedInStatus } = useSignedInContext();
  const [password, setPassword] = useState('');
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [isShowingPassword, setShowingPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordHasError, setConfirmPasswordHasError] = useState(false);
  const [isShowingConfirmPassword, setShowingConfirmPassword] = useState(false);

  const [resetPassword, { data: resetPasswordData, error: resetPasswordError, loading }] = useResetPasswordMutation({
    variables: { resetPasswordCode: route.params.code, email: route.params.email, password: password },
  });
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleResetPasswordError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (resetPasswordData) {
      navigation.navigate('ResetPasswordSuccessful');
    }
  }, [resetPasswordData, navigation, setSignedInStatus]);

  useEffect(() => {
    if (resetPasswordError) {
      handleResetPasswordError(resetPasswordError);
    }
  }, [resetPasswordError]);

  const handleResetPasswordPress = () => {
    if (password === confirmPassword) {
      if (password.length >= 8) {
        resetPassword();
      } else {
        setPasswordHasError(true);
        setHasError({ visible: true, popup: true, text: 'Password must be longer than 8' });
      }
    } else {
      setConfirmPasswordHasError(true);
      setHasError({ visible: true, popup: true, text: 'Confirm password is wrong' });
    }
  };
  return (
    <>
      <ComponentWrapper
        hasInset
        hasScroll={false}
        scrollEnabled={false}
        style={{ paddingHorizontal: 0 }}
      >
        <View style={styles.topContainer}>
          <BackButton onPress={() => navigation.goBack()} text="Back" />
        </View>
        <Text maxFontSizeMultiplier={1} style={styles.title}>
          Reset Password
        </Text>
        <Divider empty height={12} />
        <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
          Please enter your new password below.
        </Text>
        <Divider empty height={36} />
        <Text style={styles.label}>Password</Text>
        <Divider empty height={8} />
        <Input
          isPassword
          value={password}
          showPassword={isShowingPassword}
          handleShowPassClick={() => setShowingPassword((v) => !v)}
          setValue={(value) => setPassword(value)}
          style={{ width: 320 }}
          textStyle={{ fontSize: 18 }}
          hasError={passwordHasError}
          placeholder="Password"
          leftIcon={<PasswordIcon />}
          onSubmitEditing={() => handleResetPasswordPress()}
        />
        <Divider empty height={16} />
        <Text style={styles.label}>Confirm password</Text>
        <Divider empty height={8} />
        <Input
          isPassword
          value={confirmPassword}
          showPassword={isShowingConfirmPassword}
          setValue={(value) => setConfirmPassword(value)}
          handleShowPassClick={() => setShowingConfirmPassword((v) => !v)}
          style={{ width: 320 }}
          textStyle={{ fontSize: 18 }}
          hasError={confirmPasswordHasError}
          placeholder="Confirm password"
          leftIcon={<PasswordIcon />}
          onSubmitEditing={() => handleResetPasswordPress()}
        />
        <Divider empty height={40} />
        <MenuButton
          onPress={handleResetPasswordPress}
          colors={['#90DF75', '#62B655']}
          text="Reset password"
          height={64}
          width={320}
          loading={loading}
          disabled={loading}
        />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { ResetPassword };

const styles = StyleSheet.create({
  image: { width: 180, height: 100 },
  howToPlayText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: 'black',
    textDecorationLine: 'underline',
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#E89823',
    textAlign: 'center',
  },
  topContainer: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 60,
    ...mbPlatformStyle({
      mobile: {
        paddingHorizontal: 24,
      },
      web: {
        paddingHorizontal: 120,
      },
    }),
  },
  beeIcon: {
    width: 25,
    height: 38,
  },
  forgetContainer: {
    width: 320,
    justifyContent: 'flex-start',
  },
  forgetText: {
    fontFamily: 'Secular One',
    fontSize: 16,
    color: '#E89823',
    textAlign: 'right',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signUpText: {
    fontFamily: 'Secular One',
    fontSize: 20,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  label: {
    fontFamily: 'Secular One',
    fontSize: 15,
    color: '#453B3B',
    textAlign: 'center',
  },
});
