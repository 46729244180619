import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation hostGame($userName: String!, $gameType: GameType!) {
    hostGame(userName: $userName, gameType: $gameType) {
      game {
        id
        joinCode
      }
    }
  }
`;

export interface RequestType {
  userName: string;
  gameType: GAME_TYPES_TYPE;
}

export interface MutationType {
  hostGame: HostOnlineGameResponseType;
}

export interface HostOnlineGameResponseType {
  game: GameType;
  __typename?: 'HostOnlineGameResponse';
}

export interface GameType {
  id: string;
  joinCode?: string;
  __typename?: 'Game';
}

export function useHostGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
