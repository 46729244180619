import * as React from 'react';
import { nanoid } from 'nanoid';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  containerStyle?: StyleProp<ViewStyle>;
  colors: [string, string];
}

const PointPolygon = React.memo(
  ({ colors, color, containerStyle, height = 60, width = 52 }: Props) => {
    const gradientId = nanoid();

    return (
      <View style={containerStyle}>
        <Svg width={width} height={height} viewBox="0 0 52 60" fill="none" color={color}>
          <G>
            <Path d="M26 54L5.215 42V18L26 6l20.785 12v24L26 54z" fill={`url(#${gradientId})`} />
            <Path
              d="M25.75 54.433l.25.144.25-.144 20.785-12 .25-.144V17.71l-.25-.144-20.785-12-.25-.144-.25.144-20.785 12-.25.144V42.29l.25.144 20.785 12z"
              stroke="#fff"
            />
          </G>
          <Defs>
            <LinearGradient
              id={gradientId}
              x1={26}
              y1={54}
              x2={26}
              y2={6}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor={colors[0]} />
              <Stop offset={1} stopColor={colors[1]} />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { PointPolygon };
