import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, G, Defs, ClipPath} from 'react-native-svg';

const NoTeachersIcon = React.memo(({size, containerStyle, color}: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,

}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 101}
                height={size ? size * (80 / 101) : 80}
                viewBox="0 0 101 80"
            >
                <G clipPath="url(#clip0_912_3561)" fill={iconColor}>
                    <Path d="M18.092 57.603c-6.453 0-11.895-5.248-11.895-11.7 0-6.453 5.442-11.7 11.895-11.7 6.452 0 11.7 5.247 11.7 11.7 0 6.452-5.248 11.7-11.7 11.7z" />
                    <Path d="M18.092 57.603C8.399 57.603.347 65.46.347 75.153v5.85h23.595l9.134-8.338-2.146-9.402c-3.206-3.46-7.75-5.66-12.838-5.66zM82.44 57.603c-6.451 0-11.699-5.248-11.699-11.7 0-6.453 5.248-11.7 11.7-11.7 6.452 0 11.7 5.247 11.7 11.7 0 6.452-5.248 11.7-11.7 11.7z" />
                    <Path d="M82.441 57.603c-5.089 0-9.633 2.2-12.839 5.66l-1.715 10.495 8.704 7.244h23.595v-5.85c0-9.692-8.052-17.55-17.745-17.55zM50.266 19.383c-9.652 0-17.55 8.093-17.55 17.745 0 9.653 7.898 17.55 17.55 17.55 9.653 0 17.55-7.897 17.55-17.55 0-9.652-7.897-17.745-17.55-17.745z" />
                    <Path d="M76.591 81.002h-52.65c0-6.844 2.692-13.045 6.962-17.725 4.856-5.264 11.7-8.6 19.363-8.6 7.664 0 14.508 3.336 19.364 8.6 4.27 4.68 6.961 10.88 6.961 17.725z" />
                    <Path d="M50.266 54.678V19.383c9.653 0 17.55 8.093 17.55 17.745 0 9.653-7.897 17.55-17.55 17.55zM76.591 81.002H50.266V54.678c7.664 0 14.508 3.335 19.364 8.599 4.27 4.68 6.961 10.88 6.961 17.725z" />
                </G>
                <Defs>
                    <ClipPath id="clip0_912_3561">
                        <Path
                            fill={iconColor}
                            transform="translate(.347 .273)"
                            d="M0 0H99.8389V99.8389H0z"
                        />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
});

export default NoTeachersIcon;
