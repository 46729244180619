import React from 'react';
import { StyleSheet, Text, View, Pressable, Dimensions, StyleProp, ViewStyle, TextStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../../constants/colors';
import { MB_Modal } from '../../mightyByteLibraries/MB_Modal/MB_Modal';
import { CloseIcon } from '../../resources/svgComponents/CloseIcon';

interface IPopupProps {
  title: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  height?: number | 'auto';
  children?: React.ReactNode;
  hasHeader?: boolean;
  closeOnBackdropPress?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  headerTextStyle?: StyleProp<TextStyle>;
  hideCloseButton?: boolean
  headerComponent?: React.ReactNode;
}
const Popup = ({
  title,
  visible,
  setVisible,
  children,
  height,
  hasHeader = true,
  closeOnBackdropPress = true,
  containerStyle,
  headerTextStyle,
  hideCloseButton,
  headerComponent,
}: IPopupProps) => {
  return (
    <MB_Modal
      hideCloseButton
      isVisible={visible}
      backdropOpacity={20}
      backdropColor="#02010161"
      backdropBlurIntensity={0}
      animationIn="fadeIn"
      animationOut="fadeOut"
      onBackdropPress={() => (closeOnBackdropPress ? setVisible(false) : null)}
    >
      <View
        style={[
          styles.container,
          {
            width: Dimensions.get('screen').width - 32,
            height: height || 350,
          },
          containerStyle,
        ]}
      >
        {hasHeader && (
          <>
            {
              hideCloseButton ? null :
                <Pressable style={{ zIndex: 10 }} onPress={() => setVisible(false)}>
                  <CloseIcon containerStyle={styles.closeIcon} />
                </Pressable>
            }
            {headerComponent ? headerComponent : <View style={styles.absoluteContainer}>
              <LinearGradient style={styles.titleContainer} colors={['#FF9252', '#FF3F15']}>
                <Text maxFontSizeMultiplier={1} style={[styles.title, headerTextStyle]}>{title}</Text>
              </LinearGradient>
            </View>}
          </>
        )}
        {children}
      </View>
    </MB_Modal>
  );
};

export { Popup };

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    maxWidth: 400,
    backgroundColor: '#FDFDFD',
    borderRadius: 24,
    borderColor: '#FF9900',
    borderWidth: 3,
    maxHeight: '80%',
  },
  title: {
    color: 'white',
    fontFamily: 'Secular One',
    fontSize: 26,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 2,
  },
  absoluteContainer: {
    position: 'absolute',
    top: -27,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    height: 54,
    paddingHorizontal: 10,
    borderRadius: 8,
    borderColor: applyTransparency('#FFFFFF', 0.4),
    borderWidth: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: { position: 'absolute', right: -8, top: -20, zIndex: 10 },
});
