import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { Divider } from '../../helperComponents/Divider';
import { applyTransparency } from '../../../constants/colors';
import { mbPlatformStyle } from '@mightybyte/rnw.utils.style-utils';
import {isMobile} from '@mightybyte/rnw.utils.device-info';
import { BackButton } from '../../helperComponents/BackButton';
import { HiveUserType, useHiveUsersQuery } from './useHiveUsersQuery.gql';
import { ApolloError } from '@apollo/client';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { InviteIcon } from '../../../resources/svgComponents/InviteIcon';
import { sizeTitle } from '../../../constants/constants';
import { DropdownMenu } from '../../helperComponents/DropdownMenu/DropdownMenu';
import { useSignedInContext } from '../../../context/SignedInContext';
import LinearGradient from 'react-native-linear-gradient';
import { YourPoints } from '../../helperComponents/YourPoints';
import {createLeaderboardData, LeaderBoardButton, LeaderBoard} from '../../helperComponents/LeaderBoard';
import { getErrorText } from '../../../utils/errors';

const MyHiveDataStudent = ({ navigation, route }: ScreenProps<'MyHiveDataStudent'>) => {
  const { user } = useSignedInContext();
  const { id, name, size } = route.params;
  const [users, setUsers] = useState<Partial<HiveUserType>[]>([]);

  // TODO: We might need to paginate this in the future.
  const { error: getHivesError, data: getHivesData, loading } = useHiveUsersQuery({ hiveId: id, page: 1, pageSize: 2000 });
  const [showLeaderboard, setShowLeaderboard] = useState(false);

  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const handleError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  useEffect(() => {
    if (getHivesError) {
      handleError(getHivesError);
    }
  }, [getHivesError]);


  useEffect(() => {
    if (getHivesData) {
      setUsers(getHivesData.hiveUsers);
    }
  }, [getHivesData]);

  const handleInvitePress = (inviteeName?: string, inviteeId?: string) => {
    navigation.navigate('SelectGameType', {
      inviteeId,
      next: 'WaitingForHiveMate',
      userName: user?.email,
      inviteeName,
      hiveDetail: { name, id, size },
    });
  };

  const leaderBoardData = useMemo(() => {
    const currentStudent = (user && user?.points) ? ({ ...user, userName: user.email, points: user.points }) : undefined;
    return createLeaderboardData({
      users,
      currentStudent,
    });
  }, [users, user]);

  return (
    <>
      <ComponentWrapper hideBee hasInset scrollEnabled hasScroll={true} style={{ paddingHorizontal: 0 }}>
        <LeaderBoard data={leaderBoardData}
                     isVisible={showLeaderboard}
                     onClose={()=>{setShowLeaderboard(false);}}
        />
        <Divider empty height={isMobile ? 150 : 120} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <View style={{
            flexDirection: 'row',
          }}>
            <YourPoints points={user?.points} />
            <LeaderBoardButton action={()=> setShowLeaderboard(true)} loading={loading}/>
          </View>
          <Divider empty height={20} />
          <Text style={styles.title}>{name}</Text>
          <Divider empty height={20} />
          <View style={{ flexDirection: 'row' }}>
            <View style={styles.chips}><Text style={styles.chipsText}>Size: <Text style={styles.bold}>{sizeTitle[size]}</Text></Text></View>
          </View>
        </View>
        <Divider empty height={40} />
        <Text style={styles.inviteText}>Invite Hivemate</Text>
        <Divider empty height={60} />
        {
          users.length === 0 && !loading ?
            <Text>It's just you in this hive!</Text>
            :
            <View style={styles.container}>
              <View style={styles.header}>
                <View style={styles.headerView}>
                  <Text style={styles.headerText}>Username</Text>
                </View>
                <View style={styles.headerView}>
                  <Text style={styles.headerText}>Invite</Text>
                </View>
              </View>
              {users.map((item, index: number) => {
                return (
                  <View key={item.id ?? index.toString()} style={[styles.row, index % 2 !== 0 ? { backgroundColor: 'transparent' } : { backgroundColor: 'white' }]}>
                    <View style={styles.rowView}>
                      <Text style={styles.rowText}>{item.userName}</Text>
                    </View>
                    <View style={styles.rowView}>
                      <TouchableOpacity onPress={() => handleInvitePress(item.userName, item.id)} style={{ marginHorizontal: 10 }}>
                        <InviteIcon />
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}

            </View>
        }
        <DropdownMenu
          backButton={
            <BackButton
              onPress={() => navigation.reset({ index: 1, routes: [{ name: 'Lobby' }, { name: 'MyHives' }] })}
              text="Back"
            />
          }
        />
        <View style={styles.topContainer}>
          <TouchableOpacity onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })}>
            <LinearGradient style={styles.buttonContainer} colors={['#FFCF53', '#FF9900']}>
              <Text maxFontSizeMultiplier={1} style={styles.buttonText}>
                Play
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
        <Divider empty height={200} />
      </ComponentWrapper>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { MyHiveDataStudent };

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginTop: 54,
    ...mbPlatformStyle({
      mobile: {
        right: 20,
      },
      web: {
        right: 60,
      },
    }),
    position: 'absolute',
    top: 114,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  nameContainer: {
    borderRadius: 12,
    width: 180,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -20,
  },
  name: {
    fontFamily: 'Secular One',
    fontSize: 18,
    color: '#453B3B',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
  title: {
    fontFamily: 'Secular One',
    fontSize: 40,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  hivesContainer: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiveType: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    position: 'absolute',
    top: 30,
    left: -100,
    textAlign: 'right',
    width: 80,
    letterSpacing: 2,
  },
  container: {
    width: '100%',
    maxWidth: 420,
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  },
  headerText: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: 14,
    color: '#333333',
  },
  headerView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerViewLast: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F9F9F9',
    padding: 20,
  },
  rowText: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: 14,
    color: '#453B3B',
  },
  rowView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowViewLast: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  infoContainer: {
    width: '100%',
    paddingHorizontal: 54,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  infoKey: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 20,
    width: 200,
  },
  infoValue: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: 20,
  },
  chips: {
    width: 180,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFEED6',
    marginHorizontal: 8,
    borderRadius: 20,
  },
  chipsText: {
    fontFamily: 'Inter',
    color: '#E89823',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '500',
  },
  bold: {
    fontWeight: '700',
  },
  inviteText: {
    fontFamily: 'Secular One',
    fontSize: 22,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderRadius: 10,
  },
  buttonText: {
    fontFamily: 'Secular One',
    color: '#1F1F1F',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    fontSize: 16,
  },
});
