import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Popup } from './Popup';
import { MenuButton } from './MenuButton';
import { Divider } from './Divider';
import { applyTransparency } from '../../constants/colors';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onRemovePress: () => void;
  firstName: string,
  lastName: string,
  loading: boolean
}
const RemovePopup = ({ visible, setVisible, onRemovePress, firstName, lastName, loading }: IPopupProps) => {
  return (
    <Popup
      height={270}
      title="Delete Hive Member"
      headerTextStyle={{ fontSize: 18 }}
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <Divider empty height={32} />
        <Text style={styles.title}>Are you sure you want to delete “{firstName} {lastName}” from your hive. This action cannot be undone.</Text>
        <Divider empty height={32} />
        <MenuButton
          height={62}
          width={320}
          onPress={onRemovePress}
          text="Delete member"
          colors={['#DF3A3A', '#C91717']}
          textStyle={styles.buttonTextStyle}
          loading={loading}
          disabled={loading}
        />
      </View>
    </Popup>
  );
};

export { RemovePopup };

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 20,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
});
