import { StyleProp, ViewStyle } from 'react-native';
import { adminPageStyles } from './adminPageStyles';

export enum TabNames {
    Teachers = 'Teachers',
    Students = 'Students',
    Analytics = 'Analytics',
}

export function createTableColumn ({name, containerStyle}: {
    name: string,
    containerStyle?: StyleProp<ViewStyle>
}) {
    const containerStyleDefault = {
        height: 53,
    };
    return {
        content: name,
        conatinerStyle: [containerStyleDefault, containerStyle || {}],
        textStyle: adminPageStyles.tableHeaderTextStyle,
    };
}
