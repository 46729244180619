import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { LinkingOptions, NavigationContainer, createNavigationContainerRef, getStateFromPath } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Lobby } from '../components/screens/Lobby/Lobby';
import { PlayOnline } from '../components/screens/PlayOnline';
import { HostGame } from '../components/screens/HostGame';
import { JoinGame } from '../components/screens/JoinGame';
import { PlayWithAI } from '../components/screens/PlayWithAI';
import { JoinCode } from '../components/screens/JoinCode';
import { Game, GameType, GameMode } from '../components/screens/GameScreen';
import { Splash } from '../components/screens/Splash';
import { AIDifficultyLevel } from '../components/screens/PlayWithAI/useJoinAiGameMutation.gql';
import { Platform } from 'react-native';
import ForceUpdate from '../components/helperComponents/ForceUpdate';
import { SignIn } from '../components/screens/Login/Login';
import { RequestForgetPasswordCode } from '../components/screens/ForgotPassword/RequestForgetPasswordCode';
import { SignUp } from '../components/screens/SignUp/SignUp';
import { ChooseUserType } from '../components/screens/ChooseUserType/ChooseUserType';
import { USER_TYPES } from '../typesInterfacesEnums/enums';
import { VerifyEmail } from '../components/screens/VerifyEmail/VerifyEmail';
import { SIGNED_IN_STATUS, useSignedInContext } from '../context/SignedInContext';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { VerifyForgetPasswordCode } from '../components/screens/ForgotPassword/VerifyForgetPasswordCode';
import { ResetPassword } from '../components/screens/ForgotPassword/ResetPassword';
import { ResetPasswordSuccessful } from '../components/screens/ForgotPassword/ResetPasswordSuccessful';
import { MyHives } from '../components/screens/MyHives/MyHives';
import { CreateHive } from '../components/screens/CreateHive/CreateHive';
import { SelectHiveSize } from '../components/screens/CreateHive/SelectHiveSize';
import { MyHiveData } from '../components/screens/MyHiveData/MyHiveData';
import { JoinHive } from '../components/screens/JoinHive/JoinHive';
import { OrderSummary } from '../components/screens/CreateHive/OrderSummary';
import { MyHiveDataStudent } from '../components/screens/MyHiveDataStudent/MyHiveDataStudent';
import { SelectGameType } from '../components/screens/SelectGameType/SelectGameType';
import { GAME_TYPES_TYPE, HiveSize, HiveSubscriptionType } from '../constants/constants';
import { useGetCurrentUserData } from '../hooks/user';
import { envs } from '../../env';
import { ServerRedirect } from '../components/screens/ServerRedirect/ServerRedirect';
import { MB_FirebaseMessaging } from '../mightyByteLibraries/MB_FirebaseMessaging/MB_FirebaseMessaging';
import { useAddFirebaseDeviceToken } from '../hooks/addFirebaseDeviceToken';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, TMB_EventSubscription } from '@mightybyte/rnw.utils.event-emmiter/dist/MB_EventEmmiter';
import { WaitingForHiveMate } from '../components/screens/WaitingForHiveMate/WaitingForHiveMate';
import { isMobileApp, isMobile } from '@mightybyte/rnw.utils.device-info';
import { InviteToGame } from '../components/helperComponents/InviteToGame/InviteToGame';
import { PassAndPlay } from '../components/screens/PassAndPlay/PassAndPlay';
import { ContactUs } from '../components/screens/ContactUs/ContactUs';
import { AdminNavigator } from './AdminNavigator/AdminNavigator';
import { WebOnly } from '../components/screens/WebOnly';
import { utils } from '../utils/utils';
import { Header } from './Header';
import { Subscriptions } from '../components/screens/Subscriptions/Subscriptions';

export type RootStackParamList = {
  // Guest routes:
  ChooseUserType: undefined | { userType: USER_TYPES };
  VerifyEmail: { firstName: string; lastName: string; password: string; email: string };
  SignUp: { userType: USER_TYPES };
  SignIn: undefined;
  RequestForgetPasswordCode: undefined;
  VerifyForgetPasswordCode: { email: string };
  ResetPassword: { email: string, code: string };
  ResetPasswordSuccessful: undefined;
  // Private routes:
  MyHives: undefined | { shouldRefetch: boolean };
  MyHiveData: { id: string };
  MyHiveDataStudent: { id: string, name: string, size: HiveSize };
  SelectHiveSize?: { size: HiveSize };
  CreateHive: { size: HiveSize, subscriptionType: HiveSubscriptionType };
  OrderSummary: { id: string, name: string, size: HiveSize };
  JoinHive: undefined;
  WaitingForHiveMate: { userName: string; gameType: GAME_TYPES_TYPE, inviteeUserId: string, inviteeUsername: string, hiveDetail: { name: string, id: string, size: HiveSize } };
  AdminNavigator: undefined;
  // Global routes
  Lobby: undefined | {playAFriend?: boolean};
  SelectGameType: { userName?: string; next: 'PlayOnline' | 'HostGame' | 'PlayWithAI' | 'WaitingForHiveMate' | 'PassAndPlay', inviteeId?: string, inviteeName?: string, hiveDetail?: { name: string, id: string, size: HiveSize } };
  ServerRedirect: { success?: string; error?: string } | undefined
  PlayOnline: { userName?: string; isPlayAgain?: boolean, gameType: GAME_TYPES_TYPE };
  PlayWithAI: { userName?: string; isPlayAgain?: boolean; difficulty?: AIDifficultyLevel, gameType: GAME_TYPES_TYPE };
  HostGame: { userName?: string; gameType: GAME_TYPES_TYPE };
  JoinGame: { userName?: string };
  JoinCode: { joinCode: string; userName: string };
  PassAndPlay: { gameType: GAME_TYPES_TYPE };
  Game: { game: GameType; mode: GameMode; difficulty?: AIDifficultyLevel, userId?: string };
  Splash: undefined;
  WebOnly: undefined;
  ContactUs: undefined;
  Subscriptions: undefined;
};
const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
  return navRef.isReady() ? navRef : undefined;
};

const config: LinkingOptions<RootStackParamList>['config'] = {
  screens: {
    ChooseUserType: 'chooseUserType',
    VerifyEmail: 'verifyEmail',
    SignUp: 'signUp',
    SignIn: 'signIn',
    RequestForgetPasswordCode: 'requestForgetPasswordCode',
    VerifyForgetPasswordCode: 'verifyForgetPasswordCode',
    ResetPassword: 'resetPassword',
    ResetPasswordSuccessful: 'resetPasswordSuccessful',
    MyHives: 'myHives',
    MyHiveData: 'myHiveData',
    MyHiveDataStudent: 'myHiveDataStudent',
    CreateHive: 'createHive',
    SelectGameType: 'SelectGameType',
    SelectHiveSize: 'selectHiveSize',
    OrderSummary: 'orderSummary',
    JoinHive: 'joinHive',
    WaitingForHiveMate: 'waitingForHiveMate',
    Lobby: 'lobby',
    PlayOnline: 'playOnline',
    ServerRedirect: 'serverRedirect',
    HostGame: 'hostGame',
    JoinGame: 'joinGame',
    PlayWithAI: 'playWithAI',
    JoinCode: 'joinCode',
    PassAndPlay: 'passAndPlay',
    Game: {
      path: 'game',
      stringify: isMobileApp ? undefined : {
        game: utils.encodeParam,
      },
    },
    Splash: 'splash',
    ContactUs: 'contactUs',
    Subscriptions: 'subscriptions',
    AdminNavigator: {
      path: 'admin',
      screens: {
        Teachers: 'teachers',
        Students: 'students',

      },
    },
    WebOnly: 'webOnly',
  },
};

const linking = {
  prefixes: [
    envs.WEBSITE_BASE_URL,
    envs.MOBILE_DEEP_LINK,
  ],
  config,
  getStateFromPath: isMobileApp ? undefined : (path: string, options: any) => path.includes('/serverRedirect') ? getStateFromPath(path, options) : utils.decodeParams(getStateFromPath(path, options)),
};

const RootNavigation = () => {
  useGetCurrentUserData();
  const [showSplash, setShowSplash] = useState(true);
  const [isVersionChecked, setVersionChecked] = useState(Platform.OS === 'web');
  const { isSignedIn, signedInStatus, setSignedInStatus, user } = useSignedInContext();

  const mobileNotificationSubscription = useRef<TMB_EventSubscription>();
  const [isReady, setReady] = useState(false);
  const [updatePushToken] = useAddFirebaseDeviceToken();
  const [invitedGameDetail, setInvitedGameDetail] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 2000);
  }, []);

  // Initial check for sign in status based on stored token.
  useEffect(() => {
    if (signedInStatus === SIGNED_IN_STATUS.loading && isVersionChecked) {
      MB_accessTokenUtils.getAccessToken()
        .then((accessToken) => {
          if (accessToken) {
            setSignedInStatus(SIGNED_IN_STATUS.signedIn);
          } else {
            setSignedInStatus(SIGNED_IN_STATUS.signedOut);
          }
        });
    }
  }, [isReady, isVersionChecked, setSignedInStatus, signedInStatus]);

  useEffect(() => {
    if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
      mobileNotificationSubscription.current = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, (event) => {
        if (event.origin !== envs.WEBSITE_BASE_URL || event.data.message !== 'NOTIFICATION_UPDATE') {
          return;
        }
        const detail = event.data.payload.data;
        setInvitedGameDetail(detail);
      });
    } else if (signedInStatus === SIGNED_IN_STATUS.signedOut) {
      if (mobileNotificationSubscription.current) {
        mobileNotificationSubscription.current.remove();
        mobileNotificationSubscription.current = undefined;
      }
    }
  }, [signedInStatus]);

  useEffect(() => {
    if (isMobileApp) {
      const unSubscribe = MB_FirebaseMessaging.onNotificationPressed(event => {
        if (event.data) {
          setTimeout(() => {
            const detail = event.data;
            setInvitedGameDetail(detail);
          }, 1000);
        }
      });
      return unSubscribe;
    }
  }, []);


  useEffect(() => {
    if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
      const registerFirebaseToken = async () => {
        try {
          const token = await MB_FirebaseMessaging.getToken();

          updatePushToken({ variables: { token } }).then(() => {
            console.log('Success adding the firebase device token');
          }).catch((error) => {
            console.warn('Error when trying to add firebase device token', error);
          });
        } catch (error) {
          console.log('Failure to register device token', error);
        }
      };
      registerFirebaseToken();
    }
  }, [signedInStatus, updatePushToken]);

  useEffect(() => {
    setReady(true);
  }, [isSignedIn]);

  const renderGuestRoutes = (): ReactNode => {
    if (!isSignedIn) {
      return <>
        <Stack.Screen name="ChooseUserType" component={ChooseUserType} />
        <Stack.Screen name="VerifyEmail" component={VerifyEmail} />
        <Stack.Screen name="SignIn" component={SignIn} />
        <Stack.Screen name="SignUp" component={SignUp} />
        <Stack.Screen name="RequestForgetPasswordCode" component={RequestForgetPasswordCode} />
        <Stack.Screen name="VerifyForgetPasswordCode" component={VerifyForgetPasswordCode} />
        <Stack.Screen name="ResetPassword" component={ResetPassword} />
        <Stack.Screen name="ResetPasswordSuccessful" component={ResetPasswordSuccessful} />
      </>;
    }
    return null;
  };
  const renderPrivateRoutes = (): ReactNode => {
    if (isSignedIn) {
      return <>
        <Stack.Screen name="MyHives" component={MyHives} />
        <Stack.Screen name="JoinHive" component={JoinHive} />
        <Stack.Screen name="CreateHive" component={CreateHive} />
        <Stack.Screen name="SelectHiveSize" component={SelectHiveSize} />
        <Stack.Screen name="OrderSummary" component={OrderSummary} />
        <Stack.Screen name="MyHiveData" component={MyHiveData} />
        <Stack.Screen name="MyHiveDataStudent" component={MyHiveDataStudent} />
        <Stack.Screen name="WaitingForHiveMate" component={WaitingForHiveMate} />
      </>;
    }
    return null;
  };

  const renderMainRoutes = (): ReactNode => {
    return (
      <NavigationContainer ref={navRef} linking={linking}>
        <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitle: '', title: 'Number Hive', header: (props) => <Header route={props.route} /> }}>
          <Stack.Screen name="Lobby" component={Lobby} />
          <Stack.Screen name="SelectGameType" component={SelectGameType} />
          <Stack.Screen name="PlayOnline" component={PlayOnline} />
          <Stack.Screen name="HostGame" component={HostGame} />
          <Stack.Screen name="JoinGame" component={JoinGame} />
          <Stack.Screen name="PlayWithAI" component={PlayWithAI} />
          <Stack.Screen name="JoinCode" component={JoinCode} />
          <Stack.Screen name="PassAndPlay" component={PassAndPlay} />
          <Stack.Screen name="Game" component={Game} />
          <Stack.Screen name={'ContactUs'} component={ContactUs} />
          <Stack.Screen name="Subscriptions" component={Subscriptions} />
          {renderGuestRoutes()}
          {renderPrivateRoutes()}
          <Stack.Screen name="ServerRedirect" component={ServerRedirect} />
        </Stack.Navigator>
        {
          invitedGameDetail &&
          <InviteToGame
            detail={invitedGameDetail}
            onYesPress={() => setInvitedGameDetail(undefined)}
            onNoPress={() => setInvitedGameDetail(undefined)}
          />
        }
      </NavigationContainer>
    );
  };

  const renderSplash = (): ReactNode => {
    return (
      <>
        <ForceUpdate setVersionChecked={setVersionChecked} />
        <NavigationContainer>
          <Stack.Navigator screenOptions={{ headerShown: false, headerBackTitle: '', title: 'Number Hive' }}>
            <Stack.Screen name="Splash" component={Splash} />
          </Stack.Navigator>
        </NavigationContainer>
      </>
    );
  };

  const renderAdminRoutes = (): ReactNode => {
    return (
      <NavigationContainer ref={navRef} linking={linking}>
        <Stack.Navigator screenOptions={{ headerShown: false, headerBackTitle: '', title: 'Number Hive' }}>
          {isMobile ? <Stack.Screen name="WebOnly" component={WebOnly} /> : <Stack.Screen name="AdminNavigator" component={AdminNavigator} />}
        </Stack.Navigator>
      </NavigationContainer>
    );
  };

  const renderRoutes = () => {
    if (showSplash || !isVersionChecked) {
      return <>{renderSplash()}</>;
    }
    if (isSignedIn && user?.userType === USER_TYPES.admin) {
      return <>{renderAdminRoutes()}</>;
    }
    return <>{renderMainRoutes()}</>;
  };

  return renderRoutes();
};

export default RootNavigation;
