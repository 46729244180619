import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation leaveWaitingHostGame($joinCode: String!) {
    leaveWaitingHostGame(joinCode: $joinCode)
  }
`;

export interface RequestType {
  joinCode: string;
}

export interface MutationType {
  leaveWaitingHostGame: boolean;
}

export function useLeaveWaitingHostGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
