import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const RightGreenArrow = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg width={68} height={33} viewBox="0 0 68 33" fill="none" {...props}>
          <G>
            <Path
              d="M46.49 0c.126 0 .293.042.377.125l18.882 14.793c.168.125.251.334.251.543 0 .209-.125.376-.292.502L45.53 28.916c-.084.084-.209.084-.334.084a.603.603 0 01-.293-.084c-.209-.125-.334-.334-.292-.585l.25-5.557-42.318-7.146A.633.633 0 012 15.001c0-.292.209-.585.543-.626l43.029-7.23.292-6.518c0-.25.167-.46.376-.543.084-.042.167-.084.25-.084z"
              fill="url(#paint0_linear_46_3529)"
            />
            <Path
              d="M46.515.506a.383.383 0 01.006-.003.223.223 0 01.024.006l.014.01L65.44 15.312h0l.008.006c.008.006.02.018.031.043a.25.25 0 01.02.103.064.064 0 01-.008.014.316.316 0 01-.072.075L45.26 28.496l-.006.004H45.193s0 0 0 0l-.02-.008a.128.128 0 01-.068-.054.02.02 0 01-.002-.006l.001-.018.005-.03.002-.03.25-5.558.02-.442-.436-.073-42.319-7.146h0l-.012-.002a.139.139 0 01-.081-.044.125.125 0 01-.033-.088.15.15 0 01.033-.09.105.105 0 01.072-.04l.01-.002.01-.001 43.03-7.23.398-.066.018-.404.293-6.519V.627a.07.07 0 01.015-.042.112.112 0 01.047-.037l.02-.008.018-.01.051-.024z"
              stroke="#fff"
            />
          </G>
          <Defs>
            <LinearGradient
              id="paint0_linear_46_3529"
              x1={34}
              y1={29}
              x2={34}
              y2={0.00000292452}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#90DF75" />
              <Stop offset={1} stopColor="#62B655" />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { RightGreenArrow };
