import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../constants/colors';
import { LeftGreenArrow } from '../../resources/svgComponents/LeftGreenArrow';
import { RightGreenArrow } from '../../resources/svgComponents/RightGreenArrow';
import { XIcon } from '../../resources/svgComponents/XIcon';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { Calculation } from './Calculation';
import CircularProgress from './Circular';
import { Divider } from './Divider';
import { LargeSlider } from './LargeSlider';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE, OPERAND_TYPE } from '../../constants/constants';
import { PlusIcon } from '../../resources/svgComponents/PlusIcon';

const colors1 = ['#FFCF53', '#FF9900'];
const colors3 = ['#FF9252', '#FF8469'];

interface IControlsProps {
  firstSlider: OPERAND_TYPE;
  secondSlider: OPERAND_TYPE;
  turn: string;
  currentUserId: string;
  currentPlayerIndex: number;
  changedSliderIndex: null | number;
  handleSliderChange: (sliderIdx: number, leftOperand: OPERAND_TYPE, rightOperand: OPERAND_TYPE) => void;
  setExitPopupOpen: () => void;
  time: number;
  hideTimer?: boolean;
  showHands?: boolean;
  gameType: GAME_TYPES_TYPE;
  isWaitingTiles: boolean
}

export const Controls = React.memo((props: IControlsProps) => {
  const screenSize = useScreenSize();
  const { turn, firstSlider, secondSlider, currentUserId, handleSliderChange } = props;
  const isLarge = screenSize === SCREEN_SIZES.LARGE;
  const isSmall = screenSize === SCREEN_SIZES.SMALL;

  const onChangeFirstSlider = useCallback(
    (value: OPERAND_TYPE) => {
      if (turn === currentUserId) {
        handleSliderChange(0, value, secondSlider);
      }
    },
    [currentUserId, handleSliderChange, secondSlider, turn],
  );

  const onChangeSecondSlider = useCallback(
    (value: OPERAND_TYPE) => {
      if (turn === currentUserId) {
        handleSliderChange(1, firstSlider, value);
      }
    },
    [currentUserId, firstSlider, handleSliderChange, turn],
  );

  if (isLarge) {
    return (
      <View style={{ position: 'relative' }}>
        <View pointerEvents={props.isWaitingTiles ? 'auto' : 'none'} style={[styles.waitingContainer, { opacity: props.isWaitingTiles ? 1 : 0 }]}>
          <Text style={styles.waitingLarge}>Select one of the numbers highlighted above</Text>
        </View>
        <View pointerEvents={props.isWaitingTiles ? 'none' : 'auto'} style={[styles.webContainer, { opacity: props.isWaitingTiles ? 0 : 1 }]}>
          <Text style={styles.description}>{GAME_TYPES_CONFIG[props.gameType].howToWin}</Text>
          <View style={[styles.container]}>
            <LargeSlider
              colors={props.changedSliderIndex === 0 ? colors1 : colors3}
              value={props.firstSlider}
              setValue={onChangeFirstSlider}
              gameType={props.gameType}
            />
            <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              {props.hideTimer ? null : (
                <View style={{ paddingBottom: 20 }}>
                  <CircularProgress progress={props.time} size={60} fontSize={20} />
                </View>
              )}
              <View
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end' }}
              >
                <LeftGreenArrow containerStyle={{ marginHorizontal: 14, marginBottom: 10 }} />
                <Calculation
                  first={props.firstSlider.a}
                  second={props.secondSlider.a}
                  isSmallDevice={false}
                  isLargeDevice
                  changedSliderIndex={props.changedSliderIndex}
                  gameType={props.gameType}
                />
                <RightGreenArrow containerStyle={{ marginHorizontal: 14, marginBottom: 10 }} />
              </View>
            </View>
            <LargeSlider
              colors={props.changedSliderIndex === 1 ? colors1 : colors3}
              value={props.secondSlider}
              setValue={onChangeSecondSlider}
              gameType={props.gameType}
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <View pointerEvents={props.isWaitingTiles ? 'auto' : 'none'} style={[styles.waitingContainer, { opacity: props.isWaitingTiles ? 1 : 0 }]}>
        <Text style={styles.waiting}>Select one of the numbers highlighted above</Text>
      </View>
      <View pointerEvents={props.isWaitingTiles ? 'none' : 'auto'} style={[styles.mobileContainer, { opacity: props.isWaitingTiles ? 0 : 1 }]}>
        <Divider height={isSmall ? 8 : 10} empty />
        <Text style={styles.description}>{GAME_TYPES_CONFIG[props.gameType].howToWin}</Text>
        <Divider height={isSmall ? 8 : 10} empty />
        <View style={styles.mobileRow}>
          <View style={styles.leftSlider}>
            <LargeSlider
              colors={props.changedSliderIndex === 0 ? colors1 : colors3}
              value={props.firstSlider}
              setValue={onChangeFirstSlider}
              gameType={props.gameType}
            />
          </View>
          {
            props.gameType === GAME_TYPES_TYPE.ADD_1_12 || props.gameType === GAME_TYPES_TYPE.ADD_MINUS_6_6 ?
              <PlusIcon containerStyle={{ padding: 8, paddingTop: 20 }} size={20} />
              :
              <XIcon containerStyle={{ padding: 8, paddingTop: 20 }} width={20} height={20} />
          }
          <View style={styles.rightSlider}>
            <LargeSlider
              colors={props.changedSliderIndex === 1 ? colors1 : colors3}
              value={props.secondSlider}
              setValue={onChangeSecondSlider}
              gameType={props.gameType}
            />
          </View>
        </View>
      </View>
    </View>
  );
});

Controls.displayName = 'Controls';

const styles = StyleSheet.create({
  mobileContainer: {
    alignItems: 'center',
    position: 'relative',
  },
  webContainer: {
    width: '100%',
    paddingTop: 20,
    alignItems: 'center',
    position: 'relative',
  },
  webMediumContainer: {
    width: '100%',
    paddingTop: 10,
    alignItems: 'center',
  },
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingTop: 20,
    paddingBottom: 12,
  },
  mediumContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 720,
  },
  gameInfo: {
    height: 36,
    width: 148,
    borderColor: applyTransparency('#FFFFFF', 0.4),
    borderWidth: 3,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  infoText: {
    fontSize: 12,
    fontFamily: 'Secular One',
    color: 'white',
  },
  infoContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  exitButton: {
    borderWidth: 3,
  },
  exitIcon: {
    marginTop: 4,
    marginRight: 2,
  },
  leftSlider: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  rightSlider: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  arrows: {
    marginHorizontal: 14,
    marginBottom: 10,
  },
  topHandContainer: {
    position: 'absolute',
    right: 10,
    top: -125,
    zIndex: 10,
  },
  topHand: {
    width: 120,
  },
  bottomHandContainer: {
    position: 'absolute',
    left: 100,
    top: 30,
    zIndex: 10,
  },
  bottomHand: {
    width: 80,
    height: 60,
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    fontSize: 20,
    color: '#1E1E1E',
  },
  waitingLarge: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    fontSize: 20,
    color: '#1E1E1E',
    textAlign: 'center',
  },
  waiting: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    fontSize: 14,
    color: '#1E1E1E',
    textAlign: 'center',
  },
  waitingContainer: {
    width: '100%',
    height: '100%',
    left: 0,
    position: 'absolute',
    zIndex: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
