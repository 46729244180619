import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { HiveSize, PaymentStatus } from '../../../constants/constants';

const query = gql`
  query hives {
    hives {
      id
      name
      size
      code
      seatTaken
      numberOfSeats
      paymentStatus
    }
  }
`;

export interface HiveType {
  id: string;
  name: string;
  size: HiveSize;
  code: string;
  seatTaken: number;
  numberOfSeats: number;
  paymentStatus: PaymentStatus
}

export interface QueryType {
  hives: HiveType[];
}

export function useGetHivesQuery(options?: QueryHookOptions<QueryType>) {
  return useQuery<QueryType>(query, options);
}
