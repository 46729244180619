import React, { useCallback, useEffect, useState } from 'react';
import { Image, Keyboard, Platform, StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { RANDOM_NAMES } from '../../../constants/names';
import { useGraphQL } from '../../../graphql';
import BeeIcon from '../../../resources/images/bee3.png';
import { VSIcon } from '../../../resources/svgComponents/VsIcon';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { SCREEN_SIZES, useScreenSize } from '../../../utils/dimsHooks';
import { utils } from '../../../utils/utils';
import { BackButton } from '../../helperComponents/BackButton';
import CircularProgress from '../../helperComponents/Circular';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { Popup } from '../../helperComponents/Popup';
import { ProfileName } from '../../helperComponents/ProfileName';
import { GameMode } from '../GameScreen';
import { AIDifficultyLevel, useJoinAIGameMutation } from '../PlayWithAI/useJoinAiGameMutation.gql';
import {
  EventTypeGameEventType,
  GameStatus,
  GameType,
  useGameEventSubscription,
} from './useGameEventSubscription.gql';
import { useJoinOnlineGameMutation } from './useJoinOnlineGameMutation.gql';
import { useLeaveWaitingOnlineGameMutation } from './useLeaveWaitingOnlineGameMutation.gql';
import { ApolloError } from '@apollo/client';
import { useSignedInContext } from '../../../context/SignedInContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getErrorText } from '../../../utils/errors';

const vsImage = require('../../../resources/images/vsImage.png');
let interval: any = null;

const PlayOnline = ({ navigation, route }: ScreenProps<'PlayOnline'>) => {
  const { user: currentUser } = useSignedInContext();
  const { currentUserId: userId } = useGraphQL();
  const currentUserId = currentUser ? currentUser.id : userId;

  const gameType = route.params.gameType;
  const screenSize = useScreenSize();
  const [name, setName] = useState(route.params?.userName || '');
  const [isWaitingModalVisible, setIsWaitingModalVisible] = useState(false);
  const [isTimerEnded, setTimerEnded] = useState(false);
  const [randomTime, setRandomTimer] = useState(Math.floor(Math.random() * 20 + 20));
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const [joinGame, { loading, error, called: joinedGame }] = useJoinOnlineGameMutation({
    variables: { userName: name, gameType: gameType },
  });
  const [joinGameAI, { loading: loadingAI, error: errorAI, called: joinedGameAI }] =
    useJoinAIGameMutation({
      variables: {
        userName: name,
        difficultyLevel: [AIDifficultyLevel.MEDIUM, AIDifficultyLevel.HARD][
          Math.random() < 0.5 ? 0 : 1
        ],
        aiName: RANDOM_NAMES[Math.floor(Math.random() * RANDOM_NAMES.length)],
        gameType,
      },
    });
  const [leaveWaitingGame] = useLeaveWaitingOnlineGameMutation();
  const { data: gameEventSubscription } = useGameEventSubscription();
  const [timer, setTimer] = useState(0);

  const isLarge = screenSize === SCREEN_SIZES.LARGE;

  const restartTimer = () => {
    setTimer(0);
    setRandomTimer(Math.floor(Math.random() * 20 + 20));
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setTimer(value => value + 1);
    }, 1000);
  };

  const handleJoinGameError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleJoinGame = useCallback(() => {
    joinGame().then(() => {
      setIsWaitingModalVisible(true);
    }).catch((err) => {
      handleJoinGameError(err);
    });
  }, [joinGame]);

  const onNext = useCallback(async (playerName: string) => {
    AsyncStorage.setItem('playing-name', playerName);
    Keyboard.dismiss();
    handleJoinGame();
  }, [handleJoinGame]);

  useEffect(() => {
    if (route.params.userName && route.params.isPlayAgain) {
      onNext(route.params.userName);
    }
  }, [onNext, route.params.userName, route.params.isPlayAgain]);

  const navigateToGameScreen = useCallback((game: GameType) => {
    setIsWaitingModalVisible(false);
    navigation.replace('Game', {
      game,
      mode: GameMode.PLAY_ONLINE,
    }); // todo: pass game initial state
  }, [navigation]);

  const onPopupVisibilityChange = useCallback(
    (visible: boolean) => {
      setIsWaitingModalVisible(visible);

      if (!visible && (joinedGame || joinedGameAI)) {
        leaveWaitingGame();
        clearInterval(interval);
      }
    },
    [joinedGameAI, joinedGame, leaveWaitingGame],
  );

  const handlePracticePress = useCallback(() => {
    leaveWaitingGame();
    setIsWaitingModalVisible(false);
    setTimeout(() => {
      navigation.replace('PlayWithAI', {
        userName: name,
        gameType,
      });
    }, 500);
  }, [leaveWaitingGame, name, navigation, gameType]);

  useEffect(() => {
    if (gameEventSubscription && gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED) {
      clearInterval(interval);
      return;
    }
    if (timer > randomTime) {
      clearInterval(interval);
      joinGameAI();
      return;
    }
    if (timer > 59) {
      setTimerEnded(true);
      clearInterval(interval);
      return;
    }
  }, [timer, randomTime, gameEventSubscription, joinGameAI]);

  useEffect(() => {
    if (isWaitingModalVisible) {
      restartTimer();
    }
  }, [isWaitingModalVisible]);

  useEffect(() => {
    if (
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START &&
      gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED
    ) {
      // add some artificial delay to show another user's name and good animation before game starts
      clearInterval(interval);
      setTimeout(() => navigateToGameScreen(gameEventSubscription.gameEvent.game), 1000);
    }
  }, [gameEventSubscription, navigateToGameScreen, navigation]);

  useEffect(() => {
    if (error) {
      setIsWaitingModalVisible(false);
      handleJoinGameError(error);
    } else if (errorAI) {
      setIsWaitingModalVisible(false);
      handleJoinGameError(errorAI);
    }
  }, [error, errorAI]);

  const anotherPlayerName = gameEventSubscription?.gameEvent.game.status !== GameStatus.FINISHED
    ? gameEventSubscription?.gameEvent.game.users.find(player => player.id !== currentUserId)?.name
    : undefined;

  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false} style={{ paddingHorizontal: 0 }}>
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            Join A Game
          </Text>
          <Divider empty height={36} />
          <Input
            textContentType="name"
            placeholder="Name"
            value={name}
            setValue={setName}
            onSubmitEditing={() => onNext(name)}
          />
          <Divider empty height={28} />
          <MenuButton
            onPress={() => onNext(name)}
            disabled={!name || loading || loadingAI}
            colors={['#90DF75', '#62B655']}
            text="Next"
            height={isLarge ? 74 : 64}
            width={isLarge ? 275 : 240}
          />
          <Divider empty height={24} />
          <BackButton text="Back  To Lobby" onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} />
        </ComponentWrapper>
      </DismissKeyboard>
      <Popup
        title="Waiting..."
        visible={isWaitingModalVisible}
        setVisible={onPopupVisibilityChange}
        height={384}
      >
        <View style={styles.popupContainer}>
          <Divider empty height={32} />
          <View style={styles.popupInnerContainer}>
            <ProfileName gradientColors={['#FFCF53', '#FF9900']} name={name} />
            {Platform.OS === 'web' ? (
              <Image
                source={vsImage}
                style={{ width: 54, height: 54, marginTop: 20 }}
                resizeMode="contain"
              />
            ) : (
              <VSIcon containerStyle={styles.vsIcon} />
            )}
            <ProfileName gradientColors={['#FF9252', '#FF3F15']} name={anotherPlayerName || '?'} />
          </View>
          <Divider empty height={20} />
          <CircularProgress progress={60 - timer} size={80} fontSize={20} />
          <Divider empty height={16} />
          {isTimerEnded ? (
            <MenuButton
              onPress={handlePracticePress}
              colors={['#747474', '#1F1F1F']}
              text="Practice"
              textStyle={{ fontSize: 16 }}
              image={BeeIcon}
              height={60}
              width={226}
            />
          ) : (
            <Text maxFontSizeMultiplier={1} style={styles.waiting}>
              Waiting for friends...
            </Text>
          )}
        </View>
      </Popup>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { PlayOnline };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  popupContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  popupInnerContainer: {
    width: '100%',
    // maxWidth: 290,
    padding: 12,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  waiting: {
    fontSize: 20,
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
    color: '#000000',
  },
  vsIcon: {
    ...utils.getShadow({
      color: '#000000',
      offsetHeight: 2,
      offsetWidth: 0,
      radius: 4,
      opacity: 0.2,
      elevation: 0,
    }),
  },
});
