import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation inviteToGame($userName: String!, $gameType: GameType!, $inviteeUserId: String!, $hiveName: String!) {
    inviteToGame(userName: $userName, gameType: $gameType, inviteeUserId: $inviteeUserId, hiveName: $hiveName) {
      game {
        id
        joinCode
      }
    }
  }
`;

export interface RequestType {
  userName: string;
  gameType: GAME_TYPES_TYPE;
  inviteeUserId: string;
  hiveName: string;
}

export interface MutationType {
  inviteToGame: InviteToGameOnlineGameResponseType;
}

export interface InviteToGameOnlineGameResponseType {
  game: GameType;
}

export interface GameType {
  id: string;
  joinCode?: string;
}

export function useInviteToGameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
