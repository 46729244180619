import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const UserTwoIcon = React.memo(({size, containerStyle, color, filled }: {
    size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string,
    filled?: boolean,
}) => {
    const iconColor = color ?? '#FFFFFF';
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 18}
                height={size ? size : 18}
                viewBox="0 0 18 18"
                fill={filled ? iconColor : 'none'}
            >
                <Path
                    clipRule="evenodd"
                    d="M6.792 11.606c3.381 0 6.272.512 6.272 2.559s-2.871 2.574-6.272 2.574c-3.383 0-6.272-.508-6.272-2.556 0-2.047 2.87-2.577 6.272-2.577zM6.792 8.684a4.02 4.02 0 114.02-4.02 4.006 4.006 0 01-3.992 4.02h-.028z"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M13.11 7.64a3.026 3.026 0 002.6-2.99 3.026 3.026 0 00-2.532-2.984M15.045 11.17c1.422.212 2.415.711 2.415 1.738 0 .707-.468 1.165-1.223 1.452"
                    stroke={iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export default UserTwoIcon;
