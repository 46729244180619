import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation resetPassword($email: String!, $resetPasswordCode: String!, $password: String!) {
    resetPassword(email: $email, resetPasswordCode: $resetPasswordCode, password: $password) {
      accessToken {
        token
        expiresIn
        expiresInMs
        expiresAt
        expiresAtMs
      }
      refreshToken
    }
  }
`;

export interface RequestType {
  email: string;
  resetPasswordCode: string;
  password: string;
}

export interface MutationType {
  resetPassword: ResetPasswordResponseType;
}

export interface ResetPasswordResponseType {
  accessToken: {
    token: string;
    expiresIn: string;
    expiresInMs: number;
    expiresAt: string;
    expiresAtMs: number;
    __typename?: string;
  };
  refreshToken: string;
  __typename?: 'ResetPasswordResponse';
}

export function useResetPasswordMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
