import * as React from 'react';
import Svg, { Circle, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const CheckMarkIcon = ({ size = 13, colors = ['#90DF75', '#62B655'] }: { size?: number; colors?: [string, string] }) => {

  const linearGradientId = React.useMemo(() => `${colors[0]}-${colors[1]}-${Date.now()}`, [colors]);

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
    >
      <Circle
        cx={6.73305}
        cy={6.20571}
        r={6.06117}
        fill={`url(#${linearGradientId})`}
      />
      <Path
        d="M4.195 6.565l1.688 1.688 3.375-3.616"
        stroke="#fff"
        strokeWidth={0.826524}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs>
        <LinearGradient
          id={linearGradientId}
          x1={6.73305}
          y1={0.144531}
          x2={6.73305}
          y2={12.2669}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={colors[0]} />
          <Stop offset={1} stopColor={colors[1]} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export { CheckMarkIcon };
