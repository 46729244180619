import React, { useEffect, useRef } from 'react';
import { Animated, Dimensions, StyleSheet, View } from 'react-native';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

const hiveImage = require('../../resources/images/hiveIcon.png');
const topSplashImage = require('../../resources/images/topSplash.png');
const bottomSplashImage = require('../../resources/images/bottomSplash.png');

const largeTopSplashImage = require('../../resources/images/largeTopSplash.png');
const largeBottomSplashImage = require('../../resources/images/largeBottomSplash.png');

const Splash = () => {
  const { width, height } = Dimensions.get('window');
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const topPosAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const bottomPosAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  useEffect(() => {
    Animated.parallel([
      Animated.timing(topPosAnim, {
        toValue: -150,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.timing(bottomPosAnim, {
        toValue: 100,
        duration: 1000,
        useNativeDriver: true,
      }),
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
        delay: 500,
      }),
    ]).start();
  }, [bottomPosAnim, fadeAnim, topPosAnim]);

  return (
    <>
      <View style={styles.container}>
        <Animated.Image
          style={{
            width: width,
            height: height * 0.6,
            marginTop: -0.05 * height,
            transform: [{ translateY: topPosAnim }],
          }}
          resizeMode={'cover'}
          source={!isMobile ? largeTopSplashImage : topSplashImage}
        />
        <Animated.Image
          style={{
            width: width,
            height: height * 0.6,
            marginTop: -0.05 * height,
            transform: [{ translateY: bottomPosAnim }],
          }}
          resizeMode={'cover'}
          source={!isMobile ? largeBottomSplashImage : bottomSplashImage}
        />
        <Animated.Image
          style={{
            width: 200,
            height: 120,
            position: 'absolute',
            top: '50%',
            transform: [{ translateY: -60 }],
            zIndex: -1,
            opacity: fadeAnim,
          }}
          resizeMode="contain"
          source={hiveImage}
        />
      </View>
      {/* <ComponentWrapper hasInset={false} hasScroll={false} hasBackground={false}>
      </ComponentWrapper> */}
    </>
  );
};

export { Splash };

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    // justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  image: { width: 180, height: 100 },
});
