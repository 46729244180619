import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, Text, View } from 'react-native';
import { applyTransparency } from '../../../constants/colors';
import { ScreenProps } from '../../../typesInterfacesEnums/componentProps';
import { BackButton } from '../../helperComponents/BackButton';
import { ComponentWrapper } from '../../helperComponents/ComponentWrapper';
import { DismissKeyboard } from '../../helperComponents/DismissKeyboard';
import { Divider } from '../../helperComponents/Divider';
import { ErrorPopup } from '../../helperComponents/ErrorPopup';
import { Input } from '../../helperComponents/Input';
import { MenuButton } from '../../helperComponents/MenuButton';
import { Popup } from '../../helperComponents/Popup';
import { Radio } from '../../helperComponents/Radio';
import { GameMode } from '../GameScreen';
import { EventTypeGameEventType, GameStatus, useGameEventSubscription } from './useGameEventSubscription.gql';
import { AIDifficultyLevel, useJoinAIGameMutation } from './useJoinAiGameMutation.gql';
import { ApolloError } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getErrorText } from '../../../utils/errors';

const PlayWithAI = ({ navigation, route }: ScreenProps<'PlayWithAI'>) => {
  const gameType = route.params.gameType;
  const [name, setName] = useState(route.params?.userName || '');
  const [isModalVisible, setModalVisible] = useState(false);
  const [gameDifficultyLevel, setGameDifficultyLevel] = useState(
    route.params?.difficulty || AIDifficultyLevel.EASY,
  );
  const [hasError, setHasError] = useState({
    visible: false,
    popup: false,
    text: '',
  });

  const [joinGame, { loading, error }] = useJoinAIGameMutation({
    variables: { userName: name, difficultyLevel: gameDifficultyLevel, aiName: '', gameType: gameType },
  });
  const { data: gameEventSubscription } = useGameEventSubscription({ onSubscriptionComplete: () => console.log('PlayWithAI completed!') });

  useEffect(() => {
    if (
      gameEventSubscription?.gameEvent.eventType === EventTypeGameEventType.ON_GAME_START &&
      gameEventSubscription.gameEvent.game.status !== GameStatus.FINISHED
    ) {
      setModalVisible(false);
      navigation.replace('Game', {
        game: gameEventSubscription.gameEvent.game,
        mode: GameMode.PRACTICE,
        difficulty: gameDifficultyLevel,
      });
    }
    if (gameEventSubscription?.gameEvent && gameEventSubscription.gameEvent.game.status === GameStatus.FINISHED) {
      console.warn('finished game ignored!');
    }
  }, [gameDifficultyLevel, gameEventSubscription?.gameEvent, navigation]);

  const handleJoinGameError = (err: ApolloError) => {
    setHasError({ popup: true, visible: true, text: getErrorText(err) });
  };

  const handleJoinGame = () => {
    AsyncStorage.setItem('playing-name', name);
    joinGame().catch((err) => {
      handleJoinGameError(err);
    });
  };

  useEffect(() => {
    if (error) {
      setModalVisible(false);
      handleJoinGameError(error);
    }
  }, [error]);

  useEffect(() => {
    if (route?.params?.userName && route.params.isPlayAgain) {
      setName(route.params.userName);
    }
  }, [route?.params?.userName, route?.params?.isPlayAgain, joinGame]);

  const onNext = () => {
    Keyboard.dismiss();
    setModalVisible(true);
  };
  return (
    <>
      <DismissKeyboard>
        <ComponentWrapper hasInset hasScroll={false}>
          <Text maxFontSizeMultiplier={1} style={styles.title}>
            Practice
          </Text>
          <Text maxFontSizeMultiplier={1} style={styles.subtitle}>
            Please enter your Name
          </Text>
          <Divider empty height={36} />
          <Input
            textContentType="name"
            placeholder="Name"
            value={name}
            setValue={setName}
            onSubmitEditing={onNext}
          />
          <Divider empty height={28} />
          <MenuButton
            disabled={!name || loading || hasError.visible}
            onPress={onNext}
            colors={['#90DF75', '#62B655']}
            text="Next"
            height={64}
            width={240}
          />
          <Divider empty height={24} />
          <BackButton text="Back  To Lobby" onPress={() => navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] })} />
        </ComponentWrapper>
      </DismissKeyboard>

      <Popup title="Game Mode" visible={isModalVisible} setVisible={setModalVisible}>
        <View style={styles.innerModal}>
          <Divider empty height={32} />
          <Radio
            onPress={() => setGameDifficultyLevel(AIDifficultyLevel.EASY)}
            isActive={gameDifficultyLevel === AIDifficultyLevel.EASY}
            title="Mild"
          />
          <Divider empty height={12} />
          <Radio
            onPress={() => setGameDifficultyLevel(AIDifficultyLevel.MEDIUM)}
            isActive={gameDifficultyLevel === AIDifficultyLevel.MEDIUM}
            title="Medium"
          />
          <Divider empty height={12} />
          <Radio
            onPress={() => setGameDifficultyLevel(AIDifficultyLevel.HARD)}
            isActive={gameDifficultyLevel === AIDifficultyLevel.HARD}
            title="Spicy"
          />
          <Divider empty height={12} />
          <Radio
            onPress={() => setGameDifficultyLevel(AIDifficultyLevel.EXTREME)}
            isActive={gameDifficultyLevel === AIDifficultyLevel.EXTREME}
            title="Extreme"
          />
          <Divider empty height={24} />
          <MenuButton
            disabled={!name || loading}
            onPress={handleJoinGame}
            colors={['#90DF75', '#62B655']}
            text="Join Game"
            textStyle={{ fontSize: 18 }}
            height={61}
            width={230}
            loading={loading}
          />
        </View>
      </Popup>
      <ErrorPopup
        isVisible={hasError.popup}
        setVisible={() => setHasError(v => ({ ...v, popup: false }))}
        text={hasError.text}
      />
    </>
  );
};

export { PlayWithAI };

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Secular One',
    fontSize: 32,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
  },
  innerModal: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 32,
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    maxWidth: 270,
    textAlign: 'center',
    color: '#3F3F3F',
  },
  spinnerContainer: {
    paddingRight: 8,
  },
});
