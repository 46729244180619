import React, { useRef, useEffect } from 'react';
import { Animated, Text } from 'react-native';
import { applyTransparency } from '../../constants/colors';

export default function CircularProgress({ progress = 0, size = 50, fontSize = 12 }) {
  const animatedProgress = useRef(new Animated.Value(0)).current;

  const animateProgress = useRef((toValue: any) => {
    Animated.spring(animatedProgress, {
      toValue,
      useNativeDriver: true,
    }).start();
  }).current;

  useEffect(() => {
    animateProgress(progress);
  }, [animateProgress, progress]);

  const firstIndicatorRotate = animatedProgress.interpolate({
    inputRange: [0, 30],
    outputRange: ['0deg', '180deg'],
    extrapolate: 'clamp',
  });
  const secondIndicatorRotate = animatedProgress.interpolate({
    inputRange: [0, 60],
    outputRange: ['0deg', '360deg'],
    extrapolate: 'clamp',
  });
  const secondIndictorVisibility = animatedProgress.interpolate({
    inputRange: [0, 29, 30, 60],
    outputRange: [0, 0, 1, 1],
    extrapolate: 'clamp',
  });

  return (
    <Animated.View
      style={{
        position: 'relative',
        width: size,
        height: size,
        borderRadius: size / 2,
        borderWidth: size / 10,
        borderColor: '#cfcfcf',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [{ rotate: '-45deg' }],
      }}
    >
      <Text
        maxFontSizeMultiplier={1}
        style={{
          transform: [{ rotate: '45deg' }],
          fontSize: fontSize,
          fontFamily: 'Secular One',
          color: '#453B3B',
          textShadowColor: applyTransparency('#000000', 0.15),
          textShadowOffset: { width: 0, height: 4 },
          textShadowRadius: 2,
        }}
      >
        {progress}
      </Text>
      <Animated.View
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: size / 10,
          position: 'absolute',
          borderLeftColor: '#FF9900',
          borderTopColor: '#FF9900',
          borderRightColor: 'transparent',
          borderBottomColor: 'transparent',
          transform: [{ rotate: firstIndicatorRotate }],
        }}
      />
      <Animated.View
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: size / 10,
          position: 'absolute',
          borderLeftColor: '#cfcfcf',
          borderTopColor: '#cfcfcf',
          borderRightColor: 'transparent',
          borderBottomColor: 'transparent',
        }}
      />
      <Animated.View
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: size / 10,
          position: 'absolute',
          borderLeftColor: '#FF9900',
          borderTopColor: '#FF9900',
          borderRightColor: 'transparent',
          borderBottomColor: 'transparent',
          transform: [{ rotate: secondIndicatorRotate }],
          opacity: secondIndictorVisibility,
        }}
      />
    </Animated.View>
  );
}
