import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import GraphIcon from '../../../../resources/svgComponents/GraphIcon';
import NoTeachersIcon from '../../../../resources/svgComponents/NoTeachersIcon';
import NoStudentsIcon from '../../../../resources/svgComponents/NoStudentsIcon';
import { COLORS } from '../../../../constants/colors';
import { TabNames } from '../utils';


const color = '#D6D7DE';

const iconMap = {
    [TabNames.Teachers]: <NoTeachersIcon color={color} containerStyle={{top: '15%'}}/>,
    [TabNames.Students]: <NoStudentsIcon size={76} color={color} containerStyle={{top: '25%'}}/>,
    [TabNames.Analytics]: <GraphIcon size={64} color={color}/>,
};

const headerMap = {
    [TabNames.Teachers]: 'No teachers yet',
    [TabNames.Students]: 'No students yet',
    [TabNames.Analytics]: 'Nothing to show yet',
};

const textMap = {
    [TabNames.Teachers]: 'Once teachers start signing up on your app you can manage them from here',
    [TabNames.Students]: 'Once students start signing up on your app you can manage them from here',
    [TabNames.Analytics]: 'Once there’s some activity on your app, your app analytics will show up here',
};

const NothingToShow = ({
    tab}: {
    tab: TabNames,
}) => {

    return (
        <View style={styles.page}>
            <View style={styles.iconWrapper}>
                {iconMap[tab]}
            </View>
            <Text style={styles.headerText}>{headerMap[tab]}</Text>
            <View style={styles.textWrapper}>
            <Text style={styles.text}>{textMap[tab]}</Text>
            </View>
        </View>
    );
};

export { NothingToShow};

const styles = StyleSheet.create({
    page: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapper: {
        width: 105,
        height: 105,
        borderRadius: 105,
        backgroundColor: '#F1F2F7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginBottom: 12,
    },
    headerText:{
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: '600',
        color: COLORS.black,
    },
    textWrapper: {
        maxWidth: 273,
        marginTop: 6,
    },
    text: {
        fontFamily: 'Inter',
        fontWeight: '400',
        fontSize: 13,
        color: COLORS.offBlack,
        textAlign: 'center',
    },
});


