import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Animated, Easing, Image, StyleSheet } from 'react-native';
import { GAME_TYPES_CONFIG, GAME_TYPES_TYPE } from '../../constants/constants';
import { findPositionByIndex } from '../../utils/board';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

const FirstPointBee = require('../../resources/images/FirstBee.png');
const WinningRow = require('../../resources/images/winningRow.png');

interface IWinningBee {
  winningTiles: number[] | undefined;
  winningDirection: number[] | undefined;
  gameType: GAME_TYPES_TYPE
}
const WinningBee = (props: IWinningBee) => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const winningRowFadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const moveXAnim = useRef(new Animated.Value(0)).current;
  const moveYAnim = useRef(new Animated.Value(0)).current;

  const screenSize = useScreenSize();

  const calculateCellSize = useMemo(() => {
    if (isMobile) {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 64;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 45;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 35;
      }
      return 43;
    } else {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 64;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 39;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 35;
      }
      return 39;
    }
  }, [screenSize]);

  const calculateCellSpace = useMemo(() => {
    if (isMobile) {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 19;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 14;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 9.5;
      }
      return 13;
    } else {
      if (screenSize === SCREEN_SIZES.LARGE) {
        return 19;
      }
      if (screenSize === SCREEN_SIZES.MEDIUM) {
        return 10.5;
      }
      if (screenSize === SCREEN_SIZES.SMALL) {
        return 9.5;
      }
      return 10.5;
    }
  }, [screenSize]);


  const cellSize = calculateCellSize;
  const cellSpace = calculateCellSpace;

  const calculateLeft = (tileIndex: number) => {
    let { x, y } = findPositionByIndex(tileIndex, props.gameType);
    return ((((GAME_TYPES_CONFIG[props.gameType].maxInARow - GAME_TYPES_CONFIG[props.gameType].rowsCount[y]) / 2) * cellSize) + cellSize * x);
  };
  const calculateTop = (tileIndex: number) => {
    let { y } = findPositionByIndex(tileIndex, props.gameType);
    return (49 / 42) * cellSize * y - cellSpace * y;
  };
  const handleRotation = useCallback(() => {
    if (props.winningDirection && props.winningDirection[0] === 0) {
      return [
        {
          translateX: -(cellSize * 3) / 2,
        },
        {
          rotate: '60deg',
        },
        {
          translateX: (cellSize * 3) / 2,
        },
      ];
    } else if (props.winningDirection && props.winningDirection[0] === 2) {
      return [
        {
          translateX: -(cellSize * 3) / 2,
        },
        {
          rotate: '-60deg',
        },
        {
          translateX: (cellSize * 3) / 2,
        },
      ];
    }
    return [];
  }, [cellSize, props.winningDirection]);

  const startAnimations = Animated.sequence([
    Animated.parallel([
      Animated.timing(winningRowFadeAnim, {
        toValue: 1,
        duration: 700,
        useNativeDriver: true,
      }),
      Animated.timing(moveXAnim, {
        toValue: calculateLeft(props.winningTiles !== undefined ? props.winningTiles[0] : 0),
        duration: 50,
        useNativeDriver: true,
      }),
      Animated.timing(moveYAnim, {
        toValue: calculateTop(props.winningTiles !== undefined ? props.winningTiles[0] : 0),
        duration: 50,
        useNativeDriver: true,
      }),
    ]),
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 170,
      useNativeDriver: true,
    }),
    Animated.parallel([
      Animated.timing(moveXAnim, {
        toValue: calculateLeft(props.winningTiles !== undefined ? props.winningTiles[1] : 0),
        duration: 1000,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      Animated.timing(moveYAnim, {
        toValue: calculateTop(props.winningTiles !== undefined ? props.winningTiles[1] : 0),
        duration: 1000,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      Animated.timing(fadeAnim, {
        delay: 400,
        toValue: 0,
        duration: 1500,
        useNativeDriver: true,
      }),
    ]),
  ]);

  useEffect(() => {
    if (props.winningTiles) {
      startAnimations.start();
    }
  }, [props.winningTiles, startAnimations]);

  return (
    <>
      <Animated.View
        style={{
          width: cellSize,
          height: (49 / 42) * cellSize,
          position: 'absolute',
          zIndex: 10,
          left: 0,
          top: 0,
          opacity: fadeAnim,
          transform: [{ translateX: moveXAnim }, { translateY: moveYAnim }],
        }}
        pointerEvents="none"
      >
        <Image style={styles.primaryImage} source={FirstPointBee} />
      </Animated.View>
      <Animated.View
        style={{
          zIndex: 9,
          position: 'absolute',
          width: cellSize * 3,
          opacity: winningRowFadeAnim,
          transform: [
            {
              translateX: calculateLeft(
                props.winningTiles !== undefined ? props.winningTiles[0] : 0,
              ),
            },
            {
              translateY: calculateTop(
                props.winningTiles !== undefined ? props.winningTiles[0] : 0,
              ),
            },
          ],
        }}
        pointerEvents="none"
      >
        <Image
          resizeMode="stretch"
          style={{
            position: 'absolute',
            width: cellSize * 3,
            height: 10,
            top: cellSize / 2,
            left: screenSize === SCREEN_SIZES.LARGE ? 30 : 20,
            transform: handleRotation(),
          }}
          source={WinningRow}
        />
      </Animated.View>
    </>
  );
};

export default React.memo(WinningBee);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  secondaryImage: {
    position: 'absolute',
    width: 40,
    height: 49,
    top: -30,
    left: 20,
  },
  primaryImage: {
    position: 'absolute',
    width: 40,
    height: 49,
    top: -20,
    left: -20,
  },
  winningRow: {
    position: 'absolute',
  },
});
