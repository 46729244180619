import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation signOut {
    signOut
  }
`;

export interface MutationType {
  signOut: boolean;
}

export function useSignOutMutation(
  options?: MutationHookOptions<MutationType, void>,
) {
  return useMutation<MutationType, void>(mutation, options);
}
