import 'react-native-gesture-handler';

import React, { useEffect, useState } from 'react';
import { useFontLoader } from './src/utils/fontLoader';
import { SignedInStatusProvider } from './src/context/SignedInContext';
import RootNavigation from './src/navigators/RootNavigator';
import { MB_DialogueContextProvider } from './src/mightyByteLibraries/MB_Dialogue/MB_DialogueContext';
import { MB_Dialogue } from './src/mightyByteLibraries/MB_Dialogue/MB_Dialogue';
import { COLORS } from './src/constants/colors';
import { StyleSheet } from 'react-native';
import { textStyles } from './src/constants/textStyles';
import { GraphQLProvider } from './src/graphql';
import { QueryContextProvider } from './src/context/QueryContextProvider';
import { MB_deviceInfoInitialize } from '@mightybyte/rnw.utils.device-info';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { initialWindowMetrics, SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { getNewToken } from './src/graphql/getNewToken';

const App = () => {
  useFontLoader();
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);

  useEffect(() => {
    async function appInitialSetup() {
      MB_accessTokenUtils.setAccessTokenRefreshEndpointFunction(getNewToken);
      await MB_deviceInfoInitialize();
      setIsInitialSetupComplete(true);
    }

    appInitialSetup();
  }, []);

  if (!isInitialSetupComplete) {
    return null;
  }

  return (
      <SafeAreaProvider initialMetrics={initialWindowMetrics}>
        <SafeAreaView style={styles.safeAreaView}>

    <GraphQLProvider>
      <QueryContextProvider>
        <SignedInStatusProvider>
          <MB_DialogueContextProvider>
            <RootNavigation />

            <MB_Dialogue
              defaultContainerStyle={styles.modalPopUpDefaultContainer}
              defaultTitleStyle={styles.modalPopUpDefaultTitle}
              defaultMessageStyle={styles.modalPopUpDefaultMessage}
              defaultPrimaryButtonStyle={styles.modalPopUpDefaultPrimaryButton}
              defaultSeconaryButtonStyle={styles.modalPopUpDefaultSecondaryButton}
              defaultButtonsContainerStyleModifier={styles.modalPopUpDefaultButtonsContainerModifier}
            />
          </MB_DialogueContextProvider>
        </SignedInStatusProvider>
      </QueryContextProvider>
    </GraphQLProvider>
        </SafeAreaView>
      </SafeAreaProvider>
  );
};

export default App;

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  modalPopUpDefaultContainer: {
    backgroundColor: COLORS.darkPurple,
    borderRadius: 10,
    paddingTop: 40,
    paddingHorizontal: 76,
    paddingBottom: 44,
  },
  modalPopUpDefaultTitle: StyleSheet.flatten([
    textStyles.largerText,
    {
      fontWeight: '600',
    },
  ]),
  modalPopUpDefaultMessage: StyleSheet.flatten([
    textStyles.normalText,
    {
      fontWeight: '400',
      marginTop: 18,
    },
  ]),
  modalPopUpDefaultPrimaryButton: {
    height: 58,
    minWidth: 168,
    alignSelf: 'center',
    backgroundColor: COLORS.plum,
  },
  modalPopUpDefaultSecondaryButton: {
    height: 58,
    minWidth: 168,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: COLORS.white,
  },
  modalPopUpDefaultButtonsContainerModifier: {
    marginTop: 48,
  },
});
