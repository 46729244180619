import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, LinearGradient, Defs, G, Stop } from 'react-native-svg';

const RibbonYellow = React.memo(({ containerStyle, width, height }: {
    containerStyle?: StyleProp<ViewStyle>
    width?: number | string
    height?: number | string
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={width ?? 635}
                height={height ?? 102}
                viewBox="0 0 635 102"
                fill="none"
            >
                <G>
                    <Path
                        d="M16 12.855h603L585.158 47.32 619 81.783H16L51.893 47.32 16 12.855z"
                        fill="url(#paint0_linear_2019_4519)"
                    />
                </G>
                <Defs>
                    <LinearGradient
                        id="paint0_linear_2019_4519"
                        x1={317.5}
                        y1={12.8555}
                        x2={317.5}
                        y2={81.7831}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FFCF53" />
                        <Stop offset={1} stopColor="#F90" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export default RibbonYellow;
