import React from 'react';
import { StyleSheet, TouchableOpacity, Text, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { applyTransparency } from '../../constants/colors';
import { BackIcon } from '../../resources/svgComponents/BackIcon';

interface IBackButtonProps {
  text: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}
const BackButton = (props: IBackButtonProps) => {
  return (
    <TouchableOpacity style={styles.container} onPress={props.onPress}>
      <BackIcon width={28} height={20} />
      <Text maxFontSizeMultiplier={1} style={[styles.text, props.textStyle]}>
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

export { BackButton };

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Secular One',
    marginLeft: 6,
    fontSize: 20,
    marginTop: 4,
    color: '#6D6D6D',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 3 },
    textShadowRadius: 2,
  },
});
