import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { PointPolygon } from '../../resources/svgComponents/PointPolygon';
import { PlusIcon } from '../../resources/svgComponents/PlusIcon';
import { XIcon } from '../../resources/svgComponents/XIcon';
import { GAME_TYPES_TYPE } from '../../constants/constants';

interface ICalculationProps {
  first: number;
  second: number;
  isSmallDevice?: boolean;
  isLargeDevice?: boolean;
  gameType: GAME_TYPES_TYPE;
  changedSliderIndex: null | number;
}

const colors1: [string, string] = ['#FF9900', '#FFCF53'];
const colors3: [string, string] = ['#bababa', '#bababa'];

export const Calculation = React.memo((props: ICalculationProps) => {
  return (
    <View style={[styles.container, props.isSmallDevice ? styles.smallDevice : null]}>
      <View style={props.isLargeDevice ? styles.largeInnerContainer : styles.innerContainer}>
        <Text maxFontSizeMultiplier={1} style={[styles.text, props.isLargeDevice ? styles.largeText : null]}>
          {props.first}
        </Text>
        <PointPolygon
          key="first"
          width={props.isLargeDevice ? 74 : 60}
          height={props.isLargeDevice ? 74 : 60}
          containerStyle={props.isLargeDevice ? styles.largePolygon : styles.polygon}
          colors={props.changedSliderIndex === 0 ? colors1 : colors3}
        />
      </View>
      {
        props.gameType === GAME_TYPES_TYPE.ADD_1_12 || props.gameType === GAME_TYPES_TYPE.ADD_MINUS_6_6 ?
          <PlusIcon containerStyle={{ padding: 8, paddingTop: 20 }} size={20} />
          :
          <XIcon containerStyle={{ padding: 8, paddingTop: 20 }} width={20} height={20} />
      }
      {/* <Feather name="x" size={20} color={'#E20000'} /> */}
      <View style={props.isLargeDevice ? styles.largeInnerContainer : styles.innerContainer}>
        <Text maxFontSizeMultiplier={1} style={[styles.text, props.isLargeDevice ? styles.largeText : null]}>
          {props.second}
        </Text>
        <PointPolygon
          key="second"
          width={props.isLargeDevice ? 74 : 60}
          height={props.isLargeDevice ? 74 : 60}
          containerStyle={props.isLargeDevice ? styles.largePolygon : styles.polygon}
          colors={props.changedSliderIndex === 1 ? colors1 : colors3}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  smallDevice: {
    marginTop: -10,
  },
  largeDevice: {
    marginBottom: 10,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    marginTop: 8,
  },
  innerContainer: {
    position: 'relative',
    width: 60,
    height: 60,
    marginHorizontal: 8,
  },
  largeInnerContainer: {
    position: 'relative',
    width: 74,
    height: 74,
    marginHorizontal: 8,
  },
  text: {
    fontFamily: 'Secular One',
    width: 30,
    height: 30,
    lineHeight: 30,
    textAlign: 'center',
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    fontSize: 16,
    left: '50%',
    transform: [{ translateX: -15 }, { translateY: -15 }],
    color: 'black',
  },
  largeText: {
    fontSize: 20,
  },
  polygon: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    ...Platform.select({
      web: {
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0,
        shadowRadius: 0,
      },
    }),
  },
  largePolygon: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
});
