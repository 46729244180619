import { gql, useMutation, MutationHookOptions } from '@apollo/client';

export const query = gql`
  mutation changeHiveName($hiveId: String!, $name: String!) {
    changeHiveName(hiveId: $hiveId, name: $name)
  }
`;

export interface RequestType {
  name: string;
  hiveId: string;
}

export interface MutationType {
  changeHiveName: boolean;
}

export function useChangeHiveNameMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(query, options);
}
