import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { GAME_TYPES_TYPE, OPERAND_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation getLatestUpdate($gameId: String!) {
    getLatestUpdate(gameId: $gameId) {
      game {
        id
        status
        users {
          id
          name
        }
        gameState {
          gameType
          turn
          userNameTurn
          winningPlayerId
          winningPlayerUserName
          lastPlayedTimeAt
          winningTiles
          winningDirection
          leftOperand{
            a
            x
            b
          }
          rightOperand{
            a
            x
            b
          }
          tiles {
            id
            value {
              a
              x
              b
            }
            edges
            claimedBy
            claimedByUserName
          }
        }
      }
    }
  }
`;

export interface RequestType {
  gameId: string;
}

export interface MutationType {
  getLatestUpdate: LatestUpdateResponseType;
}
export interface LatestUpdateResponseType {
  game: GameType;
  __typename?: 'LatestUpdateResponse';
}

export interface GameType {
  id: string;
  status: GameStatus;
  users: GameUserType[];
  gameState: GameStateType;
  __typename?: 'Game';
}

export enum GameMode {
  PLAY_ONLINE = 'PLAY_ONLINE',
  HOST_A_GAME = 'HOST_A_GAME',
  JOIN_A_GAME = 'JOIN_A_GAME',
  PRACTICE = 'PRACTICE',
}

export enum GameStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface GameUserType {
  id: string;
  name: string;
  __typename?: 'GameUser';
}

export interface GameStateType {
  gameType: GAME_TYPES_TYPE;
  turn: string;
  winningPlayerId: string;
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
  tiles: GameHiveTileType[];
  winningTiles: number[];
  winningDirection: number[];
  lastPlayedTimeAt: string;
  __typename?: 'GameState';
}

export interface GameHiveTileType {
  id: number;
  value: OPERAND_TYPE;
  edges: number[];
  claimedBy: string;
  __typename?: 'GameHiveTile';
}

export function useGetLatestUpdate(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
