import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { HiveSize, PaymentStatus } from '../../../constants/constants';

export const mutation = gql`
  mutation CreateHive($name: String!, $size: String!, $subscriptionType: String!) {
    createHive(name: $name, size: $size, subscriptionType: $subscriptionType) {
      id
      name
      size
      code
      seatTaken
      numberOfSeats
      paymentStatus
    }
  }
`;

export interface RequestType {
  name: string;
  size: string;
  subscriptionType: string;
}
export interface HiveType {
  id: string;
  name: string;
  code: string;
  size: HiveSize;
  seatTaken: number;
  numberOfSeats: number;
  paymentStatus: PaymentStatus
}

export interface MutationType {
  createHive: HiveType;
}


export function useCreateHiveMutation(options?: MutationHookOptions<MutationType, RequestType>) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
