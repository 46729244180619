import { HiveUserType } from '../../screens/MyHiveData/useHiveUsersQuery.gql';

export type LeaderBoardData = {points: number; userName: string}[][];

export type LeaderBoardProps = {
    data: LeaderBoardData;
    isVisible: boolean;
    onClose: () => void;
};

export const createLeaderboardData = ({users, currentStudent}: {
    users: Partial<HiveUserType>[],
    currentStudent?: HiveUserType, // TODO: Sending this from myHiveDataStudent is a bit problematic, we should change the type here.
}):LeaderBoardData => {
    const hiveUsers: {
        userName: string,
        points: number,
    }[] = [...users].map((u) => {
        return {
            userName: u?.userName || '',
            points: u?.points || 0,
        };
    });
    if (currentStudent) {
        hiveUsers.push({
            userName: currentStudent?.userName || '',
            points: currentStudent?.points || 0,
        });
    }

    const sorted = hiveUsers.sort((a, b) => b?.points - a?.points);
    //group by points
    const grouped:LeaderBoardData = [];
    sorted.forEach((item, index) => {
        if (index === 0) {
            grouped.push([item]);
        } else {
            if (sorted[index - 1].points === item.points) {
                grouped[grouped.length - 1].push(item);
            } else {
                grouped.push([item]);
            }
        }
    });
    return grouped;
};
