import { nanoid } from 'nanoid';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const DisabledHexagonIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    colors?: [string, string];
    transparent?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    const gradientId = nanoid();

    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 51}
          height={props.height ?? 59}
          viewBox="0 0 51 59"
          fill="none"
          {...props}
        >
          <Path
            d="M26.218 1.6l-.423-.247-.424.248L2.166 15.208l-.414.242v28.174l.414.242 23.205 13.607.424.248.423-.248 23.205-13.607.414-.243V15.45l-.414-.242L26.218 1.6z"
            fill={'#ECECEC'}
            stroke={`url(#${gradientId})`}
            strokeWidth={1.67468}
          />
          <Defs>
            <LinearGradient
              id={gradientId}
              x1={25.7948}
              y1={2.32324}
              x2={25.7948}
              y2={56.7503}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor={props.colors ? props.colors[0] : '#C4C4C4'} />
              <Stop offset={1} stopColor={props.colors ? props.colors[0] : '#C4C4C4'} />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { DisabledHexagonIcon };
