import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const CloseIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={props.width ?? 44}
          height={props.height ?? 51}
          viewBox="0 0 44 51"
          fill="none"
          {...props}
        >
          <G>
            <Path
              d="M22.185 5l15.748 9.325v18.652l-15.748 9.325-15.749-9.325V14.326L22.185 5z"
              fill="url(#paint0_linear_190_25161)"
            />
            <Path
              d="M22.689 4.15l-.504-.3-.504.3-15.749 9.325-.485.287V33.54l.485.288 15.749 9.325.504.299.504-.299 15.748-9.325.485-.288V13.762l-.485-.287L22.69 4.149z"
              stroke="#fff"
              strokeWidth={1.97788}
            />
          </G>
          <Path
            d="M28.246 17.123L15.191 30.18M15.19 17.123L28.247 30.18"
            stroke="#fff"
            strokeWidth={3.38322}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <Defs>
            <LinearGradient
              id="paint0_linear_190_25161"
              x1={22.1848}
              y1={5}
              x2={22.1848}
              y2={42.3022}
              gradientUnits="userSpaceOnUse"
            >
              <Stop stopColor="#FF9252" />
              <Stop offset={1} stopColor="#FF3F15" />
            </LinearGradient>
          </Defs>
        </Svg>
      </View>
    );
  },
);

export { CloseIcon };
