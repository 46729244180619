import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { OPERAND_TYPE } from '../../../constants/constants';

const mutation = gql`
  mutation joinGameByCode($inputs: JoinGameByCodeInput!) {
    joinGameByCode(inputs: $inputs) {
      game {
        id
        joinCode
        status
        users {
          id
          name
        }
        gameState {
          gameType
          turn
          winningPlayerId
          lastPlayedTimeAt
          leftOperand{
            a
            x
            b
          }
          rightOperand{
            a
            x
            b
          }
          tiles {
            id
            value {
              a
              x
              b
            }
            edges
            claimedBy
          }
        }
      }
    }
  }
`;

export interface RequestType {
  inputs: JoinGameByCodeInputType;
}

export interface JoinGameByCodeInputType {
  userName: string;
  joinCode: string;
  __typename?: 'JoinGameByCodeInput';
}

export interface MutationType {
  joinGameByCode: JoinGameByCodeResponseType;
}

export interface JoinGameByCodeResponseType {
  game: GameType;
  __typename?: 'JoinGameByCodeResponse';
}

export interface GameType {
  id: string;
  joinCode?: string;
  status: GameStatus;
  users: GameUserType[];
  gameState: GameStateType;
  __typename?: 'Game';
}

export enum GameStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface GameUserType {
  id: string;
  name: string;
  __typename?: 'GameUser';
}

export interface GameStateType {
  gameType: string;
  turn: string;
  winningPlayerId: string;
  leftOperand: OPERAND_TYPE;
  rightOperand: OPERAND_TYPE;
  tiles: GameHiveTileType[];
  lastPlayedTimeAt: string;
  __typename?: 'GameState';
}

export interface GameHiveTileType {
  id: number;
  value: OPERAND_TYPE;
  edges: number[];
  claimedBy: string;
  __typename?: 'GameHiveTile';
}

export function useJoinGameByCodeMutation(
  options?: MutationHookOptions<MutationType, RequestType>,
) {
  return useMutation<MutationType, RequestType>(mutation, options);
}
