import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { Popup } from './Popup';
import { applyTransparency } from '../../constants/colors';
import { PremiumPlans } from './PremiumPlans';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../navigators/RootNavigator';
import { USER_TYPES } from '../../typesInterfacesEnums/enums';

interface IPopupProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}
const PremiumPlansPopup = ({ visible, setVisible }: IPopupProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const onSelectHive = useCallback(() => {
    setVisible(false);
    navigation.navigate('ChooseUserType', { userType: USER_TYPES.teacher });
  }, [navigation, setVisible]);

  // TODO: This might be a problematic pop-up on smaller devices.

  return (
    <Popup
      containerStyle={styles.popupContainer}
      title="Go Premium!"
      visible={visible}
      setVisible={setVisible}
      closeOnBackdropPress={true}
    >
      <View style={styles.container}>
        <PremiumPlans onSelectHive={onSelectHive} />
      </View>
    </Popup>
  );
};

export { PremiumPlansPopup };

const styles = StyleSheet.create({
  popupContainer: {
    height: 'auto',
    width: 'auto',
    maxWidth: 'auto',
  },
  title: {
    fontFamily: 'Secular One',
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
    color: '#121212',
    fontSize: 18,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 40,
    paddingVertical: 60,
  },
  buttonTextStyle: {
    fontFamily: 'Secular One',
    color: '#fff',
    fontSize: 20,
    textShadowColor: applyTransparency('#000000', 0.15),
    textShadowOffset: { width: 0, height: 4 },
    textShadowRadius: 2,
  },
  label: {
    fontFamily: 'Secular One',
    marginBottom: 8,
    fontSize: 15,
  },
});
