import React from 'react';
import {
  Image,
  ImageBackground,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SCREEN_SIZES, useScreenSize } from '../../utils/dimsHooks';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

const backgroundImage = require('../../resources/images/pattern.jpg');
const largeBackgroundImage = require('../../resources/images/webBack.png');
const largeHorizontalBackgroundImage = require('../../resources/images/horizontalBack.png');

const beeRight = require('../../resources/images/backgroundBeeRight.png');
const beeLeft = require('../../resources/images/backgroundBeeLeft.png');

interface IComponentWrapperProps {
  children: React.ReactNode;
  hasInset: boolean;
  hasBackground?: boolean;
  backgroundColor?: string;
  hasScroll?: boolean;
  style?: StyleProp<ViewStyle>;
  scrollEnabled?: boolean;
  horizontalBackground?: boolean;
  hideBee?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
}
const ComponentWrapper = ({
  hasInset,
  children,
  hasBackground = true,
  backgroundColor = 'white',
  hasScroll = true,
  style,
  scrollEnabled = true,
  horizontalBackground = false,
  hideBee,
  containerStyle,
  contentContainerStyle,
}: IComponentWrapperProps) => {
  const inset = useSafeAreaInsets();
  const screenSize = useScreenSize();
  const isLarge = screenSize === SCREEN_SIZES.LARGE;

  const renderBees = () => {
    if (hideBee) {
      return null;
    }
    if (isLarge && !horizontalBackground) {
      return (
        <>
          <Image resizeMode="cover" style={styles.beeRight} source={beeRight} />
          <Image resizeMode="cover" style={styles.beeLeft} source={beeLeft} />
        </>
      );
    }
    return null;
  };

  if (!hasBackground) {
    return (
      <View style={[styles.container, { backgroundColor }, containerStyle]}>
        <View style={styles.image}>
          <ScrollView
            scrollEnabled={scrollEnabled}
            contentContainerStyle={[{
              alignItems: 'center',
              paddingTop: hasInset ? inset.top : 0,
              paddingBottom: hasInset ? inset.bottom : 0,
            }, contentContainerStyle]}
            style={[styles.innerContainer, style]}
          >
            {children}
          </ScrollView>
        </View>
      </View>
    );
  }
  return (
    <View style={[styles.container, containerStyle]}>
      {renderBees()}
      <ImageBackground
        imageStyle={{ height: '100%' }}
        resizeMode="cover"
        style={styles.image}
        source={
          !isMobile
            ? horizontalBackground
              ? largeHorizontalBackgroundImage
              : largeBackgroundImage
            : backgroundImage
        }
      >
        {hasScroll ? (
          <ScrollView
            contentContainerStyle={[{
              alignItems: 'center',
              paddingTop: hasInset ? inset.top : 0,
              paddingBottom: hasInset ? inset.bottom : 0,
            }, contentContainerStyle]}
            scrollEnabled={scrollEnabled}
            style={[styles.innerContainer, style]}
          >
            {children}
          </ScrollView>
        ) : (
          <View
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: hasInset ? inset.top : 0,
              paddingBottom: hasInset ? inset.bottom : 0,
            }}
          >
            {children}
          </View>
        )}
      </ImageBackground>
    </View>
  );
};

export { ComponentWrapper };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    position: 'relative',
  },
  image: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    paddingHorizontal: 20,
    position: 'relative',
  },
  beeRight: {
    width: 240,
    height: 260,
    position: 'absolute',
    top: '50%',
    right: '10%',
    transform: [{ translateY: -130 }],
  },
  beeLeft: {
    width: 190,
    height: 290,
    position: 'absolute',
    top: '50%',
    left: '10%',
    transform: [{ translateY: -145 }],
  },
});
