import Base64 from 'react-native-base64';
import { Platform } from 'react-native';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import { MB_EventEmmiter, MB_EventObject, MB_EVENT_EMMITER_EVENT_TYPE } from '@mightybyte/rnw.utils.event-emmiter';
// import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { ServerRedirectNavigationProp } from '../../../typesInterfacesEnums/componentProps';
import { envs } from '../../../../env';
import { signedInContextGlobalFunction, SIGNED_IN_STATUS } from '../../../context/SignedInContext';
import { ServerError, ServerSuccess } from '../../../typesInterfacesEnums/typesAndInterfaces';
import { SERVER_ERROR_CODES } from '../../../constants/constants';
import { Token } from '@mightybyte/rnw.utils.access-token-utils';
import { PasswordRecoveryTokenObj } from '../../../utils/zod/zodObjects';

export const enum REDIRECT_REASON {
    googleLogin = 'googleLogin',
    appleLogin = 'appleLogin',
    passwordRecovery = 'passwordRecovery',
    stripePaymentSuccess = 'stripePaymentSuccess',
    manageBillingComplete = 'manageBillingComplete',
}

interface ServerRedirectPostMessageObject {
    success: boolean,
    type: WEB_POST_MESSAGE_OBJECT_TYPES,
    subType?: WEB_POST_MESSAGE_OBJECT_SUB_TYPES,
    data?: Token,
}

export enum WEB_POST_MESSAGE_OBJECT_TYPES {
    LOGIN_COMPLETE = 'LOGIN_COMPLETE',
    SOCIAL_MEDIA_CONNECT = 'SOCIAL_MEDIA_CONNECT',
    GENERAL_ERROR = 'GENERAL_ERROR',
    STRIPE_CONNECT = 'STRIPE_CONNECT',
    STRIPE_PAYMENT = 'STRIPE_PAYMENT',
}

enum WEB_POST_MESSAGE_OBJECT_SUB_TYPES {
    INSTAGRAM_CONNECT = 'INSTAGRAM_CONNECT',
    TWITTER_CONNECT = 'TWITTER_CONNECT',
    TIKTOK_CONNECT = 'TIKTOK_CONNECT',
    STRIPE_CLOSED_OR_FINISHED = 'STRIPE_CLOSED_OR_FINISHED',
    STRIPE_NEEDS_REFRESH = 'STRIPE_NEEDS_REFRESH',
    STRIPE_PAYMENT_CANCELLED = 'STRIPE_PAYLMENT_CANCELLED',
    STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS'
}

const postMessageAndClose = (message: ServerRedirectPostMessageObject, navigation: ServerRedirectNavigationProp) => {
    const eventObject: MB_EventObject = {
        origin: envs.WEBSITE_BASE_URL,
        data: message,
    };

    if (Platform.OS === 'web') {
        MB_customWindowForWeb.close();
        MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, eventObject);
    } else {
        const navRouteNames = navigation.getState().routes.map(route => route.name);

        // Note: Need some time to ensure navigation is complete before we send the message
        setTimeout(() => MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, eventObject), 50);

        if (navRouteNames.length > 0 && navRouteNames[navRouteNames.length - 1] === 'ServerRedirect') {
            if (navigation.canGoBack()) {
                navigation.goBack();
            } else {
                console.log('Error: Was unable to go back when handling post message and close for server redirect');
                navigation.reset({
                    index: 0,
                    routes: [{ name: navigation.getState().routeNames[0] }],
                });
            }
        }
    }
};

const incorrectTokenHandler = async (makeRefreshTokenCall: boolean) => {
    signedInContextGlobalFunction.signOut?.({ skipSignOutRequest: true, showExpiredError: true, makeRefreshTokenCall });
    MB_customWindowForWeb.close();
};

const handleError = (errorParamBase64: string, navigation: ServerRedirectNavigationProp) => {
    try {
        const errorParamsString = Base64.decode(errorParamBase64);
        const errorParams = JSON.parse(errorParamsString) as ServerError;

        if (errorParams.errorCode === SERVER_ERROR_CODES.GOOGLE_LOGIN_FAILED || errorParams.errorCode === SERVER_ERROR_CODES.APPLE_LOGIN_FAILED) {
            postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR }, navigation);
            return null;
        }

        if (errorParams.errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN || errorParams.errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN ||
            errorParams.errorCode === SERVER_ERROR_CODES.EXPIRED_ACCESS_TOKEN) {
            const makeRefreshTokenCall = errorParams.errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN || errorParams.errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN;
            serverRedirectUtils.incorrectTokenHandler(makeRefreshTokenCall);
            return null;
        }
    } catch (error) {
        console.error('Failed to parse server redirect error', { incomingError: errorParamBase64, error });
    }

    postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR }, navigation);
    return null;
};

const handleStripe = (successParams: ServerSuccess, navigation: ServerRedirectNavigationProp) => {
    postMessageAndClose({ success: true, type: WEB_POST_MESSAGE_OBJECT_TYPES.STRIPE_PAYMENT }, navigation);
    return null;
};

const handleGoogleOrAppleLogin = (successParams: ServerSuccess, navigation: ServerRedirectNavigationProp) => {
    const tokens = successParams.data.tokenPair;

    if (!tokens || !PasswordRecoveryTokenObj.safeParse(tokens).success) {
        postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR }, navigation);
    }

    postMessageAndClose({ success: true, type: WEB_POST_MESSAGE_OBJECT_TYPES.LOGIN_COMPLETE, data: tokens }, navigation);
    return null;
};

const handlePasswordRecovery = (successParams: ServerSuccess, navigation: ServerRedirectNavigationProp) => {
    const signedInStatus = signedInContextGlobalFunction.getSignedInStatus?.();
    if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
        // Note: Was throwing a warning without the timeout
        setTimeout(() => {
            navigation.reset({ index: 0, routes: [{ name: 'Lobby' }] });
        }, 50);
        return null;
    }

    // let passwordRecoveryToken: PasswordRecoveryToken | undefined = successParams.data as PasswordRecoveryToken;
    // let recoveryFailed: boolean | undefined;
    // if (!PasswordRecoveryTokenObj.safeParse(passwordRecoveryToken).success) {
    //     passwordRecoveryToken = undefined;
    //     recoveryFailed = true;
    // }

    // // Note: Was throwing a warning without the timeout
    // setTimeout(() => {
    //     if (isMobileApp) {
    //         if (navigation.getState().routes?.[0].name === 'ServerRedirect') {
    //             // Most likely app was closed before so the only route in router is serverRedirect.
    //             // Reset route to mobile landing page and navigate to forgot password.
    //             navigation.reset({ index: 0, routes: [{ name: 'MobileLandingPage' }] });
    //         } else {
    //             navigation.popToTop();
    //         }
    //         navigation.navigate('ForgotPassword', { passwordRecoveryToken, recoveryFailed });
    //     } else {
    //         navigation.reset({ index: 0, routes: [{ name: 'ForgotPassword', params: { passwordRecoveryToken, recoveryFailed } }] });
    //     }
    // }, 50);
    return null;
};

interface IHandleServerRedirectParams {
    errorParamBase64: string | undefined | null,
    successParamsBase64: string | undefined | null,
    navigation: ServerRedirectNavigationProp,
}

const handleServerRedirect = ({ errorParamBase64, successParamsBase64, navigation }: IHandleServerRedirectParams) => {
    if (errorParamBase64) {
        return serverRedirectUtils.handleError(errorParamBase64, navigation);
    }

    if (!successParamsBase64) {
        postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR }, navigation);
        return null;
    }

    try {
        const successParamsString = Base64.decode(successParamsBase64);
        const successParams = JSON.parse(successParamsString) as ServerSuccess;

        switch (successParams.redirectReason) {
            case REDIRECT_REASON.appleLogin:
            case REDIRECT_REASON.stripePaymentSuccess:
                return serverRedirectUtils.handleStripe(successParams, navigation);
            case REDIRECT_REASON.googleLogin:
                return serverRedirectUtils.handleGoogleOrAppleLogin(successParams, navigation);
            case REDIRECT_REASON.passwordRecovery:
                return serverRedirectUtils.handlePasswordRecovery(successParams, navigation);
            case REDIRECT_REASON.manageBillingComplete:
                return MB_customWindowForWeb.close();
            default:
                navigation.reset({
                    index: 0,
                    routes: [{ name: navigation.getState().routeNames[0] }],
                });
                return null;
        }
    } catch (error) {
        console.error('Failed to parse server redirect success params', error);
        return serverRedirectUtils.handleError('', navigation);
    }
};

export const serverRedirectUtils = {
    incorrectTokenHandler,
    handleError,
    handleStripe,
    handleGoogleOrAppleLogin,
    handlePasswordRecovery,
    handleServerRedirect,
};
