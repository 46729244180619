import { nanoid } from 'nanoid';
import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { ClipPath, Defs, G, LinearGradient, Path, Stop } from 'react-native-svg';

const UsernameIcon = (props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {

    const gradientId = nanoid();
    const gradientId2 = nanoid();

    return (
        <View style={props.containerStyle}>
            <Svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <G clipPath="url(#clip0_1_33999)">
                    <Path
                        d="M12.032 10.5H5.968a3.722 3.722 0 00-3.718 3.718V18h13.5v-3.782a3.723 3.723 0 00-3.718-3.718z"
                        fill={`url(#${gradientId})`}
                    />
                    <Path
                        d="M9 9a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
                        fill={`url(#${gradientId2})`}
                    />
                </G>
                <Defs>
                    <LinearGradient
                        id={gradientId}
                        x1={2.24723}
                        y1={14.2523}
                        x2={16.5119}
                        y2={14.2523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#949494" />
                        <Stop offset={1} stopColor="#595959" />
                    </LinearGradient>
                    <LinearGradient
                        id={gradientId2}
                        x1={4.49816}
                        y1={4.50274}
                        x2={14.0079}
                        y2={4.50274}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#949494" />
                        <Stop offset={1} stopColor="#595959" />
                    </LinearGradient>
                    <ClipPath id="clip0_1_33999">
                        <Path fill="#fff" d="M0 0H18V18H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        </View>
    );
};

export { UsernameIcon };
