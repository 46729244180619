import { initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { envs } from '../../env';

const app = initializeApp(envs.FIREBASE_CONFIG);
let analytics: Analytics | undefined;

export const logAnalytics = async (event: string, payload: Object) => {
  try {
    if (!analytics) {
      analytics = getAnalytics(app);
    }

    logEvent(analytics, event, payload);
  } catch (err) {
    console.log(err);
  }
};
