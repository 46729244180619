import { nanoid } from 'nanoid';
import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Path, Stop } from 'react-native-svg';

const EyeIcon = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    const gradientId = nanoid();
    const gradientId2 = nanoid();

    return (
        <View style={props.containerStyle}>
            <Svg
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
                {...props}
            >
                <Path
                    d="M9 11.999A2.999 2.999 0 109 6 2.999 2.999 0 009 12z"
                    fill={`url(#${gradientId})`}
                />
                <Path
                    d="M17.45 7.065C16.287 5.171 13.642 1.994 9 1.994c-4.642 0-7.287 3.177-8.45 5.071a3.68 3.68 0 000 3.87c1.163 1.894 3.808 5.071 8.45 5.071 4.642 0 7.287-3.177 8.45-5.071a3.68 3.68 0 000-3.87zM9 13.498a4.498 4.498 0 110-8.996 4.498 4.498 0 010 8.996z"
                    fill={`url(#${gradientId2})`}
                />
                <Defs>
                    <LinearGradient
                        id={gradientId}
                        x1={5.99975}
                        y1={9.0018}
                        x2={12.3372}
                        y2={9.0018}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#949494" />
                        <Stop offset={1} stopColor="#595959" />
                    </LinearGradient>
                    <LinearGradient
                        id={gradientId2}
                        x1={-0.00368762}
                        y1={9.00426}
                        x2={19.0158}
                        y2={9.00426}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#949494" />
                        <Stop offset={1} stopColor="#595959" />
                    </LinearGradient>
                </Defs>
            </Svg>
        </View>
    );
});

export { EyeIcon };
