import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const RefreshIcon = React.memo(
  (props: {
    width?: number;
    height?: number;
    color?: string;
    containerStyle?: StyleProp<ViewStyle>;
  }) => {
    return (
      <View style={props.containerStyle}>
        <Svg
          width={20}
          height={22}
          viewBox="0 0 20 22"
          fill="none"
          {...props}
        >
          <Path
            d="M16.437 5.723a8.45 8.45 0 101.388 8.98v.001l.005-.01a.898.898 0 00-1.148-1.178l.12.324-.12-.324a.899.899 0 00-.498.455h0l-.004.01a6.658 6.658 0 11-.873-6.805H12.466v.346m3.97-1.799l-4.473 2.133a.539.539 0 01.503-.334m3.97-1.799V3.205a.886.886 0 111.774 0v5.744h-5.749a.884.884 0 01-.884-.882m4.86-2.344l-4.86 2.344m.889-.545h3.096-3.096zm-.89.545v.001l.347-.003h-.346v.002z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.691468}
          />
        </Svg>
      </View>
    );
  },
);

export { RefreshIcon };
