import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import { NothingToShow } from './pieces/NothingToShow';
import { createTableColumn, TabNames } from './utils';
import { AdminPageHeader } from './pieces/ScreenHeader';
import { adminPageStyles } from './adminPageStyles';
import { AdminViewTable } from './pieces/AdminViewTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AdminNavigatorParamList } from '../../../navigators/AdminNavigator/AdminNavigator';
import { AdminNavigatorProps } from '../../../typesInterfacesEnums/componentProps';
import { utils } from '../../../utils/utils';
// import { MB_Button } from '@mightybyte/rnw.components.button';
// import CloseSquaredIcon from '../../../resources/svgComponents/CloseSquaredIcon';
import { MB_Modal } from '../../../mightyByteLibraries/MB_Modal/MB_Modal';
import { DisableUser } from './pieces/DisableUser';
import { USER_TYPES } from '../../../typesInterfacesEnums/enums';
import { useGetStudents } from '../../../graphql/admin/getStudents';

const Students = ({navigation}: AdminNavigatorProps) => {
    const pageSize = 8;

    const {page} = useRoute<RouteProp<AdminNavigatorParamList, 'Students'>>().params;
    const [currentPage, setCurrentPage] = useState<number>(page ? Number(page) : 1);
    const [disableUser, setDisableUser] = useState<{id: string, name: string} | undefined>(undefined);

    const {data:students, loading} = useGetStudents({variables: {
            pageSize: pageSize,
            page: currentPage,
        },
        fetchPolicy: 'cache-and-network',
    });


    const dataToDisplay = useMemo(() => {
        const studentsData = students?.getAdminStudents?.items || [];
        if (studentsData.length === 0 || loading) {
            return Array(8).fill(Array(5).fill(''));
        }

        return  studentsData.map((student) => {
            return [
                student.name, student.userName, student.numberOfHives, utils.dateToDisplay(student.joinedDate),
                // <MB_Button title={'Disable'}
                //            onPress={() => {
                //                setDisableUser({id:  student.id, name: student.name});
                //            }}
                //            style={adminPageStyles.tableTextButton}
                //            textStyle={{
                //                ...adminPageStyles.tableButtonBase,
                //                color: '#EA2A21',
                //            }}
                //            leftElement={<CloseSquaredIcon size={14} color={'#EA2A21'}/>}
                // />,

            ];
        });

    }, [students, loading]);

    const totalPages = useMemo(() => {
        const totalItems = students?.getAdminStudents?.total || 0;
        return Math.ceil(totalItems / pageSize);
    } , [students]);

    const tableProps = useMemo(() => {
        return {
            header: [
                createTableColumn({name: 'Name', containerStyle: {minWidth: 200, maxWidth: 240}}),
                createTableColumn({name: 'Username', containerStyle: {minWidth: 240, maxWidth: 240}}),
                createTableColumn({name: 'No. of Hives In', containerStyle: {minWidth: 150, maxWidth: 150}}),
                createTableColumn({name: 'Date Joined', containerStyle: {minWidth: 100, maxWidth: 100}}),
                // createTableColumn({name: '', containerStyle: {flex: 1, alignItems: 'flex-end'}}),
            ],
            data: dataToDisplay,

        };
    }, [dataToDisplay]);

    return (
        <View style={adminPageStyles.page}>
            { <MB_Modal isVisible={!!disableUser}
                        hideCloseButton={true}
                        onBackdropPress={()=>setDisableUser(undefined)}
            >
                {!!disableUser && <DisableUser id={disableUser.id}
                                               userName={disableUser.name}
                                               userRole={USER_TYPES.student}
                                               closeAction={()=> setDisableUser(undefined)}

                />
                }
            </MB_Modal>}
            <AdminPageHeader text={TabNames.Students}/>
            {!loading && students?.getAdminStudents.items.length === 0 ? <NothingToShow tab={TabNames.Students}/>
                : <AdminViewTable {...tableProps}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  navigation={navigation}
                                  totalPages={totalPages}
                                  totalItems={students?.getAdminStudents?.total || 0}
                                  currentPageSize={pageSize}

                />}
        </View>
    );
};

export { Students };

