import React from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  Pressable,
  ViewStyle,
  StyleProp,
  Dimensions,
  TextStyle,
} from 'react-native';
import { applyTransparency, COLORS } from '../../constants/colors';
import { ShareIcon } from '../../resources/svgComponents/ShareIcon';
import { utils } from '../../utils/utils';
import { EyeIcon } from '../../resources/svgComponents/EyeIcon';
import { isMobile } from '@mightybyte/rnw.utils.device-info';

interface IInputProps {
  isPassword?: boolean;
  showPassword?: boolean;
  value: string;
  placeholder: string;
  textContentType?: 'name' | 'password' | 'emailAddress' | 'addressCity' | 'none';
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  label?: string;
  hasError?: boolean;
  editable?: boolean;
  maxLength?: number;
  leftIcon?: any;
  handleShowPassClick?: () => void;
  setValue?: (value: string) => void;
  hasShareButton?: boolean;
  onSharePressed?: () => void;
  onSubmitEditing?: () => void;
}
const Input = (props: IInputProps) => {
  return (
    <View
      style={[styles.inputContainer, props.hasError ? styles.errorContainer : null, props.style]}
    >
      {
        props.leftIcon && (
          <View style={styles.leftIcon}>
            {props.leftIcon}
          </View>
        )
      }
      {props.hasShareButton && (
        <Pressable onPress={props.onSharePressed} style={styles.shareIcon}>
          <ShareIcon />
        </Pressable>
      )}
      <TextInput
        maxFontSizeMultiplier={1}
        placeholderTextColor={COLORS.placeholder}
        style={[styles.input, props.textStyle]}
        textContentType={props.textContentType}
        placeholder={props.placeholder}
        value={props.value}
        editable={props.editable}
        onChangeText={props.setValue}
        maxLength={props.maxLength}
        secureTextEntry={props.isPassword && !props.showPassword ? true : false}
        onSubmitEditing={isMobile ? () => null : props.onSubmitEditing}
      />
      {props.isPassword && (
        <Pressable style={styles.eyeIcon} onPress={props.handleShowPassClick}>
          <EyeIcon />
        </Pressable>
      )}
    </View>
  );
};

export { Input };

const styles = StyleSheet.create({
  label: {
    width: '100%',
    textAlign: 'left',
    fontWeight: '600',
    color: '#41423F',
    marginBottom: 4,
  },
  inputContainer: {
    position: 'relative',
    backgroundColor: COLORS.white,
    borderWidth: 2,
    borderColor: '#FF9900',
    borderRadius: 10,
    width: Dimensions.get('window').width - 60,
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 64,
    ...utils.getShadow({
      color: '#161d24',
      radius: 20,
      opacity: 0.08,
      offsetWidth: 0,
      offsetHeight: 24,
      elevation: 0,
    }),
  },
  errorContainer: {
    borderColor: '#EB5038',
  },
  input: {
    fontFamily: 'Secular One',
    height: 48,
    fontWeight: '500',
    fontSize: 24,
    color: '#41423F',
    flex: 1,
    textAlign: 'center',
  },
  hasErrorInput: {
    color: '#EB5038',
  },
  buttonIconContainer: {
    width: 54,
    height: 48,
    borderRightWidth: 1,
    borderRightColor: applyTransparency('#212121', 0.35),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hasErrorIconContainer: {
    borderRightColor: '#EB5038',
  },
  eyeIcon: {
    position: 'absolute',
    right: 12,
  },
  shareIcon: { position: 'absolute', right: 10, top: 4, zIndex: 10 },
  leftIcon: {
    position: 'absolute', left: 16, top: 20, zIndex: 10,
  },
});
