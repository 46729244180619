import React from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../../constants/colors';
import { HexagonIcon } from '../../resources/svgComponents/HexagonIcon';
import { HexagonWithTickIcon } from '../../resources/svgComponents/HexagonWithTickIcon';

interface IRadioProps {
  title: string;
  onPress: () => void;
  isActive: boolean;
}
const Radio = (props: IRadioProps) => {
  return (
    <Pressable
      onPress={props.onPress}
      style={[styles.btnContainer, props.isActive ? null : styles.disabled]}
    >
      {props.isActive ? <HexagonWithTickIcon /> : <HexagonIcon />}
      {props.isActive ? (
        <LinearGradient colors={['#FFCF53', '#FF9900']} style={styles.activeTextContainer}>
          <Text maxFontSizeMultiplier={1} style={styles.activeText}>{props.title}</Text>
        </LinearGradient>
      ) : (
        <View style={styles.deActiveTextContainer}>
          <Text maxFontSizeMultiplier={1} style={styles.deActiveText}>{props.title}</Text>
        </View>
      )}
    </Pressable>
  );
};

export { Radio };

const styles = StyleSheet.create({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.4,
  },
  activeTextContainer: {
    height: 40,
    width: 133,
    borderWidth: 2,
    display: 'flex',
    borderColor: '#1F1F1F',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  activeText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    textAlign: 'center',
    color: 'black',
  },
  deActiveTextContainer: {
    height: 40,
    width: 133,
    borderWidth: 2,
    borderColor: '#1F1F1F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  deActiveText: {
    fontFamily: 'Secular One',
    fontSize: 18,
    textAlign: 'center',
    color: applyTransparency('#000000', 0.5),
  },
});
