import { StyleSheet } from 'react-native';
import { COLORS } from '../../../constants/colors';

const adminPageStyles = StyleSheet.create({
    page: {
        height: '100%',
        paddingHorizontal: 18,
        paddingVertical: 24,
        backgroundColor: COLORS.white,
    },
    tableHeaderTextStyle: {
        fontWeight: '600',
        fontSize: 12,
        color: COLORS.offBlack,
    },

    tableTextButton: {
        backgroundColor: 'transparent',
    },
    tableButtonBase: {
        fontFamily: 'Inter',
        fontSize: 11,
        fontWeight: '400',
    },
});

export { adminPageStyles };
