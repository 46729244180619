import * as React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ArrowIcon = (props: { size?: number, active: boolean, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={[props.containerStyle, { transform: [{ rotateX: props.active ? '180deg' : '0deg' }] }]}>
            <Svg
                width={14}
                height={11}
                viewBox="0 0 14 11"
                fill="none"
                {...props}
            >
                <Path
                    d="M2.265 2.743h9.68c.099 0 .195.022.279.063a.49.49 0 01.197.168.361.361 0 01-.032.444L7.548 9.012c-.2.232-.684.232-.885 0l-4.84-5.594a.36.36 0 01-.035-.445.488.488 0 01.198-.168.642.642 0 01.279-.062z"
                    fill="#434343"
                />
            </Svg>
        </View>
    );
};

export { ArrowIcon };
