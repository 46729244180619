import { utilApiCalls } from '../apiCalls/utilApiCalls';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { UTIL_QUERY_KEYS } from '../constants/constants';
import { ServerError } from '@apollo/client';


export const useGetMinimumMobileJSVersion = (queryOptions?: UseQueryOptions<string, ServerError>) => {
	return useQuery<string, ServerError>(
		[UTIL_QUERY_KEYS.minimumMobileJSVersion],
		async () => utilApiCalls.getMinimumMobileJSVersion(),
		{ staleTime: Infinity, ...queryOptions }
	);
};


export const useGetBackendVersion = () => {
	return useQuery<string, ServerError>(
		[UTIL_QUERY_KEYS.serverVersion],
		async () => utilApiCalls.getBackendVersion(),
		{ staleTime: Infinity }
	);
};
