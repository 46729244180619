import React, { useEffect, useRef } from 'react';
import { StyleSheet, Text, Animated, Pressable, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { COLORS } from '../../constants/colors';
import { utils } from '../../utils/utils';

interface IErrorPopupProps {
  text: string;
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}
const ErrorPopup = (props: IErrorPopupProps) => {
  const inset = useSafeAreaInsets();
  const fadeAnim = useRef(new Animated.Value(100)).current; // Initial value for opacity: 0

  useEffect(() => {
    if (props.isVisible) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start();
      setTimeout(() => {
        props.setVisible(false);
      }, 5000);
    } else {
      Animated.timing(fadeAnim, {
        toValue: 100,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [fadeAnim, props, props.isVisible]);

  return (
    <Pressable onPress={() => props.setVisible(false)}>
      <Animated.View
        style={[
          styles.container,
          {
            bottom: inset.bottom ? -inset.bottom + 16 + 40 : 16 + 40,
            transform: [{ translateX: -105 }, { translateY: fadeAnim }],
          },
        ]}
      >
        <Text maxFontSizeMultiplier={1} style={{ color: 'white', textAlign: 'center' }}>{props.text}</Text>
      </Animated.View>
    </Pressable>
  );
};

export { ErrorPopup };

const styles = StyleSheet.create({
  container: {
    left: '50%',
    width: 210,
    height: 40,
    backgroundColor: COLORS.error,
    position: Platform.select({ ios: 'absolute', android: 'absolute', web: 'fixed' }) as any,
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...utils.getShadow({
      color: COLORS.error,
      radius: 4,
      opacity: 0.5,
      offsetWidth: 0,
      offsetHeight: 4,
      elevation: 0,
    }),
  },
});
