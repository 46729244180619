import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getTeachers = gql`
    query getAdminTeachers($pageSize: Int! = 1, $page: Int!=1) {
        getAdminTeachers(pageSize: $pageSize, page: $page) {
            items {
                id
                name
                email
                numberOfHives
                joinedDate
            }
            page
            total   
        }
   }
`;

export type getTeachesRequest = {
    pageSize: number,
    page: number,
};

type getTeachersResponseData = {
    id: string,
    name: string,
    email: string,
    numberOfHives: number,
    joinedDate: string,
};

export type getTeachersResponse = {
    getAdminTeachers: {
        items: getTeachersResponseData[],
        page: number,
        total: number,
    },
};

export const useGetTeachers = (options: QueryHookOptions<getTeachersResponse, getTeachesRequest> = {}) => {
    return useQuery<getTeachersResponse, getTeachesRequest>(getTeachers, options);
};

const getTeacher = gql`
    query getAdminTeacher($userId: String!) {
        getAdminTeacher(teacherId: $userId) {
            id
            firstName
            lastName
            email
            joinedDate
            hives {
                size,
                name 
            }
        }
    }
`;

type GetTeacherRequest = {
    userId: string,
};

type TeacherObject = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    joinedDate: string,
    hives: {
        size: string,
        name: string,
    }[]
};

export type GetTeacherResponse = {
    getAdminTeacher: TeacherObject,
}

export const useGetTeacher = (options: QueryHookOptions<GetTeacherResponse, GetTeacherRequest> = {}) => {
    return useQuery<GetTeacherResponse, GetTeacherRequest>(getTeacher, options);
};
