import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle, StyleSheet } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import LinearGradient from 'react-native-linear-gradient';

interface ICheckbox {
  isChecked: boolean,
  onPress: () => void,
  containerStyle?: StyleProp<ViewStyle>
}

const Checkbox = ({ isChecked, onPress, containerStyle}: ICheckbox) => {
  return (
    <TouchableOpacity style={[styles.container, containerStyle]} onPress={onPress} >
      <LinearGradient
          useAngle={false}
          angle={90}
          colors={['#FFCF53','#FF9900']}
          style={[
              styles.container,
              isChecked ? styles.checked : {},
              {display: isChecked ? 'flex' : 'none'},
          ]}
      >
      {isChecked && <Feather name="check" size={14} color={'#333333'} />}
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
    container: {
      width: 17,
        height: 17,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '#AAAAAA',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checked: {
      borderColor: '#333333',
    },
});

export { Checkbox };
